import React, { useState, useEffect, useCallback } from "react";
import HandleNumberQuestion from "./HandleNumberQues";
import HandleTextQues from "./HandleTextQues";
import HandleBooleanQuestion from "./HandleBooleanQues";
import HandleMultipleChoiceQuestion from "./HandleMultipleChoiceQues";
import HandleSingleChoiceQuestions from "./HandleSingleChoiceQues";
import HandleRatingQuestion from "./HandleRatingQuestion";
import {
  Snackbar,
  AlertProps,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  saveAcceptanceDetails,
  getPreApprovanceForm,
} from "@gogig-in/gogig-clients";
import LoadingSpinner from "../LoadingAnimation";
import { useAuthContext } from "../../providers/AuthProvider";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface GigFormProps {
  visibilityId: string;
  onSuccess: () => void;
  gigId: string;
  gigTitle: string;
  handleClose: () => void; // Adding the handleClose prop
}

const GigForm: React.FC<GigFormProps> = ({
  visibilityId,
  onSuccess,
  gigId,
  gigTitle,
  handleClose,
}) => {
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { authHeader } = useAuthContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questionaireResponse, setQuestionaireResponse] = useState<any>({});

  const isFormFilled = () => {
    const questionIds = Object.keys(questionaireResponse);
    for (const questionId of questionIds) {
      if (!answers[questionId]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (success) {
      // Invoke the onSuccess callback to inform the parent component
      onSuccess();
    }
  }, [success, onSuccess]);

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gigId) {
          const response: any = await getPreApprovanceForm(gigId);
          setQuestionaireResponse(response.data.payload || {});
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error during getPreApprovanceForm",e);
      }
    })();
  }, [authHeader, gigId]);

  function renderQuestionControl(
    questionNumber: number,
    questionId: string,
    questionData: any,
    answers: any,
    handleAnswerChange: (question: string, answer: any) => void
  ) {
    switch (questionData.type) {
      case "singleChoice":
        return (
          <HandleSingleChoiceQuestions
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={
              Array.isArray(questionData.answer) ? questionData.answer : []
            }
          />
        );

      case "multiChoice":
        return (
          <HandleMultipleChoiceQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={
              Array.isArray(questionData.answer) ? questionData.answer : []
            }
          />
        );

      case "boolean":
        return (
          <HandleBooleanQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
          />
        );

      case "text":
        return (
          <HandleTextQues
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            gridSpace={questionData.question.length > 74 ? 12 : 6}
          />
        );

      case "number":
        return (
          <HandleNumberQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
          />
        );

      case "rating":
        return (
          <HandleRatingQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
          />
        );

      default:
        return null;
    }
  }
  const handleAnswerChange = useCallback((questionId: string, answer: any) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (!visibilityId) {
        console.error("VisibilityId is not defined");
        return;
      }
      if (authHeader) {
        // Update existingQuestionaireResponse based on user answers
        Object.keys(answers).forEach((questionId) => {
          const questionResponse = questionaireResponse[questionId] || {
            answer: "",
            question: "",
          };
          questionResponse.answer = answers[questionId];
          questionaireResponse[questionId] = questionResponse;
        });

        // Convert updatedQuestionaireResponse to JSON string
        const updatedQuestionaireResponse =
          JSON.stringify(questionaireResponse);
        const updatedQuestionaireResponse1 = JSON.parse(
          updatedQuestionaireResponse
        );
        await saveAcceptanceDetails(
          visibilityId,
          authHeader,
          updatedQuestionaireResponse1
        );
        setIsModalOpen(false);
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error fetching gig details", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    handleClose();
    setAnswers({});
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            [theme.breakpoints.down("sm")]: {
              minWidth: "300px",
            },
            [theme.breakpoints.up("md")]: {
              minWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: "28px", fontWeight: "bold" }}>
          {gigTitle || "Enter Details"}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LoadingSpinner label="Please wait while we fetch some questions for you..." />
          ) : (
            Object.entries(questionaireResponse).map(
              ([questionId, questionData], index) => (
                <div key={index} style={{ marginBottom: "15px" }}>
                  {renderQuestionControl(
                    index + 1,
                    questionId,
                    questionData,
                    answers,
                    handleAnswerChange
                  )}
                </div>
              )
            )
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (isFormFilled()) {
                handleSubmit();
              } else {
                alert("Please fill out all the details");
              }
            }}
            color="primary"
            disabled={!isFormFilled()}
            sx={{
              color: "white",
              backgroundColor: isFormFilled() ? "#1976D2" : undefined,
              "&:hover": {
                backgroundColor: isFormFilled() ? "#125699" : undefined,
              },
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "white" }} size={24} />
            ) : (
              "Submit Application"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbarSeverity as "success" | "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GigForm;
