import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import EditCapabilitiesPopUp from "../ProfileEditComponents/EditCapabilitiesPopUp";
import CapabilitiesView from "./CapabilitiesView";
import { Capability, getUserCapabilityList } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import ProfileCard from "./ProfileCard";

export default function UserCapabilities() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [userCapabilities, setUserCapabilities] = useState<Capability[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });

  const skills = userCapabilities.filter(
    (capability) => capability.capabilityType === "skill"
  );
  const assets = userCapabilities.filter(
    (capability) => capability.capabilityType === "asset"
  );
  const { authHeader } = useAuthContext();

  const snackbar = (message: string, severity: Severity) => {
    setSnackbarData({ message, severity });
  };

  // Function to handle loading state while fetching data from backend
  const fetchUserCapabilities = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUserCapabilityList(authHeader);
      setUserCapabilities(response);
    } catch (error: any) {
      if (error.status === 401) {
        setOpenSnackbar(true);
        snackbar("Unauthorized access. Please log in again", "error");
        // Handle unauthorized access, e.g., redirect to login page
      } else {
        console.error(
          "An error occurred while fetching user capabilities:",
          error
        );
      }
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = useCallback(() => {
    setOpenSnackbar(false);
    setOpenDialog(false);
    fetchUserCapabilities(); // Fetch data after snackbar is closed
  }, [fetchUserCapabilities]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (authHeader) {
      fetchUserCapabilities();
    }
  }, [authHeader, fetchUserCapabilities]);

  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        severity={snackbarData.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <EditCapabilitiesPopUp
        open={openDialog}
        onClose={handleClose}
        userSelectedCapabilities={userCapabilities}
      />
      <ProfileCard
        title="Skills & Assets"
        editTooltipText="Edit Skills & Assets"
        onDialogOpen={handleDialogOpen}
        isLoading={isLoading}
      >
        <>
          {skills.length === 0 && assets.length === 0 ? (
            <>
              <br />
              <Typography variant="h5">
                {userCapabilities.length === 0
                  ? "No Skills and Assets available"
                  : userCapabilities[0].capabilityType === "skill"
                  ? "No Skills available"
                  : "No Assets available"}
              </Typography>
            </>
          ) : (
            <>
              {skills.length > 0 && (
                <CapabilitiesView label="My Skills" capabilities={skills} />
              )}
              {assets.length > 0 && (
                <CapabilitiesView label="My Assets" capabilities={assets} />
              )}
            </>
          )}
        </>
      </ProfileCard>
    </>
  );
}
