import React from 'react';
import { Container, Typography } from '@mui/material';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import { useNavigate } from 'react-router-dom';

interface SuccessApplicationProps {
  gigTitle: string;
}

const SuccessApplication: React.FC<SuccessApplicationProps> = ({ gigTitle }) => {
  const history = useNavigate();

  const handleClick = () => {
    history('/task/gigHistory', { state: { status: 'applied' } });
  };

  return (
    <Container>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          padding: '16px',
        }}
      >
        <VerifiedTwoToneIcon sx={{ color: 'primary.dark', fontSize: '4rem' }} />
        <Typography
          sx={{
            color: 'primary.dark',
            fontWeight: '700',
            fontSize: '20px',
            marginTop: '16px',
          }}
        >
          Application Submitted Successfully
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            marginTop: '16px',
          }}
        >
          Your application for the <strong>{gigTitle}</strong> gig has been submitted successfully. Please check your{' '}
          <span onClick={handleClick} style={{ margin: '0 5px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
        applied job
      </span>{' '}
          section for further communication.
        </Typography>
      </Typography>
    </Container>
  );
};

export default SuccessApplication;
