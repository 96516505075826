import React from 'react';
import { Typography, List, ListItem } from '@mui/material';

interface ProfileDetails {
  dob: string;
  phoneNumber: number;
  skillSets: string;
  workExperience: string;
  workLocation: string;
  gender: string;
  languages: string;
  lastRole: string;
  lastCompany: string;
}

interface ProfileReviewProps {
  profileDetails: ProfileDetails;
}

const ProfileDetailsReview: React.FC<ProfileReviewProps> = ({ profileDetails }) => {
  return (
    <>
      <Typography sx={{ fontFamily: '24px', fontSize: '24px' }}>
        Review and Save
      </Typography>
      <Typography sx={{ fontFamily: '24px', fontSize: '16px' }}>
        Profile Details
      </Typography>
              <List>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>Date of Birth:<span>&nbsp;</span></Typography>
          <Typography>{new Date(profileDetails.dob).toLocaleDateString()}</Typography>
        </ListItem>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>Phone Number:<span>&nbsp;</span></Typography><Typography>{profileDetails.phoneNumber}</Typography>
        </ListItem>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>Gender:<span>&nbsp;</span></Typography><Typography>{profileDetails.gender}</Typography>
        </ListItem>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>Work Experience:<span>&nbsp;</span></Typography><Typography>{profileDetails.workExperience}</Typography>
        </ListItem>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>languages:<span>&nbsp;</span><span>&nbsp;</span></Typography><Typography>{profileDetails.languages}</Typography>
        </ListItem>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>Skills:<span>&nbsp;</span></Typography><Typography>{profileDetails.skillSets}</Typography>
        </ListItem>
        <ListItem>
          <Typography sx={{ fontWeight: '600' }}>Work Location:<span>&nbsp;</span></Typography><Typography>{profileDetails.workLocation}</Typography>
        </ListItem>
        {profileDetails.workExperience !== "I haven't worked before" && (
          <><ListItem>
            <Typography sx={{ fontWeight: '600' }}>Last company you worked in:<span>&nbsp;</span></Typography>
            <Typography>{profileDetails.lastCompany}</Typography>
          </ListItem><ListItem>
              <Typography sx={{ fontWeight: '600' }}>Last company role:<span>&nbsp;</span></Typography>
              <Typography>{profileDetails.lastRole}</Typography>
            </ListItem></>
        )}

      </List>
    </>
  );
};

export default ProfileDetailsReview;
