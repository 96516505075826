import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material"
interface ProfileDetails {
  workLocation: string;
  dob: string;
  phoneNumber: number;
  gender: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
}

interface PersonalDetailsProps {
  profileDetails: ProfileDetails;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNumberError: string | null;
  emptyFieldsError: string | null;
}
const formatDate = (dateString: string) => {
  if (!dateString) return ''; 
  const date = new Date(dateString);
  const formattedDate = date.toISOString().split('T')[0];
  return formattedDate;
};
const PersonalDetails = ({
  profileDetails,
  handleChange,
  phoneNumberError,
  emptyFieldsError,
}: PersonalDetailsProps) => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 18);
  const maxDate = currentDate.toISOString().split('T')[0];

  return (
    <>
      <Typography sx={{ fontFamily: 'inter, sans-serif', fontSize: '24px' }}>
        Lastly your personal details...
      </Typography>
      <Typography sx={{ fontFamily: 'inter, sans-serif', fontSize: '16px' }}>
        Please provide your personal information
      </Typography>
      <Grid container spacing={2} sx={{ alignItems: 'center' }} >
        
        <Grid item xs={12} md={8}>
          <TextField
            type="date"
            name="dob"
            label="Date of Birth"
            fullWidth
            required
            onChange={handleChange}
            value={formatDate(profileDetails.dob)}
            margin="normal"
            sx={{ maxWidth: '400px' }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: maxDate,
            }} />
          {emptyFieldsError && !profileDetails.dob && (
            <Typography color="error">Please enter your date of birth</Typography>
          )}

          <TextField
            name="workLocation"
            label="Work Location"
            fullWidth
            required
            onChange={handleChange}
            sx={{ maxWidth: '400px' }}
            value={profileDetails.workLocation || ''}
            margin="normal" 
            autoComplete="address-level1"/>
          {emptyFieldsError && !profileDetails.workLocation && (
            <Typography color="error">Please enter your work location</Typography>
          )}
          <TextField
            name="phoneNumber"
            label="phone number"
            fullWidth
            required
            onChange={handleChange}
            value={profileDetails.phoneNumber || ''}
            helperText={phoneNumberError}
            error={!!phoneNumberError}
            autoComplete="tel"
            margin="normal"
            sx={{ maxWidth: '400px' }}
             />
          {emptyFieldsError && !profileDetails.phoneNumber && (
            <Typography color="error">Please enter your phone number</Typography>
          )}
          <FormLabel component="legend">
            <Typography variant="h6">Gender*</Typography>
          </FormLabel>
          <RadioGroup
            row
            name="gender"
            value={profileDetails.gender || ''}
            onChange={handleChange}
          >
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
          </RadioGroup>
          {emptyFieldsError && !profileDetails.gender && (
            <Typography color="error">Please select your gender</Typography>
          )}
        </Grid>
      </Grid >
    </>

  )
}

export default PersonalDetails;