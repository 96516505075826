import { Box, Container, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface LogoTextProps {
  logoName: string;
  text: string;
}

const SuccessSubmission: React.FC<LogoTextProps> = ({ logoName, text }) => {
  const history = useNavigate();
  const redirectToLoginPage = () => {
    history("/user-entry/login");
  };
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "400px",
          border: "2px solid #eee ",
          borderRadius: "10px",
          padding: "25px",
          marginTop: "25px",
        }}
      >
        <Box textAlign="center">
          <img
            alt="reset-password.png"
            src={`${process.env.PUBLIC_URL}/${logoName}.png`}
            style={{ height: "100px", width: "100px" }}
          />
        </Box>
        <Typography
          sx={{ display: "flex", textAlign: "center", marginBottom: "15px" }}
        >
          {text}
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
        >
          <Button sx={{ textTransform: "none" }} onClick={redirectToLoginPage}>
            Go back to Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SuccessSubmission;
