import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PhoneIcon from "@mui/icons-material/Phone";
import "./VendorDetails.css";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  assignToUser,
  getAssignedDetails,
  updateResponse,
} from "@gogig-in/gogig-clients";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  HandleImageQuestion,
  HandleAddressQues,
  HandlePersonQues,
  HandleTextQues,
  HandleBooleanQuestion,
  HandleMultipleChoiceQuestion,
  HandleSingleChoiceQuestions,
  HandleNumberQuestion,
} from "./index";
import { useAuthContext } from "../../providers/AuthProvider";

interface Vendor {
  vendorId: string;
  gigId: string;
  name: string;
  number: string;
  language: string;
  completed: boolean;
  selectedoption: string | null;
  assignmentStatus: string;
  responseId: string;
  userId: string;
  callStatus: string;
  retry: number;
  questionaireResponse: string;
  category: string;
  taskInformation: string;
  vehicleNumbers: [];
}

const VendorDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [vendorDetails, setVendorDetails] = useState<Vendor>();
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const { authHeader } = useAuthContext();
  const { gigId } = useParams<{ gigId?: string }>();
  const location = useLocation();
  const [submitLoader, setSubmitLoader] = useState(false);
  const gigTitle = location.state ? location.state.gigTitle : null;
  const category = location.state ? location.state.category : null;
  const [isCallStatusConfirmed, setIsCallStatusConfirmed] = useState(false);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const [totalUploadedFilesCount, setTotalUploadedFilesCount] = useState(0);
  const [imageTypeCount, setImageTypeCount] = useState(0);
  const [imageTypeCountUpdated, setImageTypeCountUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<
    { file: File; question: any }[]
  >([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleFileRemove = (questionToRemove: any) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((item) => item.question !== questionToRemove)
    );
    setTotalUploadedFilesCount((prevCount) => prevCount - 1);
  };

  const [errorsInForm, setErrorsInForm] = useState({});

  const handleChildError = (error: string | null, field: string) => {
    setErrorsInForm({ ...errorsInForm, ...{ [field]: error } });
  };
  const isFormFilled = () => {
    if (!selectedVendor) {
      return false;
    }

    const questionaireResponse = JSON.parse(
      selectedVendor.questionaireResponse || "{}"
    );
    const questionIds = Object.keys(questionaireResponse);

    for (const questionId of questionIds) {
      const questionResponse = questionaireResponse[questionId] || {
        type: "",
        answer: "",
        question: "",
      };

      const questionType = questionResponse.type;
      const ignoreKeywords = ["remarks", "comments"];

      if (
        questionType !== "image" &&
        !ignoreKeywords.some((keyword) =>
          questionResponse.question.toLowerCase().includes(keyword)
        )
      ) {
        if (
          !answers[questionId] ||
          totalUploadedFilesCount !== imageTypeCount
        ) {
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gigId) {
          // const vendorDetailsId: any = await assignToGigSeeker(gigId, authHeader)
          // const vendorAssignmentId = vendorDetailsId.data.vendorAssignmentId
          const response: any = await getAssignedDetails(gigId, authHeader);
          setVendorDetails(response.data);
        }
      } catch (e) {
        console.error("Error during getAssignedDetails",e);
      }
    })();
  }, [authHeader, gigId]);

  useEffect(() => {
    const selected = vendorDetails;
    setSelectedVendor(selected || null);
  }, [id, vendorDetails]);

  useEffect(() => {
    setAnswers({});
  }, [selectedVendor]);

  const handleOptionChange = (event: SelectChangeEvent<string | null>) => {
    setSelectedOption(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState<string | null>("");

  const handleAnswerChange = (questionId: string, answer: any) => {
    if (!isSubmissionSuccessful) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: answer,
      }));
    }
  };

  const handleSubmit = async () => {
    setSubmitLoader(true);

    try {
      if (authHeader) {
        const gigId = selectedVendor?.gigId;
        const newCallStatus = "success";
        const existingQuestionaireResponse = JSON.parse(
          selectedVendor?.questionaireResponse || "{}"
        );
        Object.keys(answers).forEach((questionId) => {
          const questionResponse = existingQuestionaireResponse[questionId] || {
            type: "",
            answer: "",
            question: "",
          };
          questionResponse.answer = answers[questionId];
          existingQuestionaireResponse[questionId] = questionResponse;
        });

        const updatedQuestionaireResponse = JSON.stringify(
          existingQuestionaireResponse
        );
        if (category !== "telecalling") {
          const formData = new FormData();

          uploadedFiles.forEach((item, index) => {
            const { file, question } = item;
            const fieldName = `${question}`;
            formData.append(fieldName, file);
          });

          formData.append(
            "updatedQuestionnaireResponse",
            updatedQuestionaireResponse
          );
          await updateResponse(authHeader, gigId, formData);
        } else if (category === "telecalling") {
          const updatedResponse = {
            gigId: gigId,
            callStatus: newCallStatus,
            questionaireResponse: updatedQuestionaireResponse,
          };
          await updateResponse(authHeader, gigId, updatedResponse);
        }
      }
      setSubmitLoader(false);
      setIsSubmissionSuccessful(true);
      setErrorMessage(false);
      setOpenDialog(true);
    } catch (error) {
      console.error("Error submitting answers:", error);
      setSubmitLoader(false);
      setErrorMessage(true);
    }
  };
  function renderQuestionControl(
    questionId: any,
    questionData: any,
    answers: any,
    isSubmissionSuccessful: boolean
  ) {
    const { type, question, answer } = questionData;

    if (!type || !question) {
      return <label>Error: Invalid question data</label>;
    }

    let renderedControl;

    switch (type) {
      case "singleChoice":
        return (
          <HandleSingleChoiceQuestions
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={answer}
            disabled={isSubmissionSuccessful}
          />
        );

      case "multiChoice":
        return (
          <HandleMultipleChoiceQuestion
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={answer}
            disabled={isSubmissionSuccessful}
          />
        );

      case "boolean":
        return (
          <HandleBooleanQuestion
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            disabled={isSubmissionSuccessful}
          />
        );

      case "text":
        if (question.toLowerCase().includes("vehicle number")) {
          return (
            <HandleTextQues
              question={question}
              questionId={questionId}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              gridSpace={
                (questionData as { question: string }).question.length > 74
                  ? 12
                  : 6
              }
              disabled={isSubmissionSuccessful}
              onErrorChange={handleChildError}
              vehicleNumbers={selectedVendor?.vehicleNumbers || []}
            />
          );
        } else {
          return (
            <HandleTextQues
              question={question}
              questionId={questionId}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              gridSpace={
                (questionData as { question: string }).question.length > 74
                  ? 12
                  : 6
              }
              disabled={isSubmissionSuccessful}
              onErrorChange={handleChildError}
              vehicleNumbers={[]}
            />
          );
        }

      case "number":
        return (
          <HandleNumberQuestion
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            disabled={isSubmissionSuccessful}
            onErrorChange={handleChildError} // Pass the function to handle errors in the child component
          />
        );

      case "address":
        return (
          <HandleAddressQues
            question={question}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            disabled={isSubmissionSuccessful}
            onErrorChange={handleChildError}
          />
        );

      case "person":
        if (type === "person" && answer && Array.isArray(answer)) {
          return (
            <HandlePersonQues
              key={questionId}
              question={`Enter details for ${questionData.question}`}
              questionId={questionId}
              answers={answers}
              handleAnswerChange={handleAnswerChange}
              disabled={false}
              onErrorChange={handleChildError}
              answerFields={answer}
            />
          );
        }

        return null;

      case "image":
        if (!imageTypeCountUpdated) {
          setImageTypeCount((prevCount) => prevCount + 1);
          setImageTypeCountUpdated(true);
        }
        return (
          <HandleImageQuestion
            question={question}
            onFileSelected={(file, question) => {
              setUploadedFiles((prevFiles) => {
                const updatedFiles = [...prevFiles, { file, question }];
                setTotalUploadedFilesCount((prevCount) => prevCount + 1);
                return updatedFiles;
              });
            }}
            handleFileRemove={handleFileRemove}
            disabled={isSubmissionSuccessful}
          />
        );

      default:
        renderedControl = null;
        break;
    }

    if (isSubmissionSuccessful && renderedControl) {
      renderedControl = React.cloneElement(renderedControl, {
        disabled: true,
      });
    }

    return renderedControl;
  }

  const handleResumeClick = async (gigId: any) => {
    if (authHeader) {
      try {
        await assignToUser(gigId, authHeader);
        window.location.reload();
      } catch (error) {
        console.error(`Error assigning gigId ${gigId} to gig seeker:`, error);
      }
    }
  };

  const handleConnectClick = async () => {
    if (selectedVendor) {
      let newCallStatus = selectedVendor.callStatus;

      if (selectedOption === "busy") {
        newCallStatus = "busy";
      } else if (selectedOption === "invalid") {
        newCallStatus = "invalid";
      }

      try {
        if (authHeader) {
          const gigId = selectedVendor?.gigId;
          const updatedVendor = {
            gigId: gigId,
            callStatus: newCallStatus,
          };
          await updateResponse(authHeader, gigId, updatedVendor);
        }
        setIsCallStatusConfirmed(true);
      } catch (error) {
        console.error("Error submitting answers:", error);
      }
    }
  };

  const hasErrors = () => {
   
    return Object.values(errorsInForm).some((e) => e);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    navigate(`/task/gig-status/${gigId}`);
  };

  return (
    <Container className="vendor-details-container">
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "background.default",
          margin: "10px 0",
          width: "100%",
        }}
      >
        <CardHeader
          title={
            gigTitle.length > 100 ? gigTitle.slice(0, 100) + "..." : gigTitle
          }
          sx={{ backgroundColor: "background.paper" }}
        />
        <CardContent>
          {selectedVendor && (
            <>
              {selectedVendor.taskInformation && (
                <div>
                  {Object.entries(
                    JSON.parse(selectedVendor.taskInformation)
                  ).map(([key, value]) => (
                    <div key={key} style={{ margin: "10px 0" }}>
                      <div>
                        <label>
                          <span style={{ fontWeight: "bold" }}>
                            {key.charAt(0).toUpperCase() + key.slice(1)}:{" "}
                          </span>
                          {key === "number" ? (
                            <>
                              {value as string}
                              <a
                                href={`tel:${value}`}
                                style={{ marginLeft: "10px" }}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  sx={{
                                    fontSize: "14px",
                                    textTransform: "none",
                                    padding: "0 10px",
                                    borderRadius: "30px",
                                    borderColor: "green",
                                    color: "green",
                                  }}
                                  startIcon={<PhoneIcon />}
                                >
                                  Dial
                                </Button>
                              </a>
                            </>
                          ) : key === "addressUrl" ? (
                            <>
                              <a
                                href={`https://www.google.com/maps?q=${value}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ marginLeft: "10px" }}
                              >
                                {
                                  `https://www.google.com/maps?q=${value}` as string
                                }
                              </a>
                              <p
                                style={{
                                  margin: "2px",
                                  color: "grey",
                                  fontSize: "13px",
                                }}
                              >
                                Click on the link to open the location in Google
                                Maps.
                              </p>
                            </>
                          ) : (
                            (value as string)
                          )}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          <div>
            {selectedVendor?.category !== "branding" && (
              <Grid container spacing={2}>
                {Object.entries(
                  JSON.parse(selectedVendor?.questionaireResponse || "{}")
                ).map(([questionId, questionData], index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <div>
                      {renderQuestionControl(
                        questionId,
                        questionData,
                        answers,
                        isSubmissionSuccessful
                      )}
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
            {selectedVendor?.category === "branding" && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {Object.entries(
                    JSON.parse(selectedVendor?.questionaireResponse || "{}")
                  )
                    .filter(([questionId, questionData]) => {
                      return (
                        questionData &&
                        typeof questionData === "object" &&
                        "type" in questionData &&
                        questionData.type !== "image"
                      );
                    })
                    .map(([questionId, questionData]) => (
                      <div key={questionId}>
                        {renderQuestionControl(
                          questionId,
                          questionData,
                          answers,
                          isSubmissionSuccessful
                        )}
                      </div>
                    ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {Object.entries(
                    JSON.parse(selectedVendor?.questionaireResponse || "{}")
                  )
                    .filter(([questionId, questionData]) => {
                      return (
                        questionData &&
                        typeof questionData === "object" &&
                        "type" in questionData &&
                        questionData.type === "image"
                      );
                    })
                    .map(([questionId, questionData]) => (
                      <div key={questionId} style={{ marginBottom: "20px" }}>
                        {renderQuestionControl(
                          questionId,
                          questionData,
                          answers,
                          isSubmissionSuccessful
                        )}
                      </div>
                    ))}
                </Grid>
              </Grid>
            )}
          </div>
          {selectedVendor?.category === "telecalling" && (
            <>
              <Box
                sx={{
                  backgroundColor: "#FFEEC3",
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  borderBottom: "2px solid #FFA500",
                  marginTop: "35px",
                  width: "fit-content",
                }}
              >
                <WarningAmberIcon sx={{ color: "#FCAA23" }} />
                <Typography sx={{ color: "#FF8C00", marginLeft: "8px" }}>
                  Select the option only if you are unable to connect to the
                  vendor.
                </Typography>
              </Box>
              <div className="dropdown">
                <Select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  displayEmpty
                  sx={{ width: "300px" }}
                >
                  <MenuItem value="">
                    <em>Select the reason</em>
                  </MenuItem>
                  <MenuItem value="busy">Busy</MenuItem>
                  <MenuItem value="unreachable">Unreachable</MenuItem>
                  <MenuItem value="invalid">Invalid</MenuItem>
                  <MenuItem value="be right back">Be right back</MenuItem>
                </Select>
              </div>
              <div>
                <Button
                  variant="contained"
                  color={isCallStatusConfirmed ? "success" : "primary"}
                  style={{
                    marginTop: "20px",
                    backgroundColor: isCallStatusConfirmed
                      ? "green"
                      : undefined,
                    color: isCallStatusConfirmed ? "white" : undefined,
                  }}
                  onClick={handleConnectClick}
                  disabled={!selectedOption || isCallStatusConfirmed}
                >
                  {isCallStatusConfirmed ? "Confirmed" : "Confirm Call Status"}
                </Button>
              </div>
            </>
          )}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color={isSubmissionSuccessful ? "success" : "primary"}
            sx={{ minWidth: "115px", margin: "auto" }}
            onClick={() => {
              handleSubmit();
            }}
            disabled={!isFormFilled() || hasErrors() || isSubmissionSuccessful}
          >
            {submitLoader ? (
              <CircularProgress size={24} sx={{ color: "#eee" }} />
            ) : isSubmissionSuccessful ? (
              "Submitted"
            ) : (
              "Submit"
            )}
          </Button>
        </CardActions>
        {errorMessage && (
          <Typography style={{ color: "red" }}>
            Submit failed. Please try again.
          </Typography>
        )}
      </Card>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Response Submitted Successfully!!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you want to submit another response click submit next or else
            click the Close button.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResumeClick(gigId)}
            sx={{ textTransform: "none" }}
          >
            Submit Next
          </Button>
          <Button
            onClick={handleDialogClose}
            autoFocus
            sx={{ textTransform: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default VendorDetails;
