import { CategoryGigListDetails } from '../components/categoryListings/CategoryGigListing';

interface BasicRequirementsProps {
  categoryGig: CategoryGigListDetails;
  maxLength: number;
}

const truncatedText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

export const gigRequirementsText = ({ categoryGig, maxLength }: BasicRequirementsProps): string => {
  const { ageRestriction, academicQualificationRequired, skillsRequired } = categoryGig;
  const requirements = [];

  if (ageRestriction) {
    requirements.push(`${ageRestriction}+ age is required`);
  }

  if (academicQualificationRequired) {
    requirements.push(academicQualificationRequired);
  }

  if (skillsRequired !== "none" && skillsRequired) {
    requirements.push(skillsRequired);
  }

  const formattedRequirements = requirements.join(", ");
  return truncatedText(formattedRequirements, maxLength);
};
