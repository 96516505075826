import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface LoadingSpinnerProps {
  label: ReactNode; // You can use ReactNode for a flexible type
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ label }) => (
  <>
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <CircularProgress color="primary" size={50} />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography>{label}</Typography>
    </Box>
  </>
);

export default LoadingSpinner;
