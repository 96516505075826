import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Chip,
  Button,
  Checkbox,
  FormGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddAccountDialog from "./AddAccountDialog";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { savePaymentMethod, getPaymentMethods } from "@gogig-in/gogig-clients";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";

interface AccountDetails {
  type: string;
  name: string;
  accountNo?: string;
  ifsc?: string;
  upiId?: string;
  isDefault?: number;
  isVerified?: number;
  paymentMethodId: string;
}

const initialAccountDetails: AccountDetails = {
  type: "",
  name: "",
  accountNo: "",
  ifsc: "",
  upiId: "",
  paymentMethodId: "",
};

const PaymentMethod: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState<"bank" | "upi">("bank");
  const [dialogData, setDialogData] = useState<AccountDetails>(
    initialAccountDetails
  );
  const { authHeader } = useAuthContext();
  const [error, setError] = useState<string | null>(null);
  const [accountDetails, setAccountDetails] = useState<AccountDetails[]>([]);
  const history = useNavigate();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);
  // const upiCount = accountDetails.filter(
  //   (detail) => detail.type === "upi"
  // ).length;
  const bankCount = accountDetails.filter(
    (detail) => detail.type === "bank"
  ).length;

  const handleAddAccount = (type: "bank" | "upi") => {
    setOpenDialog(true);
    setDialogType(type);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(initialAccountDetails);
    setError(null);
  };

  const fetchPaymentMethods = useCallback(async () => {
    try {
      if (authHeader) {
        const response: any = await getPaymentMethods(authHeader);
        setAccountDetails(response.data);
      }
    } catch (e) {
      console.error("Error during getPaymentMethods",e);
    }
  }, [authHeader]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  const handleSaveData = async (formData: AccountDetails) => {
    try {
      if (authHeader) {
        await savePaymentMethod(formData, authHeader);
        setDialogData(formData);
        handleCloseDialog();
        fetchPaymentMethods();
      }
    } catch (error) {
      console.error("Error making API call:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedPaymentMethod(value);
      const selectedDetail = accountDetails.find(
        (detail) => detail.paymentMethodId === value
      );
      if (selectedDetail) {
        setDialogData(selectedDetail);
      }
    } else {
      setSelectedPaymentMethod(null);
    }
  };

  const handleCardClick = (paymentMethodId: string) => {
    const isChecked = selectedPaymentMethod === paymentMethodId;

    if (isChecked) {
      setSelectedPaymentMethod(null);
    } else {
      setSelectedPaymentMethod(paymentMethodId);
      const selectedDetail = accountDetails.find(
        (detail) => detail.paymentMethodId === paymentMethodId
      );
      if (selectedDetail) {
        setDialogData(selectedDetail);
      }
    }
  };

  const handleContinue = () => {
    const { paymentMethodId, name, type, upiId, accountNo } = dialogData;

    const data =
      type === "upi"
        ? { paymentMethodId, name, type, upiId }
        : { paymentMethodId, name, type, accountNo };


    history("/payment/withdrawPage", { state: { data } });
  };

  return (
    <Box sx={{ width: "90%", maxWidth: "600px", margin: "10px auto" }}>
      <Grid container spacing={2}>
        <Typography variant="h3" sx={{ padding: "20px 20px 0px 20px" }}>
          Choose Payment Method for Crediting Amount.
        </Typography>
        {/* <Grid item xs={12}>
          <Typography
            sx={{
              margin: "10px 0px",
              backgroundColor: "#F0F0F0",
              padding: "0px 5px",
            }}
          >
            Saved UPI ID
          </Typography>
          <FormGroup>
            {accountDetails
              .filter((detail) => detail.type === "upi")
              .map((upiDetail, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    sx={{
                      backgroundColor: "white",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      boxShadow: "4px 8px 10px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #D3D3D3",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(upiDetail.paymentMethodId)}
                  >
                    <Checkbox
                      checked={
                        selectedPaymentMethod === upiDetail.paymentMethodId
                      }
                      onChange={handleCheckboxChange}
                      value={upiDetail.paymentMethodId}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        marginRight: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <Typography>Name: {upiDetail.name}</Typography>
                        {upiDetail.isDefault === 1 && (
                          <Chip
                            label="Default"
                            size="small"
                            sx={{
                              border: "1px solid #A9A9A9",
                              marginRight: "7px",
                              fontWeight: "500",
                              fontSize: "10px",
                            }}
                          />
                        )}
                        {upiDetail.isVerified === 1 ? (
                          <Chip
                            label="Verified"
                            size="small"
                            sx={{
                              backgroundColor: "white",
                              fontWeight: "500",
                              fontSize: "10px",
                              border: "1px solid #81c784",
                            }}
                            icon={<VerifiedIcon style={{ color: "#006400" }} />}
                          />
                        ) : (
                          <Chip
                            label="Verification Pending"
                            size="small"
                            sx={{
                              border: "1px solid #ffb851",
                              fontWeight: "500",
                              color: "#ffb851",
                              backgroundColor: "white",
                              fontSize: "10px",
                            }}
                          />
                        )}
                        <Typography>UPI ID: {upiDetail.upiId}</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        padding: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ borderRadius: "20px", padding: "3px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </FormGroup>
          {upiCount < 2 && (
            <Typography
              variant="body2"
              color="primary"
              onClick={() => handleAddAccount("upi")}
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              Add UPI ID <ArrowForwardIosIcon fontSize="small" />
            </Typography>
          )}
        </Grid> */}
        <Grid item xs={12}>
          <Typography
            sx={{
              margin: "10px 0px",
              backgroundColor: "#F0F0F0",
              padding: "0px 5px",
            }}
          >
            Saved Bank Accounts
          </Typography>
          <FormGroup>
            {accountDetails
              .filter((detail) => detail.type === "bank")
              .map((bankDetail, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    sx={{
                      backgroundColor: "white",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      border: "1px solid #D3D3D3",
                      boxShadow: "4px 8px 10px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(bankDetail.paymentMethodId)}
                  >
                    <Checkbox
                      checked={
                        selectedPaymentMethod === bankDetail.paymentMethodId
                      }
                      onChange={handleCheckboxChange}
                      value={bankDetail.paymentMethodId}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        marginRight: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Box>
                        <Typography>Name: {bankDetail.name}</Typography>
                        {bankDetail.isDefault === 1 && (
                          <Chip
                            label="Default"
                            size="small"
                            sx={{
                              border: "1px solid #A9A9A9",
                              marginRight: "7px",
                              fontWeight: "500",
                              fontSize: "10px",
                            }}
                          />
                        )}
                        {bankDetail.isVerified === 1 ? (
                          <Chip
                            label="Verified"
                            size="small"
                            sx={{
                              backgroundColor: "white",
                              fontWeight: "500",
                              fontSize: "10px",
                              border: "1px solid #81c784",
                            }}
                            icon={<VerifiedIcon style={{ color: "#006400" }} />}
                          />
                        ) : (
                          <Chip
                            label="Verification Pending"
                            size="small"
                            sx={{
                              border: "1px solid #ffb851",
                              fontWeight: "500",
                              color: "#ffb851",
                              backgroundColor: "white",
                              fontSize: "10px",
                            }}
                          />
                        )}

                        <Typography>
                          Account Number: {bankDetail.accountNo}
                        </Typography>
                        <Typography>IFSC code: {bankDetail.ifsc}</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        padding: "10px",
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        sx={{ borderRadius: "20px", padding: "3px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Edit
                      </Button> */}
                    </Box>
                  </Card>
                </Grid>
              ))}
          </FormGroup>
          {bankCount < 2 && (
            <Typography
              variant="body2"
              color="primary"
              onClick={() => handleAddAccount("bank")}
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "16px",
              }}
            >
              Add Bank Account <ArrowForwardIosIcon fontSize="small" />
            </Typography>
          )}
        </Grid>
      </Grid>
      <AddAccountDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveData}
        dialogType={dialogType}
        initialData={dialogData}
        error={error}
      />
      {selectedPaymentMethod && !isMobileView && (
        <Grid container justifyContent="flex-end" style={{ marginTop: "20px" }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleContinue}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              marginRight: "30px",
            }}
          >
            Continue{" "}
            <ArrowForwardIosIcon
              sx={{ padding: "0px 10px", fontSize: "small" }}
            />
          </Button>
        </Grid>
      )}
      {selectedPaymentMethod && isMobileView && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 10px",
            backgroundColor: "white",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleContinue}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            Continue{" "}
            <ArrowForwardIosIcon
              sx={{ padding: "0px 10px", fontSize: "small" }}
            />
          </Button>
        </div>
      )}
    </Box>
  );
};

export default PaymentMethod;
