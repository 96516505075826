export interface PasswordValues {
  newPassword: string;
  confirmNewPassword: string;
  }
  
  const initialPasswordValues: PasswordValues = {
    newPassword:'',
    confirmNewPassword: '',
  };
  
  export default initialPasswordValues;