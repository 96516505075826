import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { getCategories } from "@gogig-in/gogig-clients";
import { CategoryListValues } from "./CategoryListValues";
import SkeletonModal from "../skeleton/SkeletonModal";
import CategoryItem from "./CategoryItem";

interface CategoryListingProps {
  categoryId?: string;
}

export default function CategoryListing(props: CategoryListingProps) {
  const { categoryId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<CategoryListValues[]>();
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Fetch categories from API
        const response = await getCategories();
        setCategories(response.data as CategoryListValues[]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);
  return (
    <>
      {categoryId ? (
        isLoading ? (
          <SkeletonModal numberOfCards={1} skeletonType="categoryListing" />
        ) : (
          <Grid container spacing={2}>
            {categories &&
              categories
                .filter((category) => category.categoryId !== categoryId)
                .map((category) => (
                  <Grid item xs={12} key={category.categoryId}>
                    <CategoryItem category={category} />
                  </Grid>
                ))}
          </Grid>
        )
      ) : isLoading ? (
        <SkeletonModal numberOfCards={3} skeletonType="gigListing" />
      ) : (
        <Grid container spacing={2} mt={2}>
          {categories &&
            categories.map((category) => (
              <Grid item xs={12} md={4} key={category.categoryId}>
                <CategoryItem category={category} />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
}
