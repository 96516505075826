import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { signedInUserNavigation } from "./signedInUserNavigation";
import { useAuthContext } from "../../providers/AuthProvider";
import { guestUserNavigation } from "./guestUserNavigation";
import { NavigationItem } from "./types";
import { signedInSupervisorNavigation } from "./signedInSupervisorNavigation";
import { userRoles } from "../../types/userRoles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getUserNavigationByRole = (
  role: string | undefined
): NavigationItem[] => {
  switch (role) {
    case userRoles.seeker:
      return signedInUserNavigation;
    case userRoles.supervisor:
      return signedInSupervisorNavigation;
    default:
      return guestUserNavigation;
  }
};

interface NavigationProps {
  closeMobileMenu: () => void;
}

const Navigation: FC<NavigationProps> = ({ closeMobileMenu }) => {
  const history = useNavigate();
  const handleNavigation = (destination: string) => {
    history(destination);

    closeMobileMenu();
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userDetails } = useAuthContext();
  const userRole = userDetails?.role;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    handleNavigation(path);
    handleMenuClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        marginLeft: "50px",
      }}
    >
      {getUserNavigationByRole(userRole).map(({ path, label, dropdown }) => (
        <React.Fragment key={path || label}>
          <Box
            key={label}
            onClick={
              dropdown ? handleMenuClick : () => handleNavigation(path || "")
            }
            sx={{
              cursor: "pointer",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              ml: 3,
              mr: 3,
              minWidth:"max-content",
              fontSize: { xs: "1rem", md: "1rem" },
              "&:hover": {
                color: "primary.main",
                "& > div": {
                  display: "block",
                },
              },
            }}
            className={
              path === "/browse-jobs"
                ? "browseGigs-website"
                : path === "/task/gigHistory"
                ? "dashboard-website"
                : ""
            }
          >
            {/* Wrap the label in an anchor tag */}
            <Link
              to={path || "#"}
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box  sx={{ display: "flex", justifyContent: "center" }}>
              {label}
              {dropdown ? <ExpandMoreIcon sx={{ marginLeft: "5px" , marginTop: "3px"}} /> : ""}
              </Box>
            </Link>
          </Box>
          {dropdown && (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              slotProps={{
                paper: {
                  sx: {
                    minWidth: "150px",
                    borderRadius: "7px",
                    boxShadow: "0px 0px 5px 0px #99dfff",
                  },
                },
              }}
            >
              {dropdown.map((item: any) => (
                <MenuItem
                  key={item.path}
                  onClick={() => handleMenuItemClick(item.path)}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: "#F0F7FF",
                      borderRadius: "10px",
                    },
                  }}
                >
                  {item.label}
                  <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                </MenuItem>
              ))}
            </Menu>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Navigation;
