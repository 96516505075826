import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Logo } from "../logo";
import { Navigation } from "../navigation";
import ProfileMenu from "./ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";
import SideNavigation from "../navigation/SideNavigation";
import SignUpButton from "../SignUpButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useAuthContext } from "../../providers/AuthProvider";
import NotificationIcon from "./NotificationIcon";
// import TourComponent from "./TourGuide";

const Header = () => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  const closeMobileMenu = () => {
    setVisibleMenu(false);
  };

  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const { userDetails, isAuthenticated } = useAuthContext();

  return (
    <header
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "#F5F7FA",
      }}
    >
      <Container sx={{ py: { xs: 1, md: 1 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            {matchMobileView && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggleNav}
                className="menu-icon"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ marginLeft: matchMobileView ? "10px" : 0 }}>
              <Logo />
            </Box>
          </Box>
          <Box>
            {matchMobileView && (
              <>
                {isAuthenticated && <NotificationIcon />}
                {!isAuthenticated && <SignUpButton />}
              </>
            )}
          </Box>
          <SideNavigation
            profileData={userDetails || undefined}
            isOpen={isNavOpen}
            onClose={toggleNav}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              transition: (theme) => theme.transitions.create(["top"]),
              ...(matchMobileView && {
                py: 6,
                backgroundColor: "background.paper",
                zIndex: "appBar",
                position: "fixed",
                height: "auto",
                top: visibleMenu ? 0 : "-120vh",
                left: 0,
              }),
            }}
          >
            <Navigation closeMobileMenu={closeMobileMenu} />
            <IconButton
              disableRipple
              sx={{ marginLeft: " auto" }}
              color="inherit"
              onClick={() => {
                window.open(
                  `https://wa.me/+919108429315?text=Hi,${
                    userDetails?.firstName
                      ? " I am " + userDetails?.firstName + "."
                      : ""
                  } I need help with an issue.`
                );
              }}
            >
              <WhatsAppIcon sx={{ color: "green" }} />{" "}
              <span style={{ fontSize: "16px", padding: "3px" }}>Support</span>
            </IconButton>
            {!visibleMenu && !matchMobileView && (
              <Box sx={{ marginLeft: "auto" }} className="menu-icon">
                <ProfileMenu
                  profileData={userDetails}
                  closeMobileMenu={closeMobileMenu}
                  onClick={closeMobileMenu}
                />
              </Box>
            )}
          </Box>
          {/* TODO: the TourComponent was causing the site to reload infinitely */}
          {/* <TourComponent /> */}
        </Box>
      </Container>
    </header>
  );
};

export default Header;
