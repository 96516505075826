import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface NumberQuestionProps {
  question: string;
  questionId: string;
  answers: Record<string, number | undefined>; 
  handleAnswerChange: (questionId: string, value: number) => void;
}

const HandleNumberQuestion: React.FC<NumberQuestionProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    answers[questionId]?.toString() || ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);

  const shouldValidatePhoneNumber =
    question.toLowerCase().includes("phone") ||
    question.toLowerCase().includes("contact") ||
    question.toLowerCase().includes("mobile");

  const validatePhoneNumber = (phoneNumber: string) => {
    if (isNaN(Number(phoneNumber)) || phoneNumber.length !== 10) {
      setPhoneNumberError("Phone number must be 10 digits");
      return false;
    }
    setPhoneNumberError(null);
    return true;
  };

  const handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPhoneNumber = e.target.value;
    setPhoneNumber(newPhoneNumber);

    if (shouldValidatePhoneNumber && validatePhoneNumber(newPhoneNumber)) {
      handleAnswerChange(questionId, parseInt(newPhoneNumber, 10));
    } else {
      handleAnswerChange(questionId,  parseInt(newPhoneNumber, 10));
    }
  };

  return (
    <FormControl component="fieldset">
              <Typography sx={{fontWeight:'bold'}}>
{question}</Typography>
      <TextField
        variant="outlined"
        type="number"
        value={phoneNumber}
        size="small"
        style={{ width: "250px" }}
        onChange={handlePhoneNumberChange}
        helperText={phoneNumberError || "Type a numeric value"}
        error={!!phoneNumberError}
        autoComplete="tel"
      />
    </FormControl>
  );
};

export default HandleNumberQuestion;
