export interface LoginValues {
  emailId: string;
  password: string;
  }
  
  const initialLoginValues: LoginValues = {
    emailId:'',
    password: '',
  };
  
  export default initialLoginValues;