import React, { useState, useEffect } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  useMediaQuery,
  Divider,
  Badge,
} from "@mui/material";
import { useAuthContext } from "../../providers/AuthProvider";
import { Notification } from "../notifications/Notifications";
import MDEditor from "@uiw/react-md-editor";
import { useNotificationContext } from "../../providers/NotificationProvider";

const NotificationIcon = () => {
  const navigate = useNavigate();
  const { authHeader} = useAuthContext();
  const {alertNotification,setAlertNotification,notifications,unreadCount,setUnreadCount}=useNotificationContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  useEffect(()=>{
    setUnreadCount(notifications.filter((notification: { isRead: number; }) => !notification.isRead).length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authHeader,alertNotification,notifications,])
 

  
  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification: Notification) => {
    setAlertNotification(false);
    navigate("/notifications");
    handleClose();
  };

  const handleViewAllClick = () => {
    setAlertNotification(false);
    navigate("/notifications");
    handleClose();
  };

  const open = Boolean(anchorEl);
  const menuId = "notification-menu";

  return (
    <Box>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge color="error" badgeContent={unreadCount} invisible={!(unreadCount || alertNotification)}>
          { unreadCount ? (
            <NotificationsActiveIcon />
          ) : (
            <NotificationsNoneIcon />
          )}
        </Badge>
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isMobile ? "center" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "center" : "right",
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: isMobile ? "320px" : "320px",
              maxWidth: isMobile ? "350px" : "400px",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              maxHeight: "50vh",
              overflowY: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
            },
          },
        }}
      >
        <Box sx={{ overflowY: "auto", maxHeight: "40vh", px: 2, pt: 1 }}>
          {!unreadCount ? (
            <MenuItem disabled sx={{ justifyContent: "center" }}>
              No Unread Notifications
            </MenuItem>
          ) : (
            notifications.filter((notification)=>!notification.isRead).map((notification) => (
              <React.Fragment key={notification.notificationId}>
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    whiteSpace: "normal",
                    padding: "10px 15px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#F0F7FF",
                    },
                  }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginBottom: "0.25rem",
                      }}
                    >
                      {notification.notificationTitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "gray", fontSize: "0.875rem" }}
                    >
                      <MDEditor.Markdown
                        source={notification.notificationMDText}
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                        }}
                      />
                    </Typography>
                  </Box>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
              </React.Fragment>
            ))
          )}
        </Box>
        <Divider />
        <MenuItem
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "primary.main",
            fontWeight: "bold",
            padding: "10px 15px",
            "&:hover": {
              backgroundColor: "#F0F7FF",
            },
            position: "sticky",
            bottom: 0,
            backgroundColor: "#fff",
            borderTop: "1px solid #f0f0f0",
          }}
          onClick={handleViewAllClick}
        >
          View All Notifications
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NotificationIcon;
