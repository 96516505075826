
import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";

interface CheckboxGroupProps {
  question: string;
  questionId: string;
  answers: Record<string, string[]>; // Assuming answers is an object with string array values
  handleAnswerChange: (questionId: string, selectedOptions: string[]) => void;
  options: string[];
  disabled: boolean;
}

const HandleMultipleChoiceQuestion: React.FC<CheckboxGroupProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  options,
  disabled,
}) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h6">{question}</Typography>
      </FormLabel>
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={(answers[questionId] || []).includes(option)}
                disabled={disabled}
                onChange={(e) => {
                  const currentAnswer = answers[questionId] || [];
                  const selectedOptions = [...currentAnswer];

                  if (e.target.checked) {
                    selectedOptions.push(option);
                  } else {
                    const optionIndex = selectedOptions.indexOf(option);
                    if (optionIndex !== -1) {
                      selectedOptions.splice(optionIndex, 1);
                    }
                  }

                  handleAnswerChange(questionId, selectedOptions);
                }}
                name={`question_${questionId}_${option}`}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default HandleMultipleChoiceQuestion;
