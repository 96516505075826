import { PaletteOptions } from '@mui/material'
import { common, grey } from '@mui/material/colors'

const palette: PaletteOptions = {
  mode: 'light',
  background: {
    default: common.white,
    paper: '#F1F2F4',
  },
  text: {
    primary: grey[900],
    secondary: '#717171', // grey[700],
    disabled: grey[500],
  },
}

export default palette
