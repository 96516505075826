import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import { isNullOrEmpty } from "./onBoardingv2/Signup";

const AnyRoutes = () => {
  const { isAuthenticated, userDetails } = useAuthContext();
  if (isAuthenticated) {
    if (
      isNullOrEmpty(userDetails?.firstName) ||
      isNullOrEmpty(userDetails?.emailId) ||
      isNullOrEmpty(userDetails?.dob) || 
      isNullOrEmpty(userDetails?.lastName) ||
      isNullOrEmpty(userDetails?.gender)
    ) {
      return <Navigate to="/user-entry/register" />;
    }
  }
  return <Outlet />;
};

export default AnyRoutes;
