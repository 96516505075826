import {
    Card,
    CardContent,
    Typography,
    Box,
  } from "@mui/material";
  import { HtmlTooltip } from "../../styles/htmlTooltip";
import { Link } from "react-router-dom";
import CustomChip from "../endUserLandingPage/CustomChip";
import { CategoryListValues } from "./CategoryListValues";
import { useState } from "react";
import CategoryIcon from "../../pages/CategoryIcon";

interface CategoryItemProps {
    category: CategoryListValues;
  }
  
  const CategoryItem: React.FC<CategoryItemProps> = ({ category }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
    return (
      <Link
        to={`/categoryDetails/${category.categoryId}`}
        style={{ textDecoration: "none" }}
      >
        <Card
          variant="outlined"
          className=""
          sx={{
            backgroundColor: isHovered
              ? "background.paper"
              : "background.default",
            boxShadow: isHovered ? "0px 2px 5px rgba(0, 0, 0, 0.2)" : "none",
            borderColor: isHovered ? "#8A2BE2" : "none",
            minHeight: "220px",
            borderRadius: "16px",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CategoryIcon
                categoryId={category.categoryId}
                
              />
  
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  flexGrow: 1,
                  ml:"10px"
                }}
              >
                <Typography variant="h3">
                  {category.categoryName.charAt(0).toUpperCase() +
                    category.categoryName.slice(1)}
                </Typography>
  
                <Box
                  sx={{
                    fontSize: "small",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: "4px",
                    opacity: 0.7,
                  }}
                >
                  <HtmlTooltip title={<em>Total Number of Jobs.</em>}>
                    <CustomChip
                      label={`${category.noOfJobs?category.noOfJobs:"0"} jobs`}
                      additionalStyles={{
                        color: "blue",
                        backgroundColor: " lightBlue",
                        border: "1px solid blue",
                        borderRadius:"8px"
                      }}
                    />
                  </HtmlTooltip>
                  <HtmlTooltip title={<em>Work along your main job.</em>}>
                    <CustomChip
                      label="Part time"
                      additionalStyles={{
                        color: "green",
                        backgroundColor: "lightSkyBlue",
                        border: "1px solid green",
                        borderRadius:"8px"
                      }}
                    />
                  </HtmlTooltip>
                </Box>
              </Box>
            </Box>
            {category.basicRequirements && (
              <HtmlTooltip title={<em>{"Basic Requirements."}</em>}>
                <Typography sx={{ color: "text.secondary", fontSize: 16, mb: 2,  mt:1  }}>
                  <b>Basic Requirements: </b>
                  {category.basicRequirements}
                </Typography>
              </HtmlTooltip>
            )}
            {category.averageSalary && (
              <HtmlTooltip
                title={
                  <em>
                    {
                      "Earn guaranteed wages based on minimum tasks to perform everyday."
                    }
                  </em>
                }
              >
                <Typography sx={{ color: "text.secondary", fontSize: 16, mb: 2 }}>
                  <b>Average Salary: </b>
                  {category.averageSalary}
                </Typography>
              </HtmlTooltip>
            )}
          </CardContent>
        </Card>
      </Link>
    );
  };
  export default CategoryItem;