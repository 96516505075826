import React, { useEffect } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { ProfileData } from "../profile/ProfileValues";
import ProfileMenu from "../header/ProfileMenu";
import { Logo } from "../logo";
import { Close } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  signedInSideNavigationItems,
  guestSideNavigationItems,
  signedInSupervisorSideNavigation,
  auditSideNavigationItems,
} from "./SideNavigationRoutes";
import { Link as RouterLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthContext } from "../../providers/AuthProvider";
import { userRoles } from "../../types/userRoles";
import { WhatsappSupportIcon } from "../header/WhatsappSupportIcon";
import AuditProfileMenu from "../header/AuditProfileMenu";

interface SideNavigationProps {
  isOpen: boolean;
  onClose: () => void;
  profileData?: ProfileData;
  tourStep?: number | null;
}

const SideNavigation: React.FC<SideNavigationProps> = ({
  isOpen,
  onClose,
  profileData,
  tourStep,
}) => {
  const { isAuthenticated, logout } = useAuthContext();
  const { userDetails } = useAuthContext();
  const userRole = userDetails?.role;
  const closeMobileMenu = () => {};

  const navigationItems = isAuthenticated
    ? userRole === userRoles.external
      ? auditSideNavigationItems
      : userRole === userRoles.supervisor
      ? signedInSupervisorSideNavigation
      : signedInSideNavigationItems
    : guestSideNavigationItems; // for guest user
  console.log(userRole);
  console.log(userRoles.external);

  const currentStep = tourStep;

  useEffect(() => {
    if ((currentStep === 1 || currentStep === 3) && !isOpen) {
      onClose();
    }
  }, [currentStep, isOpen, onClose]);

  const handleLogout = () => {
    if (userRoles.external === userDetails?.role) {
      logout({ returnTo: "/SpoTrue" });
    } else {
      logout({ returnTo: "/" });
    }

    onClose();
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <List sx={{ width: "250px" }}>
        <Box
          sx={{
            padding: "5px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Logo />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: "black" }} />
        {isAuthenticated && (
          <>
            <Box className="profile-menu">
              {userDetails?.role === userRoles.external ? (
                <AuditProfileMenu
                  profileData={profileData}
                  closeMobileMenu={closeMobileMenu}
                  onClick={onClose}
                />
              ) : (
                <ProfileMenu
                  profileData={profileData}
                  closeMobileMenu={closeMobileMenu}
                  onClick={onClose}
                />
              )}
            </Box>
            <Divider />
          </>
        )}
        {navigationItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <Typography
                component={RouterLink}
                to={item.path || ""}
                onClick={onClose}
                sx={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
                className={
                  item.path === "/browse-jobs"
                    ? "BrowseGigs-mobile"
                    : item.path === "/task/gigHistory"
                    ? "dashboard-mobile"
                    : ""
                }
              >
                <ListItemText primary={item.label} />
                <KeyboardArrowRightIcon sx={{ fontSize: "20px", right: "0" }} />
              </Typography>
            </ListItem>
            <Divider sx={{ margin: "0px 6px" }} />
          </React.Fragment>
        ))}
      </List>
      {isAuthenticated && (
        <>
          <MenuItem
            onClick={handleLogout}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "&:hover": {
                backgroundColor: "#F0F7FF",
                borderRadius: "10px",
              },
              width: "100%",
            }}
          >
            Logout
            <LogoutIcon sx={{ fontSize: "20px" }} />
          </MenuItem>
          <Divider />
          <Box className="profile-menu">
            <WhatsappSupportIcon />
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default SideNavigation;
