import React from "react";
import AadharPanImageForm from "./AadharPanForm";
import CustomModal from "../custom/CustomModal";

interface DocumentUploadDialogProps {
  open: boolean;
  onClose: () => void;
}

const UploadDocumentsPopup: React.FC<DocumentUploadDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title="Upload Documents"
      isLoading={false}
    >
      <AadharPanImageForm onCloseDialog={onClose} />
    </CustomModal>
  );
};

export default UploadDocumentsPopup;
