import { Autocomplete, TextField, Typography } from "@mui/material"

const skillSetsLabels = ["Written & Spoken English", "Persuasion and Negotiation", "People Management", "Social Media Marketing", "Technical/Data Entry", "Sales", "Driving (Two Wheeler)", "Driving (Three or Four Wheeler)"];
const majorLanguages = [
  "Assamese (India)",
  "Bengali (India)",
  "English (India)",
  "Gujarati (India)",
  "Hindi (India)",
  "Kannada (India)",
  "Konkani (India)",
  "Marathi (India)",
  "Nepali",
  "Odia (India)",
];
interface SkillSetsProps {
  skillSets: string,
  languages: string,
  handleLanguagesChange: (e: React.ChangeEvent<{}>, value: string[]) => void
  handleSkillChange: (e: React.ChangeEvent<{}>, value: string[]) => void
  emptyFieldsError: string | null;
}
const SkillSets = ({ skillSets, languages, handleLanguagesChange, handleSkillChange, emptyFieldsError }: SkillSetsProps) => {

  return (
    <>
      <Typography sx={{ fontFamily: 'inter, sans-serif', fontSize: '24px' }}>
        Second, what skills do you possess?
      </Typography>
      <Typography sx={{ fontFamily: 'inter, sans-serif', fontSize: '14px' }}>
        Please select multiple skills and languages you know ...
      </Typography>
      <Autocomplete
        sx={{ maxWidth: '400px' }}
        multiple
        options={skillSetsLabels}
        onChange={handleSkillChange}
        value={(skillSets || '').split(', ').filter((lang: string) => skillSetsLabels.includes(lang))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Skill Sets"
            margin="normal"
          />
        )}
        filterOptions={(options) => options.filter((option) => option.trim() !== '')}
      />
      <Autocomplete
        sx={{ maxWidth: '400px' }}
        multiple
        options={majorLanguages}
        onChange={handleLanguagesChange}
        value={(languages || '').split(', ').filter((lang: string) => majorLanguages.includes(lang))}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Languages"
            margin="normal"
          />
        )}
        filterOptions={(options) => options.filter((option) => option.trim() !== '')}
      />
      {emptyFieldsError && !languages && (
        <Typography color="error">Please enter languages you know</Typography>
      )}
    </>
  )
}

export default SkillSets;