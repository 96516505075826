import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Paper,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDailyEarning } from "@gogig-in/gogig-clients";
import { groupDailyData } from "./GroupingDailyData";
import { useAuthContext } from "../../providers/AuthProvider";

interface JobData {
  jobNumber: number;
  earnings: number;
}

interface DailyEarning {
  date: string;
  totalEarned: number;
  data: JobData[];
}

const LoadingSkeleton: React.FC = () => (
  <Paper elevation={3} style={{ backgroundColor: "#4CAF50", color: "white" }}>
    <Card>
      <CardContent>
        <Skeleton variant="text" width={100} height={40} />
        <Skeleton variant="text" width={80} height={20} />
      </CardContent>
    </Card>
  </Paper>
);

const DailyEarnings: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [dailyEarnings, setDailyEarnings] = useState<DailyEarning[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { authHeader } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const response: any = await getDailyEarning(authHeader);
          const dailyData = response.data;
          const groupedData = groupDailyData(dailyData);
          setDailyEarnings(groupedData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    fetchData();
  }, [authHeader]);

  const handleDateChange = (date: string | null) => {
    setSelectedDate((prevSelectedDate) =>
      prevSelectedDate === date ? null : date
    );
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" gutterBottom>
        Daily Earnings
      </Typography>

      {isLoading ? (
        <>
          <LoadingSkeleton />
          <LoadingSkeleton />
          <LoadingSkeleton />
        </>
      ) : (
        dailyEarnings.map((entry: DailyEarning) => (
          <Accordion
            key={entry.date}
            expanded={isMobile ? true : selectedDate === entry.date}
            onChange={() => handleDateChange(entry.date)}
            sx={{ backgroundColor: "background.default", marginBottom: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{entry.date}</Typography>
              <Typography style={{ marginLeft: "auto" }}>
                Total Earned: {entry.totalEarned}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
              }}
            >
              {entry.data.map((job: JobData) => (
                <Paper
                  key={job.jobNumber}
                  elevation={3}
                  style={{ backgroundColor: "#4CAF50", color: "white" }}
                >
                  <Card sx={{ backgroundColor: "background.default" }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Job {job.jobNumber}
                      </Typography>
                      <Typography>Earned: {job.earnings}</Typography>
                    </CardContent>
                  </Card>
                </Paper>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Container>
  );
};

export default DailyEarnings;
