import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Slide,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../theme";

interface AddDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (formData: any) => void;
  dialogType: "bank" | "upi";
  initialData: any;
  error: string | null;
}

const Transition = React.forwardRef(function Transition(
  props: any,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddAccountDialog: React.FC<AddDialogProps> = ({
  open,
  onClose,
  onSave,
  dialogType,
  initialData,
  error,
}) => {
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [open, initialData]);

  const handleSave = () => {
    let formDataToSend = { ...formData, type: dialogType };
    if (dialogType === "upi") {
      formDataToSend = {
        upiId: formData.upiId,
        name: formData.name,
        type: dialogType,
      };
    } else if (dialogType === "bank") {
      formDataToSend = {
        type: dialogType,
        name: formData.name,
        accountNo: formData.accountNo,
        confirmAccountNumber: formData.confirmAccountNumber,
        ifsc: formData.ifsc,
      };
    }
    onSave(formDataToSend);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        <DialogTitle>
          {dialogType === "bank"
            ? "Enter Bank Account Details"
            : "Enter UPI ID Details"}
        </DialogTitle>
        <CloseIcon onClick={onClose} sx={{ paddingRight: "10px" }} />
      </div>
      <DialogContent sx={{ padding: "2px 20px" }}>
        <TextField
          placeholder="Please enter name"
          fullWidth
          margin="normal"
          autoComplete="cc-name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          InputProps={{
            sx: {
              borderRadius: "25px",
              borderColor: "rgba(0, 0, 0, 0.23)",
              "&:hover": {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
        />
        {dialogType === "bank" ? (
          <>
            <TextField
              placeholder="Account Number"
              fullWidth
              autoComplete="cc-number"
              margin="normal"
              value={formData.accountNo}
              onChange={(e) =>
                setFormData({ ...formData, accountNo: e.target.value })
              }
              InputProps={{
                inputMode: "numeric",
                sx: {
                  borderRadius: "25px",
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  "&:hover": {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
            <TextField
              placeholder="Confirm Account Number"
              fullWidth
              autoComplete="cc-number"
              margin="normal"
              value={formData.confirmAccountNumber}
              InputProps={{
                inputMode: "numeric",
                sx: {
                  borderRadius: "25px",
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  "&:hover": {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
            <TextField
              placeholder="IFSC Code"
              fullWidth
              autoComplete="off"
              margin="normal"
              value={formData.ifsc}
              onChange={(e) =>
                setFormData({ ...formData, ifsc: e.target.value })
              }
              InputProps={{
                sx: {
                  borderRadius: "25px",
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  "&:hover": {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
          </>
        ) : (
          <TextField
            placeholder="UPI ID"
            fullWidth
            autoComplete="off"
            margin="normal"
            value={formData.upiId}
            onChange={(e) =>
              setFormData({ ...formData, upiId: e.target.value })
            }
            InputProps={{
              sx: {
                borderRadius: "25px",
                borderColor: "rgba(0, 0, 0, 0.23)",
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
          />
        )}
      </DialogContent>

      <DialogActions
        sx={{ display: "flex", justifyContent: "center", padding: "15px" }}
      >
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{ display: "flex", width: "80%", borderRadius: "35px" }}
        >
          {dialogType === "bank" ? "SAVE BANK ACCOUNT" : "SAVE UPI ID"}
        </Button>
      </DialogActions>
      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          {error}
        </Typography>
      )}
    </Dialog>
  );
};

export default AddAccountDialog;
