import { Box, Typography } from "@mui/material";
import { NotificationPayload } from "firebase/messaging";


export const Message = ({
  notification
  ,
}: {
  notification: NotificationPayload | undefined;
}) => {
  return (
    <>
      <Box>
        {/* image is optional */}
        {notification?.image && (
          <Box>
            <img src={notification.image} alt="alertNotification" width={100} />
          </Box>
        )}
        <Typography>{notification?.title}</Typography>
      </Box>
      <Box id="notificationBody">{notification?.body}</Box>

    </>
  );
};

export default Message;