import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import { useAuthContext } from "../../providers/AuthProvider";
import { getWalletCredentials } from "@gogig-in/gogig-clients";
import { UserDocument } from "./UserDocumentTypes";
import AadharPanImageForm from "./AadharPanForm";
import theme from "../../theme";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ProfileCard from "../profile/ProfileCard";
import CustomModal from "../custom/CustomModal";
import UploadDocumentsPopup from "./UploadDocumentsPopup";

function addDashes(str: string) {
  return str.replace(/(.{4})/g, "$1-").slice(0, -1);
}

const UserDocumentData = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [userDocuments, setUserDocuments] = useState<UserDocument[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const [aadharNumber, setAadharNumber] = useState<string>(""); // Initialize state for Aadhaar number
  const [panNumber, setPanNumber] = useState<string>(""); // Initialize state for PAN number
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { authHeader } = useAuthContext();

  const snackbar = (message: string, severity: Severity) => {
    setSnackbarData({ message, severity });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const fetchUserDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await getWalletCredentials(authHeader);
        const data: UserDocument = response.data[0];
        setAadharNumber(data.aadhaarCardNumber);
        setPanNumber(data.panCardNumber);
        setUserDocuments(response);
      }
    } catch (error: any) {
      if (error.status === 401) {
        setOpenSnackbar(true);
        snackbar("Unauthorized access. Please log in again", "error");
      } else {
        console.error("An error occurredWhile fetching documents:", error);
      }
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  const handleClose = useCallback(() => {
    setOpenSnackbar(false);
    setOpenDialog(false);
    fetchUserDocument(); // Fetch data after Dialog is closed
  }, [fetchUserDocument]);

  useEffect(() => {
    if (authHeader) {
      fetchUserDocument();
    }
  }, [authHeader, fetchUserDocument]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        severity={snackbarData.severity}
        vertical={"top"}
        horizontal={"center"}
      />

      <CustomModal
        open={openDialog}
        onClose={handleDialogClose}
        title="Upload Documents"
        isLoading={false}
      >
        <AadharPanImageForm onCloseDialog={handleClose} />
      </CustomModal>
      <UploadDocumentsPopup open={openDialog} onClose={handleDialogClose} />

      <ProfileCard
        title="Documents"
        editTooltipText="Edit Documents"
        onDialogOpen={handleDialogOpen}
        isLoading={isLoading}
      >
        {aadharNumber.length === 0 && panNumber.length === 0 ? (
          <>
            <Typography variant="h5" align="center">
              {userDocuments.length === 0
                ? "No Aadhar & PAN document available"
                : "No Documents available"}
            </Typography>
            <br />
            <Button
              variant="outlined"
              style={{ marginLeft: "10px" }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleDialogOpen}
            >
              Add Documents
            </Button>
          </>
        ) : (
          <Box>
            <Typography sx={{ fontWeight: "600" }}>
              Aadhar Card Number:{" "}
              <span
                style={{
                  fontWeight: "400",
                  display: isMobile ? "block" : "",
                }}
              >
                {addDashes(aadharNumber)}
              </span>
            </Typography>
            <Typography sx={{ fontWeight: "600" }}>
              PAN Card Number:{" "}
              <span
                style={{
                  fontWeight: "400",
                  display: isMobile ? "block" : "",
                }}
              >
                {panNumber}
              </span>
            </Typography>
          </Box>
        )}
      </ProfileCard>
    </>
  );
};

export default UserDocumentData;
