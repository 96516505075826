// BankDialog.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoading from "../custom/Loading";
import BankDetailsForm from "./BankDetailsForm";
import theme from "../../theme";

interface BankDialogProps {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  onCloseBankForm: () => void;
  fetchUserBankData: () => void;
}

const BankDialog: React.FC<BankDialogProps> = ({
  open,
  onClose,
  isLoading,
  onCloseBankForm,
  fetchUserBankData,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle>
        Bank Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isLoading ? (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "200px",
            minWidth: "400px",
          }}
        >
          <CustomLoading />
        </Container>
      ) : (
        <DialogContent>
          <BankDetailsForm
            onCloseDialog={onCloseBankForm}
            fetchUserBankData={fetchUserBankData}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default BankDialog;
