import {
  saveBookmarkDetails,
  removeBookmarkDetails,
  getBookmarkDetails,
} from "@gogig-in/gogig-clients";
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  CardActions,
  Box,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import { HtmlTooltip } from "../styles/htmlTooltip";
import { CategoryGigListDetails } from "./categoryListings/CategoryGigListing";
import CustomChip from "./endUserLandingPage/CustomChip";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarIcon from "@mui/icons-material/Star";
import { gigRequirementsText } from "../utils/gigRequirementsText";

interface BookmarkData {
  bookmarkId: string;
  gigId: string;
}

interface CategoryGigItemProps {
  categoryGig: CategoryGigListDetails;
  bookmarkData: BookmarkData[];
}


const CategoryGigItem: React.FC<CategoryGigItemProps> = ({
  categoryGig,
  bookmarkData,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [checked, setChecked] = useState(false);
  const { isAuthenticated, authHeader, loginWithRedirect } = useAuthContext();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  

  useEffect(() => {
    const isGigMatched = bookmarkData.find(
      (gig) => gig.gigId === categoryGig.gigId
    );
    if (isGigMatched) {
      setChecked(true);
    }
  }, [bookmarkData, categoryGig.gigId]);

  const saveBookmark = async () => {
    try {
      setChecked(true);
      if (authHeader) {
        await saveBookmarkDetails(categoryGig.gigId, authHeader);
      }
    } catch (error) {
      console.error("Error saving bookmark:", error);
      setChecked(false);
    }
  };

  const removeBookmark = async () => {
    try {
      setChecked(false);
      if (!bookmarkData || !categoryGig || !categoryGig.gigId) {
        return;
      }

      const matchedData = bookmarkData.find(
        (data) => data.gigId === categoryGig.gigId
      );

      if (matchedData?.bookmarkId && authHeader) {
        if (authHeader) {
          await removeBookmarkDetails(matchedData.bookmarkId, authHeader);
        }
      } else {
        if (authHeader) {
          const getBookmarkData: any = await getBookmarkDetails(authHeader);
          const matchedData = getBookmarkData.data.find(
            (data: { gigId: string; }) => data.gigId === categoryGig.gigId
          );
          if (matchedData?.bookmarkId && authHeader) {
            await removeBookmarkDetails(matchedData.bookmarkId, authHeader);
          }
        }
      }
    } catch (error) {
      console.error("Error removing bookmark:", error);
      setChecked(true);
    }
  };

  const handleBookmarkClick = () => {
    if (isAuthenticated) {
      if (checked) {
        removeBookmark();
      } else {
        saveBookmark();
      }
    } else {
      loginWithRedirect({ returnTo: window.location.pathname });
    }
  };
  

  return (
    <Link
      style={{ textDecoration: "none" }}
      to={`/task/gig/${categoryGig.gigId}/${categoryGig.visibilityId}`}
    >
      <Card
        variant="outlined"
        className="gigListCard"
        sx={{
          backgroundColor: "background.paper",
          boxShadow: isHovered ? "0px 2px 5px rgba(0, 0, 0, 0.2)" : "none",
          borderColor: isHovered ? "#8A2BE2" : "none",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          minHeight: "260px",
          maxHeight: "260px",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardHeader title={<strong>{categoryGig.gigTitle}</strong>} />
        <CardContent sx={{ flex: 1, pt: "0" }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",

                  textOverflow: "ellipsis",
                }}
              >
                <strong>Basic Requirements :</strong>{" "}
                {gigRequirementsText({ categoryGig: categoryGig, maxLength: 50 })}              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" component="div">
                <strong>Average Salary: </strong>₹{categoryGig.minWagePerDay}{" "}
                per day
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            pt: "0px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Grid item>
            <Box display="flex" flexDirection="column">
              <Box>
                {categoryGig.companyLogo ? (
                  <img
                    src={categoryGig?.companyLogo}
                    alt={categoryGig.companyName}
                    style={{
                      maxWidth: "190px",
                      maxHeight: "50px",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logos/gmg-logo.png`}
                    alt="company Logo"
                    style={{
                      maxWidth: "90px",
                      marginRight: "10px",
                    }}
                  />
                )}
              </Box>
              <Box>
                <HtmlTooltip title={<em>Type of your job.</em>}>
                  <CustomChip
                    label="part time"
                    additionalStyles={{
                      color: "green",
                      backgroundColor: "lightSkyBlue",
                      border: "1px solid green",
                      borderRadius: "8px",
                    }}
                  />
                </HtmlTooltip>
                {categoryGig.site && (
                  <HtmlTooltip
                    title={<em>It's a survey to be collected on call.</em>}
                  >
                    <CustomChip
                      label={categoryGig.site}
                      additionalStyles={{
                        color: "blue",
                        backgroundColor: "lightBlue",
                        border: "1px solid blue",
                        borderRadius: "8px",
                        padding: "",
                      }}
                    />
                  </HtmlTooltip>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            {checked ? (
              <Button
                size="medium"
                variant="outlined"
                startIcon={<StarIcon />}
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigation
                  handleBookmarkClick();
                }}
                sx={{
                  height: "20%",
                  borderRadius: "12px",
                }}
              >
                saved
              </Button>
            ) : (
              <Button
                size="medium"
                variant="outlined"
                startIcon={<StarOutlineRoundedIcon />}
                onClick={(e) => {
                  e.preventDefault(); // Prevent navigation
                  handleBookmarkClick();
                }}
                sx={{
                  height: "20%",
                  borderRadius: "12px",
                }}
              >
                save
              </Button>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Link>
  );
};
export default CategoryGigItem;
