import React, { useState } from 'react';
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import faqs from "./../../Locale/FAQ.json";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQCategory: React.FC = () => {
  const [openedCategories, setOpenedCategories] = useState<number[]>([]);
  const [openedPanels, setOpenedPanels] = useState<{ [key: number]: number[] }>({});

  const handleCategoryChange = (index: number) => {
    if (openedCategories.includes(index)) {
      setOpenedCategories(openedCategories.filter((item) => item !== index));
    } else {
      setOpenedCategories([...openedCategories, index]);
    }
  };

  const handleAccordionChange = (catIndex: number, index: number) => {
    setOpenedPanels((prev) => {
      const currentOpenedPanels = prev[catIndex] || [];
      if (currentOpenedPanels.includes(index)) {
        return {
          ...prev,
          [catIndex]: currentOpenedPanels.filter((panelIndex) => panelIndex !== index),
        };
      } else {
        return {
          ...prev,
          [catIndex]: [...currentOpenedPanels, index],
        };
      }
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {faqs.categories.map((category, catIndex) => (
          <Grid item xs={12} key={catIndex}>
            <Accordion
              expanded={openedCategories.includes(catIndex)}
              onChange={() => handleCategoryChange(catIndex)}
              sx={{
                backgroundColor: 'rgb(255, 255, 255)',
                border: "1px solid grey",
                boxShadow: 'rgba(0, 0, 0, 0.2) 3px 2px 1px 1px',
                transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                position: 'relative',
                color: 'rgba(0, 0, 0, 0.87)',
                overflowAnchor: 'none',
                marginBottom: '10px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${catIndex}`}
                id={`panel-header-${catIndex}`}
              >
                <Typography>{category.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {openedCategories.includes(catIndex) && (
                  category.data.map((item, subIndex) => (
                    <Accordion
                      key={subIndex}
                      expanded={openedPanels[catIndex]?.includes(subIndex)}
                      onChange={() => handleAccordionChange(catIndex, subIndex)}
                      sx={{
                        backgroundColor: 'rgb(245, 245, 245)',
                        boxShadow: 'none',
                        transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        borderRadius: '0px',
                        position: 'relative',
                        color: 'rgba(0, 0, 0, 0.87)',
                        overflowAnchor: 'none',
                        marginBottom: '10px'
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`subpanel-content-${catIndex}-${subIndex}`}
                        id={`subpanel-header-${catIndex}-${subIndex}`}
                      >
                        <Typography>{item.Q}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div dangerouslySetInnerHTML={{ __html: item.A }} />
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FAQCategory;
