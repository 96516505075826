import { fetchWrapper } from "@gogig-in/gogig-clients";
import { AuthApiBaseUrl } from "./baseUrls";

export async function saveFCMToken(
  authHeader: string,
  fcmToken: string ,
  deviceType: string | undefined
) {
  return fetchWrapper(
    `${AuthApiBaseUrl}/saveFCMToken`,
    {
      method: "POST",
      headers: {
        Authorization: authHeader,
      },
      body: {
        token: fcmToken,
        deviceType,
      },
    }
  );
}