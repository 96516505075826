import React from "react";
import { Button, Container, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import { downloadDataSet } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";

interface DatasetControlsProps {
  datasetName: string;
}

const DatasetControls: React.FC<DatasetControlsProps> = ({ datasetName }) => {
  const { gigId } = useParams<{ gigId?: string }>();
  const history = useNavigate();
  const { authHeader } = useAuthContext();

  const redirectToViewFormat = () => {
    history(`/gig-result/${gigId}/view-format`, {
      state: { datasetName: datasetName },
    });
  };

  const redirectToUploadCSV = () => {
    history(`/gig-result/${gigId}/upload-csv`, {
      state: { datasetName: datasetName },
    });
  };

  const downloadCsv = async (datasetName: string) => {
    try {
      if (authHeader && gigId) {
        const response: any = await downloadDataSet(
          authHeader,
          datasetName,
          gigId
        );

        if (response.data && response.data.length > 0) {
          const dataArray = response.data;

          // Extract keys from the first object to use as headers
          const headers = Object.keys(dataArray[0]);

          const csvContent = [
            headers.join(","), // Add headers as the first row
            ...dataArray.map((obj: any) =>
              headers
                .map((key) => {
                  const field = obj[key];
                  let fieldValue =
                    field !== undefined && field !== null
                      ? field.toString()
                      : "";

                  // Check if the field value contains a comma, if yes, then enclose it within double quotes
                  if (fieldValue.includes(",")) {
                    // Escape double quotes by doubling them and then enclose the field in double quotes
                    fieldValue = `"${fieldValue.replace(/"/g, '""')}"`;
                  }

                  return fieldValue;
                })
                .join(",")
            ), // Map each object to a CSV row
          ];

          const blob = new Blob([csvContent.join("\n")], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${datasetName}.csv`;
          link.click();
        } else {
          console.error("No data to download");
        }
      }
    } catch (error) {
      console.error("error during download data set",error);
    }
  };

  return (
    <>
      <Typography>{datasetName}</Typography>
      <Button
        variant="outlined"
        sx={{ margin: "5px" }}
        onClick={() => downloadCsv(datasetName)}
      >
        Download
        <DownloadIcon sx={{ marginLeft: "5px" }} />
      </Button>
      <Button
        variant="outlined"
        sx={{ margin: "5px" }}
        onClick={() => redirectToUploadCSV()}
      >
        Upload
        <CloudUploadIcon sx={{ marginLeft: "5px" }} />
      </Button>
      <Button
        variant="outlined"
        sx={{ margin: "5px" }}
        onClick={() => redirectToViewFormat()}
      >
        View format
      </Button>
    </>
  );
};

const AdminDashboard = () => {
  return (
    <Container>
      <DatasetControls datasetName="baseData" />

      <DatasetControls datasetName="extendedData" />

      <DatasetControls datasetName="finalizedData" />
    </Container>
  );
};

export default AdminDashboard;
