import React, { useRef, useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { getUserAddress, createProfile } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";
import AlertDialog from "../custom/AlertDialog";
import ClearIcon from "@mui/icons-material/Clear";
import CustomModal from "../custom/CustomModal";

interface addressProps {
  openDialog: boolean;
  onClose: () => void;
}

const SetUserAddressPopup: React.FC<addressProps> = ({
  openDialog,
  onClose,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const [cityName, setCityName] = useState<string>("");
  const [areaName, setAreaName] = useState<string>("");
  const [zipCode, setZipCode] = useState<number | string>();
  const [userAddress, setUserAddress] = useState<any>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const { authHeader } = useAuthContext();
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const loadGoogleMapsScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCKtf2MVyTl3MIOac35LKedSTV00pLopVk&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      if (inputRef.current) {
        const options = {
          fields: ["formatted_address", "geometry", "address_component"],
          strictBounds: false,
        };
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          options
        );
        autocomplete.setComponentRestrictions({
          country: ["in"],
        });
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            setSelectedAddress(place.formatted_address);
            if (place.geometry && place.geometry.location) {
              setCoordinates({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }
            if (place.address_components) {
              place.address_components.forEach((component) => {
                if (component.types[0] === "locality") {
                  setCityName(component.long_name);
                }
                if (component.types[0] === "sublocality_level_1") {
                  setAreaName(component.long_name);
                }
                if (component.types[0] === "postal_code") {
                  setZipCode(component.long_name);
                }
              });
            }
          }
        });
      }
    };

    document.head.appendChild(script);
  };

  useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        if (authHeader) {
          const response = await getUserAddress(authHeader);
          setUserAddress(response.data);
        }
      } catch (error: any) {
        if (error.status === 404 || error.errorCause === "detailsNotFound")
          console.error("Error fetching user address", error);
      }
    };
    fetchUserAddress();
  }, [authHeader]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    if (coordinates) {
      try {
        const formValues = {
          addressLine: selectedAddress || "",
          latitude: coordinates?.lat?.toString() || "",
          longitude: coordinates?.lng?.toString() || "",
          workLocation: cityName || "",
          area: areaName || "",
          pincode: zipCode || "",
        };

        if (authHeader) {
          try {
            const response: any = await createProfile(
              formValues,
              authHeader,
              "PATCH"
            );

            const message = response.data;
            if (message === "Profile details saved successfully!!") {
              setSnackbarOpen(true);
              setTimeout(() => {
                onClose();
                handleClose("main");
                handleClose("confirm");
              }, 2000);
            }
          } catch (err) {
            console.error("Error during  createProfile",err);
          }
        }
      } catch (error) {
        console.error("Error saving profile details", error);
      }
    }
  };

  const clearText = () => {
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear the input value
      inputRef.current.focus(); // Keep focus on the input field after clearing
    }
  };

  const handleClose = (dialogType: "main" | "confirm") => {
    if (dialogType === "main") {
      onClose();
    } else if (dialogType === "confirm") {
      setConfirmDialog(false);
    }
  };

  const handleRequestPermission = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCoordinates({ lat, lng });

          const geocoder = new window.google.maps.Geocoder();
          const response: any = await geocoder.geocode({
            location: { lat, lng },
          });
          if (response.results[0]) {
            setSelectedAddress(response.results[0].formatted_address);
            const city = response.results[0].address_components.find(
              (component: { types: string | string[] }) =>
                component.types.includes("locality")
            );
            const area: any = response.results[0].address_components.find(
              (component: any) =>
                component.types.includes("sublocality_level_1")
            );
            const postalCode = response.results[0].address_components.find(
              (component: any) => component.types.includes("postal_code")
            );
            setCityName(city ? city.long_name : "");
            setAreaName(area ? area.long_name : "");
            setZipCode(postalCode ? postalCode.long_name : "");
            setLoading(false);
          }
        },
        (error) => {
          console.error("Error fetching geolocation", error);
          setLocationPermissionDenied(false);
          setLoading(false);
        }
      );
    }
  };

  const handleConfirmLocation = () => {
    if (selectedAddress || coordinates) {
      setConfirmDialog(true);
    } else {
      alert(`Please select your location${selectedAddress}`);
    }
  };

  return (
    <CustomModal
      open={openDialog}
      onClose={onClose}
      title="Location details"
      isLoading={false}
      primaryActionOnclick={handleConfirmLocation}
      primaryActionText="Confirm"
      secondaryActionOnclick={() => handleClose("main")}
      secondaryActionText="Cancel"
    >
      <Box>
        <Typography>
          {selectedAddress ? (
            <>
              <Typography component="span" fontWeight="bold">
                Current Location:{" "}
              </Typography>
              {selectedAddress}
            </>
          ) : (
            ""
          )}
        </Typography>
        <Box textAlign={"center"}>
          <Button
            onClick={handleRequestPermission}
            color="primary"
            variant="text"
            sx={{ marginTop: "10px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Use Current Location"}
          </Button>
        </Box>
        <Typography textAlign="center" my={2}>
          Or
        </Typography>
        <Box>
          {userAddress && userAddress.addressLine && (
            <>
              <Typography>
                <strong>Saved Address:</strong>
                {userAddress.addressLine}
              </Typography>
            </>
          )}
        </Box>
        <Box>
          <Autocomplete
            freeSolo
            disableClearable
            options={[]}
            value={selectedAddress || ""}
            onChange={(_, newValue) => setSelectedAddress(newValue)}
            renderInput={(params) => (
              <div style={{ zIndex: 2 }}>
                <TextField
                  variant="standard"
                  {...params}
                  inputRef={inputRef}
                  label="Search Place"
                  name="addressLine"
                  size="small"
                  sx={{ marginTop: "10px" }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear"
                            onClick={clearText}
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        ></Box>
        <Snackbar open={snackbarOpen} autoHideDuration={3000}>
          <Alert onClose={handleSnackbarClose} severity="success">
            Address Updated Successfully!
          </Alert>
        </Snackbar>
        {locationPermissionDenied && (
          <Typography color="error" sx={{ marginTop: "10px" }}>
            Location permission is denied. Please enable location services in
            your browser settings.
          </Typography>
        )}
        <AlertDialog
          open={confirmDialog}
          title={"Location confirmation"}
          content={`Are you sure this is your location ${
            areaName && cityName ? `${areaName}, ${cityName}` : ""
          }?`}
          onDisagree={() => handleClose("confirm")}
          onAgree={handleSubmit}
          onClose={() => handleClose("confirm")}
        />
      </Box>
    </CustomModal>
  );
};

export default SetUserAddressPopup;
