import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WithdrawStatus: React.FC = () => {
  const [timer, setTimer] = useState<number>(72 * 60 * 60);
  const navigate = useNavigate();
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);
  const handleSubmit = () => {
    navigate("/wallet")
  };
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const timerText = formatTime(timer);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: 450,
          maxHeight: "600px",
          width: "100%",
          marginBottom: "20px",
          backgroundColor: "background.default",
          marginTop: "50px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 100,
            height: 100,
            margin: "auto",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <CircularProgress
            variant="determinate"
            value={(timer / (72 * 60 * 60)) * 100}
            size={100}
            thickness={1.5}
            sx={{ color: "orange" }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "100%",
              marginLeft: "-5px",
              width: "10px",
              height: "10px",
              backgroundColor: "orange",
              borderRadius: "50%",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "orange" }}>
              {timerText}
            </Typography>
          </Box>
        </Box>

        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Withdrawal request received
        </Typography>

        <Typography variant="body1">
          We will notify you when the payment is credited to your requested bank account.
        </Typography>
      </Box>
      <Button onClick={handleSubmit} sx={{margin:"20px"}} variant="outlined">
            Okay
          </Button>
    </Box>
  );
};

export default WithdrawStatus;
