import React, { useState, useEffect, useCallback } from "react";
import HandleNumberQuestion from "./HandleNumberQues";
import HandleTextQues from "./HandleTextQues";
import HandleBooleanQuestion from "./HandleBooleanQues";
import HandleMultipleChoiceQuestion from "./HandleMultipleChoiceQues";
import HandleSingleChoiceQuestions from "./HandleSingleChoiceQues";
import HandleRatingQuestion from "./HandleRatingQuestion";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import LoadingSpinner from "../LoadingAnimation";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  addSubordinateToSupervisor,
  getPreApprovanceForm,
} from "@gogig-in/gogig-clients";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../operationRolePages/CustomSnackbar";

interface GigFormProps {
  supervisorId: string | undefined;
  gigId: string;
  gigTitle: string;
  handleClose: () => void; // Adding the handleClose prop
}

const GigInviteForm: React.FC<GigFormProps> = ({
  supervisorId,
  gigId,
  gigTitle,
  handleClose,
}) => {
  const [answers, setAnswers] = useState<{ [key: string]: any }>({});
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { authHeader } = useAuthContext();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questionaireResponse, setQuestionaireResponse] = useState<any>({});
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const isFormFilled = () => {
    const questionIds = Object.keys(questionaireResponse);
    for (const questionId of questionIds) {
      if (!answers[questionId]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gigId) {
          const response: any = await getPreApprovanceForm(gigId);
          setQuestionaireResponse(response.data.payload || {});
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error during getPreApprovanceForm",e);
      }
    })();
  }, [authHeader, gigId]);

  function renderQuestionControl(
    questionNumber: number,
    questionId: string,
    questionData: any,
    answers: any,
    handleAnswerChange: (question: string, answer: any) => void
  ) {
    switch (questionData.type) {
      case "singleChoice":
        return (
          <HandleSingleChoiceQuestions
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={
              Array.isArray(questionData.answer) ? questionData.answer : []
            }
          />
        );

      case "multiChoice":
        return (
          <HandleMultipleChoiceQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            options={
              Array.isArray(questionData.answer) ? questionData.answer : []
            }
          />
        );

      case "boolean":
        return (
          <HandleBooleanQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
          />
        );

      case "text":
        return (
          <HandleTextQues
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
            gridSpace={questionData.question.length > 74 ? 12 : 6}
          />
        );

      case "number":
        return (
          <HandleNumberQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
          />
        );

      case "rating":
        return (
          <HandleRatingQuestion
            question={`${questionNumber}. ${questionData.question}`}
            questionId={questionId}
            answers={answers}
            handleAnswerChange={handleAnswerChange}
          />
        );

      default:
        return null;
    }
  }
  const handleAnswerChange = useCallback((questionId: string, answer: any) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (authHeader && gigId && supervisorId) {
        Object.keys(answers).forEach((questionId) => {
          const questionResponse = questionaireResponse[questionId] || {
            answer: "",
            question: "",
          };
          questionResponse.answer = answers[questionId];
          questionaireResponse[questionId] = questionResponse;
        });

        const updatedQuestionaireResponse =
          JSON.stringify(questionaireResponse);
        const updatedQuestionaireResponse1 = JSON.parse(
          updatedQuestionaireResponse
        );
        await addSubordinateToSupervisor(
          supervisorId,
          authHeader,
          gigId,
          updatedQuestionaireResponse1
        );
        setIsModalOpen(false);
        handleSnackbar("success", "Gig Invitation accepted successfully.");
        setTimeout(() => {
          navigate("/task/gigHistory");
        }, 3000);
      }
    } catch (error: any) {
      if (error.status === 409 && error.errorCause === "gigInviteAccepted") {
        setMessage("The gig invitation is already accepted by you.");
      } else {
        console.error("Error fetching gig details", error);
        setMessage("An error occurred. Please try again after sometime.");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    handleClose();
    setAnswers({});
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            [theme.breakpoints.down("sm")]: {
              minWidth: "300px",
            },
            [theme.breakpoints.up("md")]: {
              minWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle sx={{ fontSize: "28px", fontWeight: "bold" }}>
          {gigTitle || "Enter Details"}
        </DialogTitle>
        {!message && (
          <>
            <DialogContent>
              {isLoading ? (
                <LoadingSpinner label="Please wait while we fetch some questions for you..." />
              ) : (
                Object.entries(questionaireResponse).map(
                  ([questionId, questionData], index) => (
                    <div key={index} style={{ marginBottom: "15px" }}>
                      {renderQuestionControl(
                        index + 1,
                        questionId,
                        questionData,
                        answers,
                        handleAnswerChange
                      )}
                    </div>
                  )
                )
              )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-start" }}>
              <Button onClick={handleCancel} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (isFormFilled()) {
                    handleSubmit();
                  } else {
                    alert("Please fill out all the details");
                  }
                }}
                color="primary"
                disabled={!isFormFilled()}
                sx={{
                  color: "white",
                  backgroundColor: isFormFilled() ? "#1976D2" : undefined,
                  "&:hover": {
                    backgroundColor: isFormFilled() ? "#125699" : undefined,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : (
                  "Submit Application"
                )}
              </Button>
            </DialogActions>
          </>
        )}
        {message && (
          <DialogContent>
            <Typography variant="h5" paragraph>
              {message}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                onClick={() => navigate("/task/gigHistory")}
                sx={{ textTransform: "none" }}
              >
                Click Here
              </Button>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ display: "flex", margin: "auto" }}
              >
                to go back to back to gig dashboard.
              </Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </>
  );
};

export default GigInviteForm;
