import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Button, Container } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CustomButton from "../ButtonWrapper";
import { useNavigate } from "react-router-dom";

const JoinCommunity: FC = () => {
  const history = useNavigate();

  const primaryTextStyle = {
    display: "block",
    fontSize: "32px",
    marginRight: "30px",
  };

  const secondaryTextStyle = {
    marginTop: "20px",
  };

  const thirdTextStyle = {
    fontWeight: "normal",
    display: "block",
    fontSize: "14px",
    marginTop: "8px",
  };
  const redirectToBrowseJobs = () => {
    history("/browse-jobs");
  };

  const navigateToEmployerRegistration = () => {
    history("/employer-registration");
  };

  return (
    <Container
      sx={{
        backgroundColor: "background.default",
        pt: 4,
        pb: { xs: 8, md: 10 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        gap: { xs: 2, md: 0 },
      }}
    >
      <Card sx={{ width: { xs: "100%", md: "50%", marginRight: "10px" } }}>
        <CardContent>
          <Typography sx={primaryTextStyle}>Become a Candidate</Typography>
          <Typography sx={thirdTextStyle}>
            Join gOGig for flexible work opportunities, skill development, and a
            supportive community. Shape your career on your terms with us today!
          </Typography>
          <CustomButton
            tag="ApplyForJob"
            variant="contained"
            sx={secondaryTextStyle}
            onClick={() => redirectToBrowseJobs()}
          >
            APPLY FOR JOB
            <EastIcon />
          </CustomButton>
        </CardContent>
      </Card>
      <Card
        sx={{ width: { xs: "100%", md: "50%" }, backgroundColor: "lightblue" }}
      >
        <CardContent>
          <Typography sx={primaryTextStyle}>Become an Employer</Typography>
          <Typography sx={thirdTextStyle}>
            Join gOGig for access to a diverse pool of skilled gig workers,
            ensuring quick access to on-demand talent for your business needs.
          </Typography>
          <Button
            variant="contained"
            sx={secondaryTextStyle}
            onClick={() => navigateToEmployerRegistration()}
          >
            GET STARTED
            <EastIcon />
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default JoinCommunity;
