import React from "react";
import AllGigHistory from "./AllGigHistory";
import { GigHistoryDetails } from "./GigHistoryValues";

interface GigHistoryListingProps {
  gigs: GigHistoryDetails[];
  isLoading: boolean;
}

const GigHistoryListing = ({
  gigs,
  isLoading,
}: GigHistoryListingProps) => {

  return (
    <AllGigHistory allGigs={gigs} isLoading={isLoading} />
  );
};

export default GigHistoryListing;
