
export interface FormValues {
    companyName:string;
    location: string;
    industry: string;
    description:string;
    website:string | null
    contactEmail: string;
    contactNumber: number | null;
    size: string;
    socialMedia: string | null;
    }
    
    const initialFormValues: FormValues = {
     companyName: '',
     location:'',
     industry:'',
     description:'',
     website:'',
     contactEmail: '',
     contactNumber: null,
     size: '',
     socialMedia: '',
    };
    
    export default initialFormValues;