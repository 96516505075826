import { Typography } from "@mui/material";

interface PageTitleComponentProps {
  title: string,
  subTitle?: string
}
const PageTitleComponent: React.FC<PageTitleComponentProps> = ({title, subTitle}) => {
  return (
    <> 
      <Typography
        variant="h1"
        sx={{
          fontSize: "28px",
          fontWeight: "bold",
          fontFamily: "inter, sans-serif",
          marginTop: 2,
          marginBottom: 1,
        }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "normal",
            fontFamily: "inter, sans-serif",
            marginBottom: 2,
          }}
        >
          {subTitle}
        </Typography>
      )}
    </>
  );
};

export default PageTitleComponent;
