import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getFirebaseToken } from "../libs/firebaseData/firebase";
import { onMessage } from "firebase/messaging";
import { messaging } from "../libs/firebaseData/firebase";
import Message from "../components/notifications/Message";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "./AuthProvider";
import { toast } from "react-toastify";
import { getSavedNotifications } from "../clients/getSavedNotifications";
import { Notification } from "../components/notifications/Notifications";
type NotificationContextType = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: number;
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>;
  alertNotification: boolean;
  setAlertNotification: React.Dispatch<React.SetStateAction<boolean>>;
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
};

const NotificationContext = createContext<NotificationContextType | null>(null);

export const useNotificationContext = () => {
  const value = useContext(NotificationContext);

  if (!value) {
    throw new Error(
      "useNotificationContext must be used within a NotificationProvider"
    );
  }
  return value;
};

export interface NotificationProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const NotificationProvider = (props: NotificationProviderProps) => {
  const { isAuthenticated, authHeader } = useAuthContext();
  const [alertNotification, setAlertNotification] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  useEffect(() => {
    if (authHeader) {
      getFirebaseToken(authHeader);
      onMessage(messaging, (payload) => {
        toast(<Message notification={payload.data} />, {
          style: {
            backgroundColor: "orange",
            color: "#ffffff",
            padding: "16px",
            borderRadius: "8px",
          },
        });
        setAlertNotification(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader, isAuthenticated]);

  useEffect(() => {
    const getNotifications = async () => {
      if (authHeader) {
        try {
          const res: any = await getSavedNotifications(authHeader);
          setNotifications(res.data);
          setUnreadCount(
            notifications.filter(
              (notification: { isRead: number }) => !notification.isRead
            ).length
          );
          setIsLoading(false);
          setAlertNotification(false);
        } catch (error) {
          setNotifications([]);
          console.error(
            "An error occurred while fetching notifications:",
            error
          );
        }
      }
    };
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader, alertNotification]);

  const value = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
      alertNotification,
      unreadCount,
      setUnreadCount,
      setAlertNotification,
      notifications,
      setNotifications,
    };
  }, [
    isLoading,
    setIsLoading,
    alertNotification,
    unreadCount,
    setUnreadCount,
    setAlertNotification,
    notifications,
    setNotifications,
  ]);

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
    </NotificationContext.Provider>
  );
};
