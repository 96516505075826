import React from "react";
import OurVision from "../home/ourVision";
import WorkingProcess from "../home/workingProcess";
import FeaturedCategories from "../home/featuredCategories";
import JoinCommunity from "../home/joinCommunity";
import { HomeHero } from "../home";
import { LocationMapper } from "../Location";
import ProfileDetailsCheckComponent from "../homeV2/ProfileDetailsCheckComponent";

function DefaultLandingPage() {
  LocationMapper();
  return (
    <>
      <ProfileDetailsCheckComponent />
      <HomeHero />
      <OurVision />
      <WorkingProcess />
      <FeaturedCategories />
      <JoinCommunity />
    </>
  );
}

export default DefaultLandingPage;
