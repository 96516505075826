import React from "react";
import { useAuthContext } from "../../providers/AuthProvider";
import { IconButton, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const WhatsappSupportIcon = () => {

  const { userDetails } = useAuthContext();
  const handleClick = () => {
    window.open(
      `https://wa.me/+919108429315?text=Hi, I am ${
        userDetails?.firstName ? userDetails?.firstName : "User"
      }. I need help with an issue.`
    );
  };
  
  return (
    <IconButton disableRipple color="inherit" onClick={handleClick}>
      <WhatsAppIcon style={{ color: "green", marginLeft: "3px" }} />{" "}
      <Typography>Support</Typography>
    </IconButton>
  );
};
