import React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Typography } from "@mui/material";

interface HandleBooleanQuestionProps {
  question: string;
  questionId: string;
  answers: Record<string, string>; // Assuming answers is an object with string values
  handleAnswerChange: (questionId: string, value: string) => void;
}

const HandleBooleanQuestion: React.FC<HandleBooleanQuestionProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
}) => {
  return (
    <FormControl component="fieldset">
          <Typography sx={{fontWeight:'bold'}}>
            {question}</Typography>
      <RadioGroup
        name={`question_${questionId}`}
        value={answers[questionId] || ""}
        onChange={(e) => handleAnswerChange(questionId, e.target.value)}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

export default HandleBooleanQuestion;
