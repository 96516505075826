import { PaletteOptions } from '@mui/material'

// Green and Orance
// const paletteBase: Partial<PaletteOptions> = {
//   primary: {
//     light: '#9FF1D2',
//     main: '#127C71',
//     dark: '#0D6A69',
//     contrastText: '#fbfbfb',
//   },
//   secondary: {
//     light: '#FDE6A8',
//     main: '#ffaf35',
//     dark: '#D0821C',
//     contrastText: '#fbfbfb',
//   },
// }


// Orange and Black
// const paletteBase: Partial<PaletteOptions> = {
//   primary: {
//     light: '#9FF1D2',
//     main: '#2C343C',
//     dark: '#0D6A69',
//     contrastText: '#fbfbfb',
//   },
//   secondary: {
//     light: '#FDE6A8',
//     main: '#F98920',
//     dark: '#D0821C',
//     contrastText: '#fbfbfb',
//   },
// }

// Red and Black
// const paletteBase: Partial<PaletteOptions> = {
//     primary: {
//       light: '#9FF1D2',
//       main: '#2C343C',
//       dark: '#0D6A69',
//       contrastText: '#fbfbfb',
//     },
//     secondary: {
//       light: '#FDE6A8',
//       main: '#E44A1F',
//       dark: '#D0821C',
//       contrastText: '#fbfbfb',
//     },
//   }



const paletteBase: Partial<PaletteOptions> = {}

export default paletteBase
