import { Container, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";

const TermsAndCondition: React.FC = () => {
  return (
    <Container
    >
      <Typography variant="h1" my={3}>
        Terms and Conditions
      </Typography>

      <Grid item xs={6}>
        <Typography>
          These terms{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            (Terms)
          </span>{" "}
          form a binding legal agreement and govern your relationship with{" "}
          <span
            style={{
              color: "orange",
              fontWeight: "bold",
            }}
          >
            gOGig
          </span>{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            (Company / We / Us)
          </span>
          . The Company runs an online platform that enables members like you to
          provide services to members who are searching for or availing services
          on the platform{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            (Platform)
          </span>
          .Members who provide services on the Platform are called{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Taskers
          </span>{" "}
          and members who seek services on the Platform are called{" "}
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Clients
          </span>
          .
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography
          style={{
            textAlign: "justify",
          }}
        >
          You must register on the Platform and agree to the Terms to access and
          use the Platform. You must keep your information accurate. As the
          owner of the Platform, we do not own, control, offer or manage any
          services on the Platform. The Platform offers a host of services to
          the Client. Upon completing your registration, you will be eligible to
          provide services to the Client subject to meeting the specific
          requirements of the service and submitting additional details and
          documents. Where deemed suitable, you can provide services to the
          Clients. For this purpose, you will need to execute supplemental
          terms, which will include the details of service, duration of service,
          fees payable and additional conditions, as may be applicable. It is
          hereby clarified that all fees paid to you would be subject to
          services rendered by you to the Clients and would be more particularly
          described in the supplemental terms executed by you.
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography
          style={{
            textAlign: "justify",
          }}
        >
          If you do not agree to these Terms, you may not use the Platform.
          These Terms are the complete and exclusive statement of agreement
          between you and the Company and supersedes all prior or
          contemporaneous representations, communications and contractual
          agreements relating to the subject matter of these Terms, whether
          written or oral. Supplemental terms may apply in certain cases where
          you agree to provide services to Clients. Such supplemental terms are
          in addition to and shall be deemed to be a part of the Terms for the
          purposes of services provided by you on the Platform. Any information
          in relation to the supplemental terms or any document to be executed
          to enforce such supplemental terms shall be informed to you as and
          when applicable. In case of any conflict, these Terms shall supersede
          any other supplemental terms or agreements between you and Us.
        </Typography>
      </Grid>

      <br />
      <Grid item xs={6}>
        <Typography variant="h3" textAlign={"center"}>
          PLEASE READ THE FOLLOWING TERMS CAREFULLY BEFORE REGISTERING ON THE
          PLATFORM:
        </Typography>
        <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              No employment relationship:
            </span>{" "}
            You agree that you will be an independent contractor and not an
            employee of the Company or any Client who engages you via the
            Platform. You shall not represent directly or indirectly that you
            are an employee, or legal representative of Company. You shall not
            have the authority to incur any liabilities or obligations of any
            kind, in the name of, or on behalf of the Company. You acknowledge
            and agree that you are not subject to the direct control or
            supervision of the Company in relation to the services to be
            provided by you on the Platform. You do not have the authority to
            create, modify or terminate a contractual relationship(s) between
            the Company and any third party or act for or bind the Company in
            any respect. You recognize and agree that no joint venture or
            partnership agreement is intended or created hereby. The obligations
            performed under these Terms by you and the Company shall be on a
            principal-to-principal basis.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              No Employee Benefits:
            </span>{" "}
            You acknowledge and agree that neither you nor anyone acting on your
            behalf shall be entitled to receive any vacation pay, sick leave,
            social security benefits, or any other employee benefits of any kind
            from the Company or any Client who engages you via the Platform. You
            agree, as an independent contractor, that you would not be entitled
            to any termination benefits/compensation payable under Indian laws
            in the event that you leave the Platform or your relationship with
            the Company or any Client who engages you via the Platform ends.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Non-Exclusivity:
            </span>{" "}
            You agree that you have entered into these Terms on a non-exclusive
            basis, and that both Company and you have the ability to enter into
            agreements for providing consultancy or services to third parties.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Confidentiality:
            </span>{" "}
            You hereby acknowledge that the Company or any Client who engages
            you via the Platform, has and that you may come to possess or know
            certain information belonging to Company or any Client who engages
            you via the Platform, and/or its affiliates or customers, including
            but not limited to technical data, specifications, designs,
            software, product plans, research and development, personal
            information, financial information, business methods and operations,
            and marketing programs, and involving inventions (whether or not
            patentable), trade secrets, know-how, techniques, and combinations
            of known information all of which are proprietary information
            exclusive to the Company or any Client who engages you via the
            Platform, as may be applicable and of a character regarded by
            Company or any Client who engages you via the Platform as
            confidential, and which, except as the Company or any Client who
            engages you via the Platform may otherwise agree in writing, shall
            be deemed by Company or any Client who engages you via the Platform,
            to be valuable confidential information (collectively, "Proprietary
            Information"). You acknowledge that all Proprietary Information is
            material and confidential and greatly affects the goodwill and the
            effective and 4.successful conduct of the Company or any Client who
            engages you via the Platform and its operations, and that
            maintaining confidentiality of the Proprietary Information is
            necessary to protect the legitimate interests of the Company or any
            Client who engages you via the Platform, as may be applicable.
            Accordingly, where you receive such Proprietary Information, you
            agree to handle it in strict confidence and not to copy, reprint,
            duplicate, or recreate in whole or in part, alone or in combination
            with anything else, the Proprietary Information. You further agree
            to not directly or indirectly, divulge, reveal or communicate any
            Proprietary Information to any person, company, firm, corporation or
            entity whatsoever, or use, pursue or exploit any Proprietary
            Information for your own benefit or for the benefit of others so
            long as it remains proprietary information of the Company or any
            Client who engages you via the Platform. Nothing contained in these
            Terms shall be construed as expressly or impliedly granting a
            license or right to use the Proprietary Information by you. You
            agree that, upon the demand of the Company or any Client who engages
            you via the Platform, you shall immediately return to Company or any
            Client who engages you via the Platform, as may be applicable, all
            tangible material pertaining to Proprietary Information in your
            possession or control. Further, the mere omission to designate a
            particular item of information as Proprietary Information shall not
            in itself suffice to waive your confidentiality obligations in
            respect of such information. You further agree and understand that
            the obligation to maintain confidentiality with regard to the
            Proprietary Information of the Company shall survive after
            termination of these Terms and up to a period of three (3) years
            after the date of termination of your arrangement with the Company.
            You agree that damages would not be the only adequate remedy for any
            disclosure of Proprietary Information not permitted by these Terms
            and agree that the Company shall be entitled to equitable remedies
            including injunction and specific performance in relation to any
            actual or threatened breach of this clause.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Intellectual Property:
            </span>{" "}
            Any and all inventions, discoveries, improvements, modifications,
            developments, concepts, designs, ideas, know how, trade secrets
            and/or original works of authorship, innovations, enhancements,
            including but not limited to computer software, and intellectual
            property of any kind{" "}
            <span style={{ fontWeight: "bold" }}>(Inventions)</span>conceived or
            developed by you pursuant to these Terms shall be deemed to have
            been created under these Terms{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              (Company Inventions)
            </span>
            .You hereby agree and acknowledge to assign all rights, title, and
            interest in the Company Inventions to the Company. Any such
            assignment by you shall be worldwide, royalty-free, absolute,
            irrevocable and perpetual. You shall execute all such documents as
            may be required to ensure that all rights in such Company Inventions
            are fully assigned to the Company. You further represent and warrant
            that you shall not use for the purpose of providing the services, or
            license to the Company, any inventions, discoveries, developments,
            innovations and intellectual property of any kind that belong to a
            third party, including but not limited to any of your previous
            employers or clients.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Payment:
            </span>{" "}
            Subject to you rendering services on the Platform, you shall be paid
            the fees as agreed in the supplemental terms executed in respect of
            the services to be rendered by you on the Platform. This amount
            shall be paid after applicable tax deductions, as full and final
            compensation for the services, subject to you rendering the invoice
            for such payment on the Platform. The Company will not accept any
            invoice raised by you on any other mode except{" "}
            <span
              style={{
                fontStyle: "italic",
              }}
            >
              via{" "}
            </span>
            the Platform.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Representations:
            </span>{" "}
            You hereby represent and warrant to the Company that:
            <br /> <span style={{ fontWeight: "bold" }}>(i)</span> you have the
            authority and legal capacity to enter into these Terms;
            <br /> <span style={{ fontWeight: "bold" }}>(ii)</span> you are not
            under any disability, restriction or prohibition, which prevents you
            from performing or adhering to any of your duties or obligations
            under these Terms; <br />
            <span style={{ fontWeight: "bold" }}>(iii)</span> you will at your
            own cost hold all necessary licenses, consents, permits and
            approvals required in connection with the your provision of services
            under these Terms;
            <br />
            <span style={{ fontWeight: "bold" }}>(iv)</span> you will arrange to
            undertake all necessary steps to be compliant under all the
            applicable laws, including taxation laws. You represent that
            currently you do not have any obligations in respect of Goods and
            Services Tax <span style={{ fontWeight: "bold" }}>(GST)</span>.You
            further agree to issue a prior notice to the Company informing them
            where the provisions of GST laws apply to you on account of meeting
            the applicability criteria. Failure to intimate the Company can
            result in immediate termination of your arrangement with the
            Company. Under no circumstances, does the Company take any
            responsibility whatsoever of your obligations under any applicable
            taxation laws.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Duties:{" "}
            </span>
            You agree to perform the services with due diligence and in a safe,
            competent, professional and workman like manner. In your performance
            of services, you agree to use your knowledge, skill and ingenuity
            and use the best efforts to promote the interest of the Company and
            the Client. You agree to comply with the additional requirements,
            where applicable as regards provision of your service. Any such
            details will be mentioned in supplemental terms of service and would
            be binding on you once you agree to provide such service to the
            Client.
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Data Protection:
            </span>{" "}
            You agree to specifically comply with the obligations laid down
            under the Information Technology Act, 2000 and the Information
            Technology (Reasonable Security Practices and Procedures and
            Sensitive Personal Data or Information) Rules, 2011 (Data Protection
            Rules) and any other law for data protection in India, where
            applicable.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Equipment and Devices:
            </span>{" "}
            You agree to use your own equipment and devices for the purpose of
            performing services on the Platform. The Company does not take any
            responsibility to provide you with any resources for the purpose of
            these Terms.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Termination:
            </span>{" "}
            The Company or you may terminate these Terms upon giving at least
            fifteen (15) business days' advance notice to the other party. The
            Company and you may mutually agree to waive off in writing the
            notice requirement in some specific circumstances. We may also
            terminate these Terms immediately and without notice and stop
            providing you the access to the Platform if you breach these Terms,
            you violate applicable laws, or We reasonably believe termination is
            necessary to protect the Platform or its members.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Indemnification:
            </span>{" "}
            You agree to keep the Company and its authorized representatives
            indemnified and harmless against any claims or demands that may be
            made by a third party for any loss or damage to such third party
            arising out of or in any way connected with: <br />
            <span style={{ fontWeight: "bold" }}>(i)</span> your breach of these
            Terms (including any supplemental or additional terms that apply to
            your services on the Platform),
            <br/> <span style={{ fontWeight: "bold" }}>(ii)</span> your
            improper use of the Platform,
            <br />
            <span style={{ fontWeight: "bold" }}>(iii)</span> your provision of
            services to any Client on the Platform. You shall be solely
            responsible for payment of all taxes and compliance of all labour
            and employment requirements under applicable laws and shall keep the
            Company and its representatives indemnified and held harmless
            against any losses, damages, costs and expenses that may be incurred
            or suffered by the Company, as a result of your failure to make such
            tax payments or on account of any breach of contract, warranty, tort
            (including negligence) or otherwise of any of your obligations
            contained herein.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Amendment:
            </span>{" "}
            The Company may modify these Terms at any time. When We make changes
            to these Terms, we will post the revised terms on the Platform. The
            Company will also provide you with notice of any material changes by
            email at least 30 days before the date they become effective. If you
            disagree with the revised terms, you may terminate this agreement
            with Us immediately as provided in these Terms. If you do not
            terminate your arrangement with Us under these Terms before the date
            the revised terms become effective, your continued access to or use
            of the Platform will constitute acceptance of the revised terms.
            Each amendment to these Terms shall supersede the provisions as
            contained herein in these Terms to the extent that they are
            inconsistent with any such amendment.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Severability:
            </span>{" "}
            Should any part of these Terms be declared illegal or unenforceable
            by a final adjudication of any tribunal or court of competent
            jurisdiction, such adjudication shall not alter the validity or
            enforceability of any other term or provision of these Terms unless
            the terms and provisions so declared illegal are expressly defined
            as a conditions precedent or as of the essence of these Terms, or
            comprising an integral part of, or inseparable from the remainder of
            these Terms.
          </ListItem>
          <ListItem
            sx={{ display: "list-item" }}
            style={{
              textAlign: "justify",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Governing Law:
            </span>{" "}
            These Terms shall be governed by and construed in accordance with
            the laws of India. You and the Company agree that all proceedings
            brought to enforce the rights under these Terms shall be brought
            before and heard by the courts in Bangalore, India.
          </ListItem>
        </List>
      </Grid>
    </Container>
  );
};
export default TermsAndCondition;
