import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import PaidIcon from "@mui/icons-material/Paid";

const UserEntry = () => {
  const history = useNavigate();
  const handleNext = () => {
    history("/user-entry/user-entry-form/work-experience");
  };
  return (
    <Box sx={{ pt: { xs: 1, md: 14 } }}>
      <Container maxWidth="lg">
        <Typography variant="h2" sx={{ fontFamily: "inter,sans-serif" }}>
          Hello User, are you prepared to embark on your gOGig journey and
          achieve success?
        </Typography>
        <List
          sx={{
            width: "100%",
            maxWidth: 500,
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Answer next few questions and build your profile" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Apply for open Gig" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PaidIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Get paid safely" />
          </ListItem>
        </List>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column" },
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={handleNext}
            sx={{ borderRadius: "20px", marginRight: "25px" }}
          >
            Get Started
          </Button>
          <Typography>
            It only takes a few minutes and you can edit it later. We'll save as
            you go.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default UserEntry;
