import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Logo } from "../logo";

const SlimHeader = () => {
  return (
    <Box sx={{ backgroundColor: "#F5F7FA" }}>
      <Container sx={{ py: { xs: 1, md: 1 } }}>
        <Logo />
      </Container>
    </Box>
  );
};

export default SlimHeader;
