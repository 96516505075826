import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getGigDetailsByGigId } from "@gogig-in/gogig-clients";
import {
  Container,
  Typography,
  Paper,
  Divider,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { publishProject } from "@gogig-in/gogig-clients";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessages, logCustomError } from "../ErrorHandling";
import { useAuthContext } from "../../providers/AuthProvider";

const GigDetailsEmp: React.FC = () => {
  const { gigId } = useParams<{ gigId?: string }>();
  const [gigDetails, setGigDetails] = useState<any>(null);
  const [showDraftMessage, setShowDraftMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGigId, setSelectedGigId] = useState<string | null>(null);
  const { authHeader } = useAuthContext();

  useEffect(() => {
    const fetchGigDetails = async () => {
      try {
        if (!gigId) {
          logCustomError({
            code: "GigIdNotDefined",
            message: ErrorMessages.GigIdNotDefined,
            page: "Gig details Employer",
            gigId: gigId,
          });
          return;
        }

        const response: any = await getGigDetailsByGigId(gigId);
        if (response && response.data && response.data.length > 0) {
          setGigDetails(response.data[0]);
        } else {
          logCustomError({
            code: "NoDataFound",
            message: ErrorMessages.NoDataFound,
            page: "Gig details Employer",
            gigId: gigId,
          });
        }
      } catch (error) {
        logCustomError({
          code: "FetchingGigDetailsError",
          message: ErrorMessages.FetchingGigDetailsError,
          page: "Gig details Employer",
          gigId: gigId,
        });
      }
    };
    fetchGigDetails();
  }, [gigId]);

  const openModal = (gigId: string) => {
    setSelectedGigId(gigId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handlePublish = async () => {
    if (selectedGigId && authHeader) {
      try {
        await publishProject(selectedGigId, authHeader);
        closeModal();
        window.location.reload();
      } catch (error) {
        console.error( "Error during publishing project",error);
        closeModal();
      }
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Container sx={{ marginBottom: "40px" }}>
      <div style={{ margin: "30px 10px 0 10px" }}>
        {gigDetails && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h2">
                    {gigDetails.gigTitle}
                    <span
                      style={{
                        marginLeft: "7px",
                        position: "relative",
                        display: "inline-block",
                        cursor: "pointer",
                        marginBottom: "3px",
                      }}
                      onMouseEnter={() => setShowDraftMessage(true)}
                      onMouseLeave={() => setShowDraftMessage(false)}
                    >
                      <Typography sx={{ fontSize: "14px" }}>
                        ({gigDetails.status})
                      </Typography>
                      {showDraftMessage && (
                        <span
                          className="hover-message"
                          style={{
                            position: "absolute",
                            left: "250%",
                            bottom: "100%",
                            transform: "translateX(-50%)",
                            whiteSpace: "nowrap",
                            zIndex: 1,
                            color: "white",
                            backgroundColor: "#212121",
                            width: "fit-content",
                            padding: "2px 6px 2px 6px",
                            borderRadius: "15px",
                          }}
                        >
                          Your gig is in a {gigDetails.status} state now.
                        </span>
                      )}
                    </span>
                  </Typography>
                </Grid>
                {gigDetails.status !== "draft" && (
                  <Grid item>
                    <Typography
                      style={{ textAlign: "center", marginTop: "25px" }}
                    >
                      <strong>
                        Number of Applicants: {gigDetails.numberOfApplicants}
                      </strong>
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    variant="outlined"
                    component={Link}
                    disabled={gigDetails.status !== "draft"}
                    to={`/editGig/${gigDetails.gigId}`}
                    sx={{
                      borderRadius: "22px",
                      marginBottom: "-25px",
                      marginRight: "15px",
                    }}
                  >
                    Edit Gig
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => openModal(gigDetails.gigId)}
                    style={{
                      color: "white",
                      backgroundColor: "secondary",
                      borderRadius: "22px",
                      marginBottom: "-25px",
                      marginRight: "15px",
                    }}
                    disabled={gigDetails.status !== "draft"}
                  >
                    Publish Gig
                  </Button>
                  <Link to={`/employerGigList?status=${gigDetails.status}`}>
                    <Button
                      variant="outlined"
                      endIcon={<CloseIcon />}
                      sx={{
                        borderRadius: "22px",
                        marginBottom: "-25px",
                        marginRight: "15px",
                      }}
                    >
                      Close
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </div>
            <Typography variant="caption">
              Created on {formatDate(gigDetails.createdAt)}
            </Typography>
          </div>
        )}
      </div>
      <Divider
        style={{
          margin: "2px 10px 10px 10px",
          backgroundColor: "#000",
          height: "3px",
        }}
      />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={7}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Paper
            elevation={3}
            style={{
              flex: 1,
              marginBottom: "20px",
              padding: "20px 20px 0 20px",
              backgroundColor: "white",
              borderRadius: "15px",
              border: "0.5px solid grey",
            }}
          >
            {gigDetails && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-10px",
                  }}
                >
                  <LocationOnOutlinedIcon sx={{ fontSize: "medium" }} />
                  <Typography>{gigDetails.site}</Typography>
                </Box>
                <Typography sx={{ marginBottom: "5px" }}>
                  <strong>Category: </strong> {gigDetails.category}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Languages: </strong>
                      {gigDetails.communicationLanguages}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Location:</strong> {gigDetails.location}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    margin: "5px -20px 20px -20px",
                    backgroundColor: "black",
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Budget:</strong> {gigDetails.budget}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Skills Required: </strong>
                      {gigDetails.skillsRequired}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Qualifications Required: </strong>
                      {gigDetails.academicQualificationRequired}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Age Restriction: </strong>
                      {gigDetails.ageRestriction}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Gig Start Date: </strong>
                      {formatDate(gigDetails.gigStartDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Gig End Date: </strong>
                      {formatDate(gigDetails.gigEndDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Price per client: </strong>
                      {gigDetails.pricePerClient}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Minimum calls per day: </strong>
                      {gigDetails.minCallsPerDay}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Minimum wage per day: </strong>
                      {gigDetails.minWagePerDay}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Gig Start Time: </strong>
                      {gigDetails.workStartTime}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <strong>Gig End Time: </strong>
                      {gigDetails.workEndTime}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  style={{
                    margin: "5px -20px 20px -20px",
                    backgroundColor: "black",
                  }}
                />
                <Typography gutterBottom>
                  <strong>Description:</strong>
                  <br />{" "}
                  {gigDetails.description
                    .split("\n")
                    .map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
                <Typography>{gigDetails.site}</Typography>
                <div
                  style={{
                    margin: "15px 0 5px 0",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <Typography variant="caption">
                    Last updated on {formatDate(gigDetails.updatedAt)}
                  </Typography>
                </div>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Paper
            elevation={3}
            style={{
              flex: 1,
              marginBottom: "20px",
              padding: "20px 20px 0 20px",
              backgroundColor: "white",
              borderRadius: "15px",
              border: "0.5px solid grey",
            }}
          >
            {gigDetails && gigDetails.questionaire && (
              <div>
                <Typography>Questions for job</Typography>
                <Typography>
                  <ul>
                    {Object.entries(
                      JSON.parse(gigDetails?.questionaire || "")
                    ).map(([key, value], index) => (
                      <li key={index}>
                        <strong>Question {index + 1}:</strong>
                        <ul>
                          <li>
                            <strong>Type:</strong> {(value as any).type}
                          </li>
                          <li>
                            <strong>Options:</strong>{" "}
                            {Array.isArray((value as any).answer)
                              ? (value as any).answer.join(", ")
                              : (value as any).answer}
                          </li>
                          <li>
                            <strong>Question:</strong> {(value as any).question}
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </Typography>
              </div>
            )}
            {gigDetails && gigDetails.preAcceptanceForm && (
              <div>
                <Typography>Questions for seeker</Typography>
                <Typography>
                  <ul>
                    {Object.entries(
                      JSON.parse(gigDetails?.preAcceptanceForm || "")
                    ).map(([key, value], index) => (
                      <li key={index}>
                        <strong>Question {index + 1}:</strong>
                        <ul>
                          <li>
                            <strong>Type:</strong> {(value as any).type}
                          </li>
                          <li>
                            <strong>Options:</strong>{" "}
                            {Array.isArray((value as any).answer)
                              ? (value as any).answer.join(", ")
                              : (value as any).answer}
                          </li>
                          <li>
                            <strong>Question:</strong> {(value as any).question}
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </Typography>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirm Publish</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this gig?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePublish} color="primary">
            Confirm Publish
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GigDetailsEmp;
