import { Outlet } from "react-router-dom";
import SlimHeader from "./components/header/SlimHeader";
import { Box } from "@mui/material";

function SlimContainer() {
  const headerSize = "16px";
  return (
    <Box className={"layout-container"}>
      <SlimHeader />
      <Box style={{ marginTop: headerSize }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default SlimContainer;
