import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Button, MobileStepper, Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { ProfileCreationStep } from "./types";

interface ProfileCreationStepperProps {
    profileCreationSteps: ProfileCreationStep[];
    currentStep: ProfileCreationStep;
    handleNext: () => void;
    handleBack: () => void;
}

export const ProfileCreationStepper = ({profileCreationSteps, currentStep, handleNext, handleBack}: ProfileCreationStepperProps) => {
    const isSmallScreen = useMediaQuery("(max-width:600px)");
    

    return isSmallScreen ? <MobileStepper
        variant="text"
        steps={profileCreationSteps.length}
        position="static"
        activeStep={profileCreationSteps.indexOf(currentStep)}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={profileCreationSteps.indexOf(currentStep) === profileCreationSteps.length}>
            {currentStep.nextStepLabel}
              <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={profileCreationSteps.indexOf(currentStep) === 0}>
              <KeyboardArrowLeft />
            Back
          </Button>
        }
      /> :  
      <><Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, }}>
      <Button
        variant="contained"
        color="inherit"
        disabled={profileCreationSteps.indexOf(currentStep) === 0}
        onClick={handleBack}
        sx={{ mr: 1, textTransform: 'capitalize' }}
      >
        Back
      </Button>
      <Box sx={{ flex: '1 1 auto' }} />
      <Button onClick={handleNext}
        variant="contained" sx={{ mr: 1, textTransform: 'capitalize' }}>
        {currentStep.nextStepLabel}
      </Button>
    </Box>
      <Stepper nonLinear activeStep={profileCreationSteps.indexOf(currentStep)} sx={{ marginBottom: '25px', 
      marginTop: '25px' }}>
        {profileCreationSteps.map((step, index) => (
          <Step key={step.stepName} completed={index < profileCreationSteps.indexOf(currentStep)}>
            <StepLabel color="inherit" >
              {step.stepName}
            </StepLabel>
          </Step>
        ))}
      </Stepper></>
}