import React, { FC, useState } from "react";
import { Grid, TextField, Button, Container, Typography } from "@mui/material";
import FAQCategory from "../components/header/FAQCategory";
import { postQuery } from "@gogig-in/gogig-clients";
import PageTitleComponent from "../components/PageTitleComponent";

const FAQPageComponent: FC = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    contactDetails: "",
    query: "",
  });
  const [error, setError] = useState({
    fullName: false,
    contactDetails: false,
    query: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError({
      ...error,
      [name]: value.trim().length === 0,
    });
  };

  const handleSubmit = async () => {
    const newError = {
      fullName: formData.fullName.trim().length === 0,
      contactDetails: formData.contactDetails.trim().length === 0,
      query: formData.query.trim().length === 0,
    };
    setError(newError);

    if (Object.values(newError).some((err) => err)) {
      return;
    }
    try {
      await postQuery(formData);
      alert(`Your query has been submitted`);
      // Clear the form after submission
      setFormData({ fullName: "", contactDetails: "", query: "" });
    } catch (error) {
      console.error("Error during post query",error);
    }
  };

  return (
    <Container id="hero" sx={{ position: "relative" }}>
      <Grid
        container
        spacing={2}
        marginBottom={4}
        mt={2}
        direction={{ xs: "column", md: "row" }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            ml: { xs: 1, md: "16px" },
            // backgroundColor: "rgb(243, 246, 249)",
          }}
        >
          <PageTitleComponent
            title="Help Center"
            subTitle="We have compiled a list of frequently asked questions to provide you with quick and helpful solutions. Explore the topics below to find answers to common queries about our products, services, and policies."
          />
          <FAQCategory />
        </Grid>

        <Grid item xs={12} md={4} sx={{ ml: { xs: 1, md: "24px" } }}>
          <PageTitleComponent
            title="Connect with us"
            subTitle="If you cannot find the information you're looking for, please feel free to reach out to our customer support team for further assistance."
          />
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            Please provide your name
          </Typography>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Enter your full name here"
            multiline
            fullWidth
            rows={1}
            variant="outlined"
            name="fullName"
            error={error.fullName}
            helperText={error.fullName?"Full name is required":""}
            value={formData.fullName}
            onChange={handleInputChange}
            autoComplete="name"
          />
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            Please provide your contact Details
          </Typography>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Enter your contact details here"
            multiline
            fullWidth
            rows={1}
            variant="outlined"
            name="contactDetails"
            error={error.contactDetails}
            helperText={error.contactDetails?"Phone number/email is required":""}
            value={formData.contactDetails}
            onChange={handleInputChange}
            autoComplete="tel"
          />
          <Typography variant="h6" sx={{ marginTop: 1 }}>
            Write your query below and our team will reach out to you within 24
            hrs for your requirements
          </Typography>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Enter your query here"
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            name="query"
            error={error.query}
            helperText={error.query?"query is required":""}
            value={formData.query}
            onChange={handleInputChange}
            autoComplete="off"
          />
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            sx={{ marginTop: "15px" }}
          >
            Submit Query
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQPageComponent;
