import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  Chip,
  Link,
  Divider,
  useMediaQuery,
  Snackbar,
  Card,
  Slide,
  IconButton,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import TrainingIcon from "@mui/icons-material/ModelTraining";
import Strings from "../Locale/strings.json";
import {
  CalendarTodayOutlined as CalendarIcon,
  TimerOutlined as TimerIcon,
  WorkOutlineOutlined as WorkIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Map from "@mui/icons-material/MapOutlined";
import Wallet from "@mui/icons-material/AccountBalanceWalletOutlined";
import FilterIcon from "@mui/icons-material/FilterNoneOutlined";
import SuccessApplication from "../components/endUserLandingPage/SuccessApplication";
import CustomButton from "../components/ButtonWrapper";
import {
  Capability,
  saveBookmarkDetails,
  removeBookmarkDetails,
  getAcceptedGigsByUser,
  getGigCapabilitiesByGigId,
  getBookmarkDetails,
  getGigDetailsByGigId,
} from "@gogig-in/gogig-clients";
import LoadingSpinner from "../components/LoadingAnimation";
import GigForm from "../components/GigForm/GigForm";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import { useAuthContext } from "../providers/AuthProvider";
import MDEditor from "@uiw/react-md-editor";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarIcon from "@mui/icons-material/Star";
import { gigRequirementsText } from "../utils/gigRequirementsText";

interface BookmarkData {
  bookmarkId: string;
  gigId: string;
}

const GigDetails = () => {
  const { gigId, visibilityId } = useParams<{
    gigId?: string;
    visibilityId: string;
  }>();
  const [gigDetails, setGigDetails] = useState<any>(null);
  const [isAcceptedGig, setIsAcceptedGig] = useState(false);
  const [checked, setChecked] = useState(false);
  const [values, setValues] = useState<BookmarkData[]>();
  const [success, setSuccess] = useState(false);
  const { isAuthenticated, authHeader, loginWithRedirect } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const history = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isMobileView = useMediaQuery("(max-width: 600px)");

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("success");
  const [gigCapabilities, setGigCapabilities] = useState<Capability[]>([]);

  const handleCopyClick = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setSnackbarMessage("Link copied");
        setSnackbarSeverity("success");
        setOpen(true);
      })
      .catch((error) => {
        console.error("Copy failed:", error);
      });
  };
  const handleWhatsAppShare = async () => {
    const urlToShare = window.location.href;
    try {
      await navigator.clipboard.writeText(urlToShare);
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        urlToShare
      )}`;
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  document.title = Strings.PageTitles.GigDetailsPage;
  useEffect(() => {
    const fetchGigDetails = async () => {
      try {
        if (gigId) {
          const response = await getGigDetailsByGigId(gigId);
          setGigDetails(response.data);
          setSuccess(false);
          setIsDialogOpen(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };
    fetchGigDetails();
  }, [gigId]);

  useEffect(() => {
    const getAcceptedGigs = async () => {
      try {
        if (authHeader && gigId) {
          const acceptedGigs = await getAcceptedGigsByUser(authHeader);
          const isAccepted = acceptedGigs.some((gig) => gig.gigId === gigId);
          setIsAcceptedGig(isAccepted);
        }
      } catch (error) {
        console.error("Error fetching accepted gigs", error);
      }
    };
    getAcceptedGigs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader, gigId]);

  const handleApplyNowClick = async () => {
    if (!isAuthenticated) {
      loginWithRedirect({
        returnTo: window.location.pathname,
      });
    } else {
      setIsDialogOpen(true);
    }
  };

  useEffect(() => {
    const fetchCapabilityData = async () => {
      try {
        if (gigId) {
          const res: any = await getGigCapabilitiesByGigId(gigId);
          setGigCapabilities(res.data);
        }
      } catch (e) {
        console.error("Something went wrong", e);
      }
    };
    fetchCapabilityData();
  }, [gigId]);

  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          const response:any = await getBookmarkDetails(authHeader);
          const isGigMatched = response.data.find(
            (gig: { gigId: string | undefined; }) => gig.gigId === gigId
          );
          if (isGigMatched) {
            setChecked(true);
          }
          setValues(response.data);
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader, gigId]);

  const saveBookmark = async () => {
    setChecked(true);
    try {
      if (authHeader) {
        await saveBookmarkDetails(gigDetails.gigId, authHeader);
      }
    } catch (error) {
      console.error("Error saving bookmark:", error);
      setChecked(false);
    }
  };

  const removeBookmark = async () => {
    try {
      setChecked(false);
      if (!values || !gigDetails || !gigDetails.gigId) {
        console.error("Invalid input data");
        return;
      }
      const gigIdToMatch = gigDetails.gigId;
      const matchedData = values.find((data) => data.gigId === gigIdToMatch);
      if (matchedData?.bookmarkId && authHeader) {
        await removeBookmarkDetails(matchedData?.bookmarkId, authHeader);
      } else {
        if (authHeader) {
          const getBookmarkData:any= await getBookmarkDetails(authHeader);
          const matchedData = getBookmarkData.data.find(
            (data: { gigId: string; }) => data.gigId === gigIdToMatch
          );
          if (matchedData?.bookmarkId && authHeader) {
            await removeBookmarkDetails(matchedData.bookmarkId, authHeader);
          }
        }
      }
    } catch (error) {
      console.error("Error removing bookmark:", error);
      setChecked(true);
    }
  };

  const handleSupplementsClick = () => {
    history("/supplementsPage", { state: { category: gigDetails.category } });
  };

  const handleBookmarkClick = () => {
    if (isAuthenticated) {
      if (checked) {
        removeBookmark();
      } else {
        saveBookmark();
      }
    } else {
      loginWithRedirect({ returnTo: window.location.pathname });
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const handleBack = () => {
    history(-1);
  };

 
  const showGigAssets = gigCapabilities
    .filter((s) => s.capabilityType === "asset")
    .map((s) => s.capabilityName)
    .join(", ");

  const fontAsPerMd =
    "apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji";

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <Card sx={{ borderRadius: "16px" }}>
        <Container sx={{ marginBottom: isMobileView ? "80px" : 0 }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          {isLoading && (
            <LoadingSpinner label="Please wait while we fetch gig Details..." />
          )}

          {!isLoading && !success && (
            <Box sx={{ marginTop: "20px", marginBottom: "40px" }}>
              {gigDetails && (
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={7.5}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "-10px",
                      }}
                    >
                      {gigDetails?.companyLogo ? (
                        <img
                          src={gigDetails.companyLogo}
                          alt={gigDetails.companyName}
                          style={{
                            maxWidth: "132px",
                            height: "60px",
                            marginRight: "10px",
                          }}
                        />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/logos/gmg-logo.png`}
                          alt="company Logo"
                          style={{
                            maxWidth: "132px",
                            marginRight: "10px",
                          }}
                        />
                      )}

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            lineHeight: "1.2",
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          {gigDetails.gigTitle}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column", // default to column for mobile
                            marginBottom: "5px",
                            fontFamily: "Inter, sans-serif",
                            marginTop: "5px",
                            "@media (min-width: 600px)": {
                              flexDirection: "row", // change to row for screens wider than 600px
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: "5px",
                              marginRight: "15px",
                              textTransform: "capitalize",
                            }}
                          >
                            at {gigDetails.companyName}
                          </Typography>
                          <Chip label={gigDetails.jobType || "PART-TIME"} />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {!isMobileView && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {checked ? (
                          <CustomButton
                            tag="GigDetails/bookmark"
                            size="large"
                            variant="outlined"
                            onClick={handleBookmarkClick}
                            startIcon={<StarIcon />}
                            sx={{
                              marginRight: "10px",
                              width: "30%",
                              height: "50%",
                              borderRadius: "12px",
                            }}
                          >
                            saved
                          </CustomButton>
                        ) : (
                          <CustomButton
                            tag="GigDetails/bookmark"
                            size="large"
                            variant="outlined"
                            onClick={handleBookmarkClick}
                            startIcon={<StarOutlineRoundedIcon />}
                            sx={{
                              marginRight: "10px",
                            }}
                          >
                            save
                          </CustomButton>
                        )}

                        {isAcceptedGig ? (
                          <CustomButton
                            tag="GigDetails/Done"
                            variant="contained"
                            size="large"
                            color="success"
                            endIcon={<DoneIcon />}
                          >
                            {Strings.GigDetails.Applied}
                          </CustomButton>
                        ) : (
                          <CustomButton
                            tag="GigDetails/ApplyNow"
                            variant="contained"
                            onClick={handleApplyNowClick}
                            size="large"
                            endIcon={<ArrowForwardIcon />}
                          >
                            {isAuthenticated
                              ? Strings.GigDetails.ApplyNow
                              : "Login to Apply"}
                          </CustomButton>
                        )}
                        {isDialogOpen && visibilityId !== undefined && (
                          <GigForm
                            visibilityId={gigDetails.visibilityId}
                            onSuccess={() => setSuccess(true)}
                            gigId={gigDetails.gigId}
                            gigTitle={gigDetails.gigTitle}
                            handleClose={() => setIsDialogOpen(false)} // Pass a function reference
                          />
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              )}

              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        flex: 1,
                        marginBottom: "20px",
                        padding: "20px 20px 0 20px",
                        backgroundColor: "background.default",
                        borderRadius: "8px",
                      }}
                    >
                      {gigDetails && (
                        <Box>
                          {gigDetails.skillsRequired && (
                            <>
                              {gigDetails.requirements && (
                                <>
                                  <Typography
                                    style={{
                                      fontFamily: fontAsPerMd,
                                      fontWeight: "600",
                                      fontSize: "18px",
                                      color: "#18191C",
                                    }}
                                  >
                                    Basic Requirements
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: fontAsPerMd,
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {gigRequirementsText({ categoryGig: gigDetails, maxLength: 1000 })}
                                  </Typography>
                                </>
                              )}
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "#18191C",
                                }}
                              >
                                Skills Required
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {gigDetails.skillsRequired}
                              </Typography>
                            </>
                          )}
                          {showGigAssets && (
                            <>
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "#18191C",
                                }}
                              >
                                Assets Required
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {showGigAssets}
                              </Typography>
                            </>
                          )}
                          {gigDetails.communicationLanguages && (
                            <>
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "#18191C",
                                }}
                              >
                                Languages to know
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {gigDetails.communicationLanguages}
                              </Typography>
                            </>
                          )}
                          {gigDetails.description && (
                            <>
                              <Typography
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "#18191C",
                                  marginTop: "20px",
                                }}
                              >
                                Description
                              </Typography>
                              <Typography
                                data-color-mode="light"
                                style={{
                                  fontFamily: fontAsPerMd,
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                <MDEditor.Markdown
                                  source={gigDetails.description}
                                />
                              </Typography>
                            </>
                          )}

                          {/* <Box data-color-mode="light" sx={{}}>
                        <MDEditor.Markdown source={gigDetails.description} />
                      </Box> */}
                        </Box>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Paper
                      elevation={0}
                      sx={{
                        flex: 1,
                        marginBottom: "20px",
                        padding: "20px",
                        backgroundColor: "background.default",
                        borderRadius: "8px",
                        border: "2px solid #E7F0FA",
                      }}
                    >
                      {gigDetails && (
                        <Grid container spacing={3} direction="row">
                          <Grid item xs={4}>
                            <Card
                              sx={{
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                                padding:"5px",
                                textAlign: "center",
                                maxHeight:"100px",
                                minHeight:"100px",
                                maxWidth:"100%",
                                minWidth:"100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                {Strings.GigDetails.Salary}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#0BA02C",
                                  fontSize: "20px",
                                  fontWeight: "500",
                                }}
                              >
                                {gigDetails.minWagePerDay !== null
                                  ? Strings.INR + " " + gigDetails.minWagePerDay
                                  : "hello world"}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "16px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                {Strings.GigDetails.SalaryPeriod}
                                <br />
                              </Typography>
                            </Card>
                          </Grid>
                          <Grid item xs={4}>
                            <Card
                              sx={{
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                               maxHeight:"100px",
                               padding:"5px",
                                minHeight:"100px",
                                textAlign: "center",
                                maxWidth:"100%",
                                minWidth:"100%",
                                
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                Earn
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#0BA02C",
                                  fontSize: "20px",
                                  fontWeight: "500",
                                }}
                              >
                                {gigDetails.pricePerClient !== null
                                  ? Strings.INR +
                                    " " +
                                    gigDetails.pricePerClient
                                  : "hello world"}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "16px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                per valid entry
                                <br />
                              </Typography>
                            </Card>
                          </Grid>
                          <Grid item xs={4}>
                            <Card
                              sx={{
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                                padding:"5px",
                                textAlign: "center",
                                maxHeight:"100px",
                                minHeight:"100px",
                                maxWidth:"100%",
                                minWidth:"100%"
                                
                              }}
                            >
                              <Map
                                color="primary"
                                sx={{ marginRight: "10px" }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                <strong>
                                  Job Location
                                  <br />
                                </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "16px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                {gigDetails.location !== ""
                                  ? gigDetails.location
                                  : "India"}
                                <br />
                              </Typography>
                            </Card>
                          </Grid>
                        </Grid>
                      )}
                    </Paper>

                    <Paper
                      elevation={0}
                      sx={{
                        backgroundColor: "background.default",
                        borderRadius: "8px",
                        border: "2px solid #E7F0FA",
                      }}
                    >
                      <Box sx={{ margin: "20px" }}>
                        {gigDetails && (
                          <Grid container spacing={3}>
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <CalendarIcon
                                sx={{ fontSize: "30px", color: "#0A65CC" }}
                              />
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "12px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                JOB STARTS FROM
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                {formatDate(gigDetails.gigStartDate)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <TimerIcon
                                sx={{ fontSize: "30px", color: "#0A65CC" }}
                              />
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "12px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                JOB EXPIRES IN
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                {formatDate(gigDetails.gigEndDate)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <FilterIcon
                                sx={{ fontSize: "30px", color: "#0A65CC" }}
                              />
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "12px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                JOB LEVEL
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                Entry Level
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Wallet
                                sx={{ fontSize: "30px", color: "#0A65CC" }}
                              />
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "12px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                EXPERIENCE
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                Not Applicable
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <WorkIcon
                                sx={{ fontSize: "30px", color: "#0A65CC" }}
                              />
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "12px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                EDUCATION
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                {gigDetails.academicQualificationRequired}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={4}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={handleSupplementsClick}
                            >
                              <TrainingIcon
                                sx={{ fontSize: "30px", color: "#0A65CC" }}
                              />
                              <Typography
                                sx={{
                                  color: "grey",
                                  fontSize: "12px",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                <Link
                                  onClick={handleSupplementsClick}
                                  underline="always"
                                >
                                  {"SUPPLEMENTS"}
                                </Link>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  fontFamily: "inter, sans-serif",
                                }}
                              >
                                Tutorial
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                      <Divider sx={{ marginY: "10px", width: "100%" }} />{" "}
                      {/* Full-width horizontal divider */}
                      <Box
                        sx={{
                          paddingLeft: "30px",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          <strong>{Strings.GigDetails.ShareJob}</strong>
                        </Typography>
                        <CustomButton
                          tag="GigDetails/CopyLink"
                          startIcon={<LinkIcon />}
                          onClick={() => {
                            handleCopyClick();
                          }}
                        >
                          {Strings.ShareType.CopyLink}
                        </CustomButton>

                        <CustomButton onClick={handleWhatsAppShare}>
                          Share on whatsapp
                        </CustomButton>
                      </Box>
                      <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                      >
                        <MuiAlert
                          elevation={6}
                          variant="filled"
                          onClose={handleClose}
                          severity={snackbarSeverity}
                        >
                          {snackbarMessage}
                        </MuiAlert>
                      </Snackbar>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {!isLoading && success && (
            <SuccessApplication gigTitle={gigDetails.gigTitle} />
          )}
          {isMobileView && !success && (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px 10px",
                backgroundColor: "white",
                marginTop: 0,
              }}
            >
              {checked ? (
                <CustomButton
                  tag="GigDetails/bookmark"
                  size="large"
                  variant="outlined"
                  onClick={handleBookmarkClick}
                  startIcon={<StarIcon />}
                  sx={{
                    marginRight: "10px",
                    width: "30%",
                    height: "50%",
                    borderRadius: "12px",
                  }}
                >
                  saved
                </CustomButton>
              ) : (
                <CustomButton
                  tag="GigDetails/bookmark"
                  size="large"
                  variant="outlined"
                  onClick={handleBookmarkClick}
                  startIcon={<StarOutlineRoundedIcon />}
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  save
                </CustomButton>
              )}
              {isAcceptedGig ? (
                <CustomButton
                  tag="GigDetails/Done"
                  variant="contained"
                  size="large"
                  color="success"
                  endIcon={<DoneIcon />}
                  sx={{ width: "70%", height: "50%", borderRadius: "10px" }}
                >
                  {Strings.GigDetails.Applied}
                </CustomButton>
              ) : (
                <CustomButton
                  tag="GigDetails/ApplyNow"
                  variant="contained"
                  onClick={handleApplyNowClick}
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  sx={{ width: "70%", height: "50%", borderRadius: "10px" }}
                >
                  {isAuthenticated
                    ? Strings.GigDetails.ApplyNow
                    : "Login to Apply"}
                </CustomButton>
              )}
              {isDialogOpen && visibilityId !== undefined && (
                <GigForm
                  visibilityId={gigDetails.visibilityId}
                  onSuccess={() => setSuccess(true)}
                  gigId={gigDetails.gigId}
                  gigTitle={gigDetails.gigTitle}
                  handleClose={() => setIsDialogOpen(false)} // Pass a function reference
                />
              )}
            </div>
          )}
        </Container>
      </Card>
    </Slide>
  );
};

export default GigDetails;
