import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface HandleTextQuesProps {
  question: string;
  questionId: string;
  answers: Record<string, string>;
  handleAnswerChange: (questionId: string, value: string) => void;
  gridSpace: number;
  disabled: boolean;
  onErrorChange: (error: string | null, field: string) => void;
  vehicleNumbers: string[];
}

const HandleTextQues: React.FC<HandleTextQuesProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  gridSpace,
  disabled,
  onErrorChange,
  vehicleNumbers,
}) => {
  const [vehicleNumber, setVehicleNumber] = useState<string | null>(
    answers[questionId]?.toString() || ""
  );
  const [vehicleNumberError, setVehicleNumberError] = useState<string | null>(
    null
  );

  const shouldValidateVehicleNumber =
    question.toLowerCase().includes("vehicle number") ||
    question.toLowerCase().includes("auto number") ||
    question.toLowerCase().includes("number plate");

  const validateVehicleNumber = (number: string) => {
    const vehiclePlateRegex = /^[A-Z]{2}\d{1,2}[A-Z]{0,2}\d{1,4}$/; // The format it accepts AA11AA1111,  AA111111

    if (!number.match(vehiclePlateRegex)) {
      setVehicleNumberError("Invalid vehicle number plate.");
      onErrorChange("Invalid vehicle number plate.",question);
      return false;
    }

    if (vehicleNumbers.includes(number)) {
      setVehicleNumberError("Number already exists.");
      onErrorChange("Number already exists.", question);
      return false;
    }
    onErrorChange(null, question);
    setVehicleNumberError(null);
    return true;
  };

  const handleVehicleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newVehicleNumber = e.target.value;

    if (shouldValidateVehicleNumber) {
      newVehicleNumber = newVehicleNumber.toUpperCase();
    }

    setVehicleNumber(newVehicleNumber);

    if (shouldValidateVehicleNumber) {
      validateVehicleNumber(newVehicleNumber);
    }

    // Always handle answer change, regardless of validation
    handleAnswerChange(questionId, newVehicleNumber);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography
          variant="h6"
          sx={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            textJustify: "inter-word",
          }}
        >
          {question}
        </Typography>
      </FormLabel>{" "}
      <TextField
        variant="outlined"
        value={vehicleNumber}
        onChange={handleVehicleNumberChange}
        style={{ width: "250px" }}
        size="small"
        helperText={
          shouldValidateVehicleNumber
            ? vehicleNumberError || "Type a valid vehicle number plate"
            : ""
        }
        error={!!vehicleNumberError}
        disabled={disabled}
        autoComplete="off"
      />
      <br />
    </FormControl>
  );
};

export default HandleTextQues;
