import { FC, ReactNode } from "react";
import { Box, Grid, Container, Typography, Button } from "@mui/material";
import WorkTwoToned from "@mui/icons-material/WorkTwoTone";
import NextWeekTwoToned from "@mui/icons-material/NextWeekTwoTone";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../providers/AuthProvider";
interface Exp {
  label: string;
  value: string;
  icon: ReactNode;
}
interface ExpItemProps {
  item: Exp;
}

const exps: Array<Exp> = [
  {
    label: "Live Gigs",
    value: "10+",
    icon: <WorkTwoToned fontSize="large" color="primary" />,
  },
  {
    label: "Companies",
    value: "20+",
    icon: <BusinessOutlinedIcon fontSize="large" color="primary" />,
  },
  {
    label: "Gig Seekers",
    value: "10+",
    icon: <PeopleAltOutlinedIcon fontSize="large" color="primary" />,
  },
  {
    label: "Upcoming Gigs",
    value: "21+",
    icon: <NextWeekTwoToned fontSize="large" color="primary" />,
  },
];

const ExpItem: FC<ExpItemProps> = ({ item }) => {
  const { value, label, icon } = item;

  return (
    <Box
      sx={{ backgroundColor: "background.default", mb: { xs: 1, md: 0 } }}
      borderRadius={2}
    >
      <Grid container sx={{ padding: 2 }}>
        <Box
          sx={{
            width: "72px",
            height: "72px",
            backgroundColor: "#E7F0FA",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
            borderRadius: 1,
          }}
        >
          {icon}
        </Box>
        <Box sx={{ marginLeft: "16px" }}>
          <Typography
            margin={0}
            sx={{ fontSize: { xs: 22, md: 24 }, fontWeight: "bold" }}
          >
            {value}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              fontSize: { xs: 14, md: 16 },
              fontFamily: "inter, sans-serif",
            }}
          >
            {label}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

const HomeHero: FC = () => {
  const history = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuthContext();
  const redirectToAppliedJobs = () => {
    history("/task/gigHistory");
  };
  return (
    <Box
      className="hero-home"
      id="hero"
      sx={{
        backgroundColor: "background.paper",
        position: "relative",
        pt: 4,
        pb: { xs: 8, md: 10 },
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={0}
          sx={{ flexDirection: { xs: "column", md: "unset" } }}
        >
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography
                  component="h2"
                  sx={{
                    position: "relative",
                    fontSize: { xs: 36, md: 56 },
                    letterSpacing: 1.5,
                    fontWeight: "bold",
                    fontFamily: "inter, sans-serif",
                    lineHeight: 1.1,
                  }}
                >
                  Find a{" "}
                  <Typography
                    component="mark"
                    sx={{
                      position: "relative",
                      color: "secondary.main",
                      fontSize: "inherit",
                      fontWeight: "inherit",
                      fontFamily: "inter, sans-serif",
                      backgroundColor: "unset",
                      lineHeight: 1.1,
                    }}
                  >
                    Gig{" "}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "inherit",
                      fontWeight: "inherit",
                      fontFamily: "inter, sans-serif",
                      position: "relative",
                      lineHeight: 1.1,
                      "& svg": {
                        position: "absolute",
                        top: -16,
                        right: -21,
                        width: { xs: 22, md: 30 },
                        height: "auto",
                      },
                    }}
                  >
                    that suits
                  </Typography>{" "}
                  your interest & skills.
                </Typography>
              </Box>
              <Box sx={{ mb: 4, width: { xs: "100%", md: "80%" } }}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    lineHeight: 1.6,
                    fontFamily: "inter, sans-serif",
                  }}
                >
                  {
                    "Welcome to gOGig, where you can find exciting job chances in India's growing gig economy. Discover jobs that let you work when you want, learn new things, and earn money on your own schedule. Join our community of motivated people and begin creating your future today!"
                  }
                </Typography>
                <Button
                  sx={{ marginTop: "15px" }}
                  size="small"
                  variant="contained"
                  onClick={
                    isAuthenticated
                      ? redirectToAppliedJobs
                      : () => loginWithRedirect({})
                  }
                >
                  {isAuthenticated ? "My applied gigs" : "Get started"}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{ lineHeight: 0, display: "flex", justifyContent: "center" }}
            >
              <img
                style={{ maxWidth: "100%" }}
                src={`${process.env.PUBLIC_URL}/images/home/Illustration.png`}
                alt="Hero img"
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={4}>
          {exps.map((item, index) => (
            <Grid key={index} item xs={6} md={3}>
              <ExpItem item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeHero;
