import React, { useState, useEffect } from "react";
import {
  Container,
  Tabs,
  Tab,
  Paper,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { getGigDetailsByUserId } from "@gogig-in/gogig-clients";
import DraftGigList from "./DraftGigsList";
import PublishedGigList from "./PublishedGigList";
import CompleteGigList from "./CompletedGigList";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";

const EmployerGigList: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialStatus = queryParams.get("status");
  const { authHeader } = useAuthContext();
  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          await getGigDetailsByUserId(authHeader);
        }
      } catch (e) {
        console.error("Error during getGigDetailsByUserId",e);
      }
    })();
  }, [authHeader]);
  useEffect(() => {
    if (initialStatus === "draft") {
      setCurrentTab(0);
    } else if (initialStatus === "published") {
      setCurrentTab(1);
    } else if (initialStatus === "completed") {
      setCurrentTab(2);
    }
  }, [initialStatus]);
  return (
    <Container disableGutters sx={{ padding: "40px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            "& .MuiTab-root": {
              color: "#212121",
              fontWeight: "400",
              fontSize: "17px",
              "&.Mui-selected": {
                color: "#212121",
              },
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "black",
            },
          }}
        >
          <Tab label="DRAFT" />
          <Tab label="PUBLISHED" />
          <Tab label="COMPLETED" />
        </Tabs>
        <Link to={"/create-gig/basic-details"}>
          <Button color="primary">Create Gig</Button>
        </Link>
      </Box>
      <Divider sx={{ borderWidth: "0.5px", borderColor: "#757575" }} />
      <Paper
        elevation={4}
        sx={{
          margin: "20px auto",
          maxWidth: "1200px",
          borderRadius: "15px",
          padding: "20px",
        }}
      >
        {currentTab === 0 && <DraftGigList />}
        {currentTab === 1 && <PublishedGigList />}
        {currentTab === 2 && <CompleteGigList />}
      </Paper>
    </Container>
  );
};

export default EmployerGigList;
