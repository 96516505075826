
export interface EmpFormValues {
    name: string;
    jobTitle: string;
    bio: string;
    phoneNumber: number;
    emailId: string;
    }
    
const initialEmpFormValues: EmpFormValues = {
    name: "",
    jobTitle: "",
    bio: "",
    phoneNumber: 0,
    emailId: ""
};
    
export default initialEmpFormValues;