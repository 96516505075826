import React, { FC, ReactNode } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Grid, useMediaQuery, Box, Container, Typography } from "@mui/material";

interface StepItem {
  title: string;
  subTitle: string;
  icon: ReactNode;
}

const workSteps: Array<StepItem> = [
  {
    title: "Create an Account",
    subTitle: "Create an account on gOGig's website or app for free.",
    icon: (
      <AccountCircleOutlinedIcon color="primary" sx={{ fontSize: "32px" }} />
    ),
  },
  {
    title: "Find Suitable Job",
    subTitle: "Explore various job opportunities available on the platform.",
    icon: <ZoomInIcon color="primary" sx={{ fontSize: "32px" }} />,
  },
  {
    title: "Easy Apply",
    subTitle:
      "Apply for gigs that match your skills and interests, complete the task as per agreement.",
    icon: <VerifiedOutlinedIcon color="primary" sx={{ fontSize: "32px" }} />,
  },
  {
    title: "Get Paid",
    subTitle:
      "Receive payment for your work through the platform's secure payment system.",
    icon: <CurrencyRupeeIcon color="primary" sx={{ fontSize: "32px" }} />,
  },
];

const WorkProcessItem = ({
  step,
  isEven,
  isLast,
}: {
  step: StepItem;
  isEven: boolean;
  isLast: boolean;
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)"); // Adjust the tablet breakpoint as needed

  const primaryTextStyle = {
    fontWeight: "bold",
    display: "block",
    fontSize: "22px",
    fontFamily: "inter, sans-serif",
  };

  const secondaryTextStyle = {
    fontWeight: "normal",
    display: "block",
    fontSize: "17px",
    marginTop: "8px",
    fontFamily: "inter, sans-serif",
    color: "#767F8C",
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            width: "72px",
            height: "72px",
            borderRadius: "50%",
            backgroundColor: "background.default",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          {step.icon}
        </Box>
        <Typography sx={primaryTextStyle}>{step.title}</Typography>
        <Typography sx={secondaryTextStyle}>{step.subTitle}</Typography>
      </Box>
      {!isMobile && !isTablet && !isLast && (
        <img
          style={{
            width: "75%",
            position: "absolute",
            top: isEven ? "-10px" : "50px",
            paddingLeft: "50px",
          }}
          src={
            isEven
              ? `${process.env.PUBLIC_URL}/images/home/arrows.png`
              : `${process.env.PUBLIC_URL}/images/home/arrow-reverse.png`
          }
          alt="arrow img"
        />
      )}
    </Box>
  );
};

const WorkingProcess: FC = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        pt: isMobile ? 4 : { xs: 4, md: 4 },
        pb: isMobile ? 4 : { xs: 4, md: 4 },
        textAlign: "center",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mb: 3 }}>
          <Typography
            component="h2"
            sx={{
              position: "relative",
              fontSize: isMobile ? 24 : { xs: 40, md: 36 },
              letterSpacing: 1.5,
              fontWeight: "bold",
              lineHeight: 1.1,
            }}
          >
            How gOGig Work
          </Typography>
        </Box>
        <Grid container spacing={2} mt={4}>
          {workSteps.map((step, index) => (
            <Grid key={step.title} item xs={12} md={3}>
              <WorkProcessItem
                step={step}
                isEven={index % 2 === 0}
                isLast={index === workSteps.length - 1}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WorkingProcess;
