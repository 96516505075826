import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FooterSocialLinks from "./footer-social-links";
import FooterNavigation from "./FooterNavigation";
import { useMediaQuery, useTheme } from "@mui/material";

const Footer = () => {
  const { breakpoints } = useTheme();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#18191C",
        paddingTop: "60px",
        color: "primary.contrastText",
      }}
    >
      <Container sx={{ paddingBottom: matchMobileView ? "80px" : 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Box sx={{ width: { xs: "100%", md: 360 }, mb: { xs: 3, md: 0 } }}>
              <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
                gOGig
              </Typography>
              <Typography variant="subtitle1" sx={{ letterSpacing: 1, mb: 2 }}>
                Rukmini Knowledge Park, Kattigenahalli, SH 104, Srinivasa Nagar,
                Bengaluru, Karnataka 560064
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <FooterNavigation />
          </Grid>
        </Grid>
        <hr style={{ width: "100%", borderTop: "1px #ccc" }} />

        <FooterSocialLinks />
      </Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>
          Copyright © 2024 Tolu Technologies Private Limited
        </Typography>
      </Box>
      <br />
    </Box>
  );
};

export default Footer;
