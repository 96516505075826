import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { addUserData, UserData } from "@gogig-in/gogig-clients";
import validateAge from "../validators/DateValidators";
import validateEmail from "../validators/EmailValidator";
import validateGender from "../validators/GenderValidators";
import { useAuthContext } from "../../providers/AuthProvider";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import dateFormat from "dateformat";
import CustomModal from "../custom/CustomModal";

interface PopupDialogProps {
  open: boolean;
  onClose: () => void;
}

const EditProfilePopup: React.FC<PopupDialogProps | any> = ({
  open,
  onClose,
}) => {
  const [userData, setUserData] = useState<UserData>({
    firstName: "",
    lastName: "",
    emailId: "",
    gender: "",
    dob: "",
  });

  const [errors, setErrors] = useState<Partial<UserData> | any>({});
  const genders = ["male", "female", "other"];
  const { authHeader, userDetails, resetUser } = useAuthContext();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<Severity>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userDetails) {
      const { firstName, lastName, emailId, gender, dob } = userDetails;
      setUserData({
        firstName,
        lastName,
        emailId,
        gender,
        dob,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (userDetails) {
      setUserData({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        emailId: userDetails.emailId,
        gender: userDetails.gender,
        dob: userDetails.dob,
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      userData.dob === undefined ||
      userData.dob === null ||
      userData.dob === ""
    ) {
      userData.dob = ""; // Set dob to null if it's undefined, null, or empty
    } else {
      // Assuming userData.dob is a valid date string from the API
      userData.dob = dateFormat(userData.dob, "yyyy-mm-dd");
    }
  }, [userData]);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  // Format the date as YYYY-MM-DD (required format for input type 'date')
  const maxDateString = maxDate.toISOString().split("T")[0];

  const handleClose = () => {
    setUserData({
      firstName: "",
      lastName: "",
      emailId: "",
      gender: "",
      dob: "",
    });
    onClose();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof UserData
  ) => {
    setUserData({
      ...userData,
      [field]: e.target.value,
    });
    setErrors({
      ...errors,
      [field]: "", // Clear error message when input changes
    });
  };

  const validateForm = (): boolean => {
    let isValid = true;
    const newErrors: Partial<UserData> = {};

    // Check for empty fields
    for (const key in userData) {
      if (!userData[key as keyof UserData]) {
        newErrors[key as keyof UserData] = "This field is required";
        isValid = false;
      }
    }

    // Email validation
    if (!validateEmail(userData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      isValid = false;
    }

    // Date of Birth validation
    if (!validateAge(userData.dob)) {
      newErrors.dob = "You must be at least 18 years old";
      isValid = false;
    }

    // Gender validation
    if (!validateGender(userData.gender, genders)) {
      newErrors.gender = "Please select a valid gender";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdateProfile = async () => {
    try {
      // Assuming AddUserData returns errors in case of failure
      setIsLoading(true);
      if (validateForm() && authHeader) {
        await addUserData(userData, authHeader);
        setOpenSnackbar(true);
        setSnackbarMessage("Your profile has been updated successfully");
        setSeverity("success");
        await resetUser();
        setIsLoading(false);
        handleClose();
      }
    } catch (error: any) {
      // Check if the error is an object with errors property
      if (error && error.errors) {
        setIsLoading(false);
        const newErrors: Partial<UserData> = {};

        // Loop through the errors and set them to corresponding fields
        for (const err of error.errors) {
          newErrors[err.field as keyof UserData] = err.message;
        }

        // Set errors state to display the error messages
        setErrors(newErrors);
      } else {
        // Handle other types of errors if necessary
        console.error("Error:",error);
      }
    }
  };

  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        severity={severity}
        vertical={"top"}
        horizontal={"left"}
      />

      <CustomModal
        open={open}
        onClose={handleClose}
        title="Edit your details"
        isLoading={isLoading}
        primaryActionText="Submit"
        primaryActionOnclick={handleUpdateProfile}
      >
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              hiddenLabel
              label="First name"
              autoComplete="cc-given-name"
              variant="outlined"
              value={userData.firstName}
              onChange={(e: any) => handleInputChange(e, "firstName")}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last name"
              autoComplete="cc-family-name"
              variant="outlined"
              value={userData.lastName}
              onChange={(e: any) => handleInputChange(e, "lastName")}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoComplete="email"
              label="Email address"
              variant="outlined"
              type="email"
              value={userData.emailId}
              onChange={(e: any) => handleInputChange(e, "emailId")}
              error={!!errors.emailId}
              helperText={errors.emailId}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth error={!!errors.gender}>
              <InputLabel>Gender</InputLabel>
              <Select
                value={userData.gender}
                label="Gender"
                onChange={(e: any) => handleInputChange(e, "gender")}
                error={!!errors.gender}
                required
                variant="outlined"
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"others"}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              name="dob"
              label="Date of Birth"
              autoComplete="off"
              fullWidth
              onChange={(e: any) => handleInputChange(e, "dob")}
              value={dateFormat(userData.dob, "yyyy-mm-dd")}
              error={!!errors.dob}
              helperText={errors.dob}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: maxDateString,
              }}
            />
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default EditProfilePopup;
