import { Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Strings from "../../Locale/strings.json";
import { GigHistoryDetails } from "./GigHistoryValues";
import GigDashboardCard from "./GigDashboardCard";
import SkeletonModal from "../skeleton/SkeletonModal";

interface OngoingGigProps {
  allGigs: GigHistoryDetails[];
  isLoading: boolean;
}

const AllGigHistory = ({ allGigs, isLoading }: OngoingGigProps) => {
  const history = useNavigate();

  // const handlePaymentClick = async (gigDetails: GigHistoryDetails) => {
  //   try {
  //     if (authHeader) {
  //       const paymentData = {
  //         gigId: gigDetails.gigId,
  //         userId: gigDetails.userId,
  //         amount: gigDetails.earned,
  //         title: gigDetails.gigTitle,
  //         tasksCompleted: gigDetails.totalVendorsCompletedByUser.toString(),
  //         transactionType: "credit",
  //       };

  //       await sendForPayment(paymentData, authHeader, gigDetails.acceptanceId);
  //       history("/wallet");
  //     } else {
  //       console.error("authHeader is undefined");
  //     }
  //   } catch (error) {
  //     console.error("Error sending payment:", error);
  //   }
  // };

  const redirectToBrowseJobs = () => {
    history("/browse-jobs");
  };
  document.title = Strings.PageTitles.GigHistoryPage;

  return (
    <Box sx={{ width: "100%" }}>
      {isLoading && (
        <SkeletonModal numberOfCards={3} skeletonType="dashboard" />
      )}
      {!isLoading && allGigs.length === 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            You do not have any ongoing gigs press
            <Button
              variant="text"
              onClick={redirectToBrowseJobs}
              sx={{
                ml: "-9px",
                mr: "-11px",
                color: "pallete.dark",
                textTransform: "none",
              }}
            >
              here{" "}
            </Button>{" "}
            to apply for gigs...
          </Typography>
        </Box>
      )}
      {!isLoading && allGigs.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {allGigs.map((gig) => (
            <GigDashboardCard
              key={gig.gigId}
              gig={gig}
              // handleSecondButtonClick={handlePaymentClick}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AllGigHistory;
