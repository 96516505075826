import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  TextField,
  Box,
  Alert,
  Grid,
  Card,
  CardContent,
  Modal,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { checkPin, getWalletDetails } from "@gogig-in/gogig-clients";
import CreatePin from "./CreatePin";
import { useAuthContext } from "../../providers/AuthProvider";

const WithdrawPage: React.FC = () => {
  const location = useLocation();
  const accountData = location.state?.data;
  const [withdrawAmount, setWithdrawAmount] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const history = useNavigate();
  const minWithdrawalAmount: number = 0;
  const { authHeader } = useAuthContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [walletDetails, setWalletDetails] = useState<Record<string, any>>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const walletResponse: any = await getWalletDetails(authHeader);
          setWalletDetails(walletResponse.data[0]);
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    fetchData();
  }, [authHeader]);

  const handleWithdraw = async () => {
    const numericValue = parseFloat(withdrawAmount);
    if (isNaN(numericValue)) {
      setError("Invalid withdrawal amount");
    } else if (numericValue < minWithdrawalAmount) {
      setError(`Minimum withdrawal amount is ${minWithdrawalAmount} INR`);
    } else if (
      !walletDetails ||
      numericValue > walletDetails.availableBalance
    ) {
      setError("Withdrawal amount cannot exceed available balance");
    } else {
      try {
        if (authHeader) {
          const response: any = await checkPin(authHeader);
          const result = response.data;
          if (result.status === "not available") {
            setModalOpen(true);
          } else if (result.status === "available") {
            history("/payment/withdraw-confirmation", {
              state: {
                withdrawalAmount: numericValue,
                paymentMethodId: accountData.paymentMethodId,
              },
            });
          } else {
            setError("Unexpected pin status");
          }
        }
      } catch (error) {
        console.error("Error during checking pin:", error);
        setError("Error making the withdrawal");
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    const numericValue = parseFloat(inputText);
    setWithdrawAmount(isNaN(numericValue) ? "" : numericValue.toString());

    if (isNaN(numericValue)) {
      setError("Invalid withdrawal amount");
    } else if (numericValue < minWithdrawalAmount) {
      setError(`Minimum withdrawal amount is ${minWithdrawalAmount} INR`);
    } else if (
      !walletDetails ||
      numericValue > walletDetails.availableBalance
    ) {
      setError("Withdrawal amount cannot exceed available balance");
    } else {
      setError(null);
    }
  };

  const handleBackToWallet = () => {
    history("/wallet");
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxWidth: 450,
          width: "100%",
          marginBottom: "20px",
          backgroundColor: "background.default",
          marginTop: "50px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h2" sx={{ margin: "20px" }}>
              Withdraw Page
            </Typography>
            <Typography sx={{ fontSize: "24px", marginTop: "10px" }}>
              Available Balance
            </Typography>
            {walletDetails && (
              <Typography sx={{ margin: "5px", fontSize: "20px" }}>
                Rs {walletDetails.availableBalance}
              </Typography>
            )}
            <TextField
              label="Enter the Amount(INR)"
              variant="outlined"
              value={withdrawAmount}
              onChange={handleChange}
              fullWidth
              sx={{ marginBottom: "20px", marginTop: "30px" }}
              error={!!error}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              autoComplete="off"
            />
            {error && (
              <Alert severity="error" sx={{ marginBottom: "20px" }}>
                {error}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <Button
                  variant="contained"
                  onClick={handleWithdraw}
                  fullWidth
                  disabled={!!error}
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  Continue to Withdraw
                </Button>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Button
                  variant="outlined"
                  onClick={handleBackToWallet}
                  fullWidth
                  sx={{
                    marginBottom: "20px",
                    color: "#DC3545",
                    borderColor: "#DC3545",
                    "&:hover": {
                      backgroundColor: "#FDEDEC",
                      borderColor: "#DC3545",
                    },
                  }}
                >
                  Back to Wallet
                </Button>
              </Grid>
            </Grid>
            {accountData && (
              <Box sx={{ marginTop: "20px" }}>
                {accountData.type === "upi" ? (
                  <Typography>
                    Payment will be made to:{" "}
                    <strong>
                      {accountData.name} - {accountData.upiId}
                    </strong>
                  </Typography>
                ) : (
                  <div>
                    <Typography>
                      Payment will be made to:{" "}
                      <strong>
                        {accountData.name} - {accountData.accountNo}
                      </strong>
                    </Typography>
                  </div>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ margin: "100px 0px" }}
      >
        <CreatePin onClose={handleCloseModal} />
      </Modal>
    </Container>
  );
};

export default WithdrawPage;
