import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import { Navigation } from "../../types/navigation";
import FooterSectionTitle from "./footer-section-title";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Strings from "../../Locale/strings.json";
import "./footer-navigation.css";
import { useAuthContext } from "../../providers/AuthProvider";
const quickMenu: Array<Navigation> = [
  {
    label: Strings.FooterNavigation.About,
    path: "/about",
  },
];

const candidateMenu: Array<Navigation> = [
  { label: Strings.FooterNavigation.BrowseJobs, path: "/browse-jobs" },
];

const supportMenu: Array<Navigation> = [
  { label: Strings.FooterNavigation.Faqs, path: "/faq" },
  { label: Strings.FooterNavigation.PrivacyPolicy, path: "/privacy-policy" },
  {
    label: Strings.FooterNavigation.TermsConditions,
    path: "/terms-conditions",
  },
];

interface NavigationItemProps {
  label: string;
  path: string;
}

const NavigationItem: FC<NavigationItemProps> = ({ label, path }) => {
  return (
    <Typography sx={{ display: "block", mb: 1 }}>
      <Link
        to={path}
        target={path === "/faq" ? "_parent" : "_blank"}
        className="footer-navigation-items"
      >
        {label}
      </Link>
    </Typography>
  );
};

const FooterNavigation: FC = () => {
  const { isAuthenticated } = useAuthContext();

  return (
    <Grid container spacing={4}>
      {/* Quick Link Section */}
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Quick Link" />
        {quickMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
      {/* Candidate Section */}
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Candidate" />
        {candidateMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
        {isAuthenticated && (
          <NavigationItem
            label={Strings.FooterNavigation.CandidateDashboard}
            path="/task/gigHistory"
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Support" />
        {supportMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
    </Grid>
  );
};

export default FooterNavigation;
