import React, { useEffect, useState } from "react";
import { Container, Typography, Paper, Button } from "@mui/material";
import { getGigDetailsByUserId } from "@gogig-in/gogig-clients";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAuthContext } from "../../providers/AuthProvider";

interface GigDetails {
  gigId: string;
  gigTitle: string;
  category: string;
  budget: string;
  status: "draft" | "published" | "completed";
  creationDate: string;
  gigStartDate: string;
  skillsRequired: string;
  numberOfApplicants: string;
  completedAt: string;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const currentDate = new Date();
  const isSameDay =
    date.getDate() === currentDate.getDate() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear();

  if (isSameDay) {
    const timeDifference = currentDate.getTime() - date.getTime();
    if (timeDifference < 60 * 1000) {
      const seconds = Math.floor(timeDifference / 1000);
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (timeDifference < 60 * 60 * 1000) {
      const minutes = Math.floor(timeDifference / (60 * 1000));
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else {
      const hours = Math.floor(timeDifference / (60 * 60 * 1000));
      const minutes = Math.floor(
        (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
      );
      return `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} minute${
        minutes !== 1 ? "s" : ""
      } ago`;
    }
  } else {
    return date.toLocaleDateString("en-GB");
  }
};

const CompletedGigList: React.FC = () => {
  const [gigData, setGigData] = useState<GigDetails[]>([]);
  const { authHeader } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const response: any = await getGigDetailsByUserId(authHeader);

          const sortedData = response.data
            .filter((card: GigDetails) => card.status === "completed")
            .sort(
              (a: GigDetails, b: GigDetails) =>
                new Date(b.creationDate).getTime() -
                new Date(a.creationDate).getTime()
            );

          setGigData(sortedData);
        }
      } catch (e) {
        console.error("error during getGigDetailsByUserId",e);
      }
    };

    fetchData();
  }, [authHeader]);

  return (
    <Container disableGutters>
      <Paper elevation={0} sx={{ margin: "20px auto", maxWidth: "1200px" }}>
        <div style={{ gap: "20px" }}>
          {gigData.map((card, index) => (
            <div
              key={index}
              style={{
                marginBottom: "0px",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                fontWeight: "bolder",
                position: "relative",
                transition: "background-color 0.3s",
                cursor: "pointer",
                borderBottom: "1px solid #ccc",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "white";
                e.currentTarget.style.borderRadius = "10px";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#F1F2F4";
                e.currentTarget.style.borderRadius = "0px";
              }}
            >
              <Link
                to={`/gig-details/${card.gigId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    position: "absolute",
                    bottom: 15,
                    right: 7,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Know More
                    <NavigateNextIcon style={{ marginLeft: "5px" }} />
                  </div>
                </Button>
              </Link>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                fontSize={"20px"}
              >
                {card.gigTitle}
              </Typography>
              <Typography>Category: Tele-Calling</Typography>
              <Typography>Budget: {card.budget}</Typography>
              <Typography>Skills Required: {card.skillsRequired}</Typography>
              <Typography>
                Gig Start Date:{" "}
                {new Date(card.gigStartDate).toLocaleDateString("en-GB")}
              </Typography>
              <Typography>
                Created on: {formatDate(card.creationDate)}
              </Typography>
              <Typography>
                Completed on: {formatDate(card.completedAt)}
              </Typography>
              <Typography>
                <strong>Number of Applicants: </strong>
                {card.numberOfApplicants}
              </Typography>
            </div>
          ))}
        </div>
      </Paper>
    </Container>
  );
};

export default CompletedGigList;
