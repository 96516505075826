export interface CustomError {
  code: string;
  message: string;
  page?: string;
  gigId?: string;
}

export const ErrorMessages: Record<string, string> = {
  GigIdNotDefined: "Gig ID is not defined",
  NoDataFound: "No data found for this gig",
  FetchingGigDetailsError: "Error fetching gig details",
};

export function logCustomError(error: CustomError): void {
  const errorMessage = `Code: ${error.code}, Message: ${error.message}, Page: ${error.page}, Gig ID: ${error.gigId}`;
  console.error(errorMessage);
}
