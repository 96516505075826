import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HandleImageQuestion from "./vendorDetail/handleImageQues";
import { saveWalletCredentials } from "@gogig-in/gogig-clients";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";

const AadharPanImageForm: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [aadhaarCardNumber, setAadhaarCardNumber] = useState("");
  const [panCardNumber, setPanCardNumber] = useState("");
  const [aadhaarCardImage, setAadhaarCardImage] = useState<File | null>(null);
  const [panCardImage, setPanCardImage] = useState<File | null>(null);
  const [aadharCardNumberError, setAadharCardNumberError] =
    useState<string>("");
  const [panCardNumberError, setPanCardNumberError] = useState<string>("");
  const [panCardImageError, setPanCardImageError] = useState<string>("");
  const [aadharCardImageError, setAadharCardImageError] = useState<string>("");
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const history = useNavigate();

  const handleAadharFileSelected = (file: File, question: string) => {
    setAadhaarCardImage(file);
    setAadharCardImageError("");
  };
  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string
  ) => {
    const { value } = e.target;

    switch (fieldName) {
      case "aadharCardNumber":
        setAadhaarCardNumber(value);
        setAadharCardNumberError(
          validateAadharNumber(value) ? "" : "Aadhar number should be 12 digits"
        );
        break;
      case "panCardNumber":
        setPanCardNumber(value.toUpperCase());
        setPanCardNumberError(
          validatePanCardNumber(value.toUpperCase())
            ? ""
            : "PAN card number should be in the format ABCDE1234F"
        );
        break;

      default:
        break;
    }
  };

  const handlePanCardFileSelected = (file: File, question: string) => {
    setPanCardImage(file);
    setPanCardImageError("");
  };

  const handleFileRemove = (question: string) => {
    if (question === "Upload Aadhar Card") {
      setAadhaarCardImage(null);
    } else if (question === "Upload PAN Card") {
      setPanCardImage(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (
        aadharCardNumberError ||
        panCardNumberError ||
        !aadhaarCardNumber ||
        !panCardNumber ||
        !aadhaarCardImage ||
        !panCardImage
      ) {
        if (!aadhaarCardNumber) {
          setAadharCardNumberError("Aadhar number is required");
        }
        if (!panCardNumber) {
          setPanCardNumberError("PAN card number is required");
        }
        if (!aadhaarCardImage) {
          setAadharCardImageError("Aadhar card image is required");
        }
        if (!panCardImage) {
          setPanCardImageError("PAN card image is required");
        }
        return;
      }

      const formData = new FormData();
      if (aadhaarCardImage) {
        formData.append("aadhaarCardImage", aadhaarCardImage);
      }
      if (panCardImage) {
        formData.append("panCardImage", panCardImage);
      }

      formData.append("aadhaarCardNumber", aadhaarCardNumber);
      formData.append("panCardNumber", panCardNumber);

      if (authHeader) {
        await saveWalletCredentials(formData, authHeader);
      }
      history("/payment/payment-options");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  const validateAadharNumber = (aadharNumber: string) => {
    const isDigitsOnly = /^\d+$/.test(aadharNumber);
    const isLengthValid = aadharNumber.length === 12;

    if (!isDigitsOnly || !isLengthValid) {
      setAadharCardNumberError("Aadhar number must contain only 12 digits");
      return false;
    } else {
      setAadharCardNumberError("");
      return true;
    }
  };
  const validatePanCardNumber = (panCardNumber: string) => {
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
    const isFormatValid = panRegex.test(panCardNumber);

    if (!isFormatValid) {
      setPanCardNumberError(
        "PAN card number should be in the format ABCDE1234F"
      );
      return false;
    } else {
      setPanCardNumberError("");
      return true;
    }
  };
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "600px",
          border: "2px solid #eee ",
          borderRadius: "10px",
          padding: "25px",
          marginTop: "25px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
          gutterBottom
        >
          Upload Your Personal Documents
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: "#717171" }} variant="h6">
              Aadhar Card Number:
            </Typography>
            <TextField
              size="small"
              value={aadhaarCardNumber}
              onChange={(e) => handleFormChange(e, "aadharCardNumber")}
              error={!!aadharCardNumberError}
            />
            {aadharCardNumberError && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                }}
              >
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {aadharCardNumberError}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <HandleImageQuestion
              question="Upload Aadhar Card"
              onFileSelected={handleAadharFileSelected}
              handleFileRemove={handleFileRemove}
              disabled={false}
            />
            {aadharCardImageError && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                }}
              >
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {aadharCardImageError}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: "#717171" }} variant="h6">
              PAN Card Number:
            </Typography>
            <TextField
              size="small"
              type="text"
              name="panCardNumber"
              value={panCardNumber}
              onChange={(e) => handleFormChange(e, "panCardNumber")}
              error={!!panCardNumberError}
            />
            {panCardNumberError && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                }}
              >
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {panCardNumberError}
                </span>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <HandleImageQuestion
              question="Upload PAN Card"
              onFileSelected={handlePanCardFileSelected}
              handleFileRemove={handleFileRemove}
              disabled={false}
            />
            {panCardImageError && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                }}
              >
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {panCardImageError}
                </span>
              </div>
            )}
          </Grid>
        </Grid>
        {!isMobileView && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        )}

        {isMobileView && (
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 5px",
              backgroundColor: "white",
            }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              sx={{ padding: "5px", marginLeft: "5px", borderRadius: "5px" }}
            >
              Submit
            </Button>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default AadharPanImageForm;
