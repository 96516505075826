import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import "../../App.css";
import { useAuthContext } from "../../providers/AuthProvider";
import { CategoryGigListDetails } from "./CategoryGigListing";
import { getBookmarkDetails } from "@gogig-in/gogig-clients";
import CategoryGigItem from "../CategoryGigItem";

interface BookmarkData {
  bookmarkId: string;
  gigId: string;
}

interface CategoryGigsListProps {
  categoryGigs: CategoryGigListDetails[];
}

const CategoryGigsList: React.FC<CategoryGigsListProps> = ({
  categoryGigs,
}) => {
  const { authHeader, isAuthenticated } = useAuthContext();
  const [bookmarkData, setBookmarkData] = useState<BookmarkData[]>([]);
  const initialGigsToShow = 4;
  const [gigsToShow, setGigsToShow] = useState(initialGigsToShow);
  const [allGigsShown, setAllGigsShown] = useState(false);

  const handleShowMore = () => {
    setGigsToShow(categoryGigs.length);
    setAllGigsShown(true);
  };

  const fetchAndSetBookmark = async (authHeader: string) => {
    try {
      const bookmarkResponse: any = await getBookmarkDetails(authHeader);
      setBookmarkData(bookmarkResponse.data);
    } catch (e) {
      console.error("error during setting the bookmark data",e);
    }
  };

  useEffect(() => {
    authHeader && isAuthenticated && fetchAndSetBookmark(authHeader);
  }, [authHeader, isAuthenticated]);

  if (!categoryGigs || !Array.isArray(categoryGigs)) {
    return <Typography>No gigs available</Typography>;
  }

  return (
    <Grid container spacing={2} mt={"4px"}>
      {categoryGigs.slice(0, gigsToShow).map((categoryGig) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          key={categoryGig.gigTitle}
          sx={{ pt: 0 }}
        >
          <CategoryGigItem
            categoryGig={categoryGig}
            bookmarkData={bookmarkData}
          />
        </Grid>
      ))}
      {categoryGigs.length > initialGigsToShow && !allGigsShown && (
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleShowMore}>
            Show More Gigs
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default CategoryGigsList;
