
import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";

interface CheckboxGroupProps {
  question: string;
  questionId: string;
  answers: Record<string, string[]>; // Assuming answers is an object with string array values
  handleAnswerChange: (questionId: string, selectedOptions: string[]) => void;
  options: string[];
}

const HandleMultipleChoiceQuestion: React.FC<CheckboxGroupProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  options,
}) => {
  return (
    <FormControl component="fieldset">
      <Typography sx={{fontWeight:'bold'}}>
        {question}
        </Typography>
    <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={(answers[questionId] || []).includes(option)}
              onChange={(e) => {
                const currentAnswer = answers[questionId] || [];
                const selectedOptions = [...currentAnswer];
  
                if (e.target.checked) {
                  selectedOptions.push(option);
                } else {
                  const optionIndex = selectedOptions.indexOf(option);
                  if (optionIndex !== -1) {
                    selectedOptions.splice(optionIndex, 1);
                  }
                }
  
                handleAnswerChange(questionId, selectedOptions);
              }}
              name={`question_${questionId}_${option}`}
            />
          }
          label={option}
        />
      ))}
    </FormGroup>
  </FormControl>
  
  );
};

export default HandleMultipleChoiceQuestion;
