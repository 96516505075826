import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Avatar,
  TablePagination,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import getAuditFunction from "../../clients/getAuditRequest";
import { useAuthContext } from "../../providers/AuthProvider";
import dateFormat from "dateformat";

interface FileData {
  name: string;
  uploadDate: string;
  uploadTime: string;
  resultUrl?: string;
  pptUrl: string;
  sheetUrl: string;
  processorName: string;
  requesterName: string;
}

const Dashboard: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { authHeader } = useAuthContext();
  const [fileData, setFileData] = useState<FileData[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          const response: any = await getAuditFunction(authHeader)
          const data = response.data.map((file: any) => {
            return {
              name: file.gogigRequestId, // Or another appropriate field
              uploadDate: dateFormat(file.createdAt, 'dd-mm-yyyy'),
              uploadTime: dateFormat(file.createdAt, 'hh:MM TT'),
              resultUrl: file.resultUrl,
              pptUrl: file.requestedPptUrl,
              sheetUrl: file.requestedSheetUrl,
              processorName: file.processorName,
              requesterName: file.requesterName,
            };
          });
          setFileData(data);
        }
      } catch (e) {
        console.error("Error during getting Audit Function", e);
      }
    })();
  }, [authHeader]);

  // Sort files by upload date (most recent first)
  const sortedFilesData = [...fileData].sort(
    (a, b) =>
      new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime()
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getFileStatus = (file: FileData) => {
    return file.resultUrl ? "Completed" : "Processing";
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Uploaded Files
        </Typography>
        {isMobile ? (
          <Grid container spacing={2}>
            {sortedFilesData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file, index) => (
                <Grid item xs={12} key={index}>
                  <Card variant="outlined" elevation={5}>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>
                            <InsertDriveFileIcon />
                          </Avatar>
                          <Box>
                            <Typography variant="body2" flexGrow={1} fontWeight={'bold'}>
                              {file.name}
                            </Typography>
                            <Typography variant="body2">
                              {file.uploadDate}
                            </Typography>
                            <Typography variant="body2">
                              {file.uploadTime}
                            </Typography>
                          </Box>
                        </Box>
                        <Box textAlign="right">
                          <Typography
                            variant="body2"
                            color={
                              getFileStatus(file) === "Completed"
                                ? "green"
                                : "orange"
                            }
                            mb={1}
                          >
                            {getFileStatus(file)}
                          </Typography>
                          <Button
                            variant={file.resultUrl ? "contained" : "outlined"}
                            color={file.resultUrl ? "primary" : "secondary"}
                            size="small"
                            startIcon={<DownloadIcon />}
                            {...(file.resultUrl && {
                              href: file.resultUrl,
                              target: "_blank",
                              download: true,
                            })}
                            disabled={!file.resultUrl}
                          >
                            {file.resultUrl ? "Download" : "Unavailable"}
                          </Button>

                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell>Upload Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reviewer</TableCell>
                  <TableCell align="center">Download Report</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedFilesData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>
                            <InsertDriveFileIcon />
                          </Avatar>
                          <Typography variant="body2" fontWeight={'bold'}>{file.name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{file.uploadDate}</TableCell>
                      <TableCell>{file.uploadTime}</TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color={
                            getFileStatus(file) === "Completed"
                              ? "green"
                              : "orange"
                          }
                        >
                          {getFileStatus(file)}
                        </Typography>
                      </TableCell>
                      <TableCell>{file.processorName}</TableCell>
                      <TableCell align="center">
                        {file.resultUrl ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            startIcon={<DownloadIcon />}
                            href={file.resultUrl}
                            download
                            target="_blank"
                          >
                            Download
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="medium"
                            disabled
                          >
                            Processing...
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedFilesData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;

