import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Typography } from "@mui/material";

interface HandleBooleanQuestionProps {
  question: string;
  questionId: string;
  answers: Record<string, string>; // Assuming answers is an object with string values
  handleAnswerChange: (questionId: string, value: string) => void;
  disabled: boolean;
}

const HandleBooleanQuestion: React.FC<HandleBooleanQuestionProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  disabled,
}) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h6">{question}</Typography>
      </FormLabel>
      <RadioGroup
        name={`question_${questionId}`}
        value={answers[questionId] || ""}
        onChange={(e) => handleAnswerChange(questionId, e.target.value)}
      >
        <FormControlLabel disabled={disabled} value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel disabled={disabled} value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

export default HandleBooleanQuestion;
