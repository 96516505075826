import { Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import { useAuthContext } from "../../providers/AuthProvider";
import { getUserAddress } from "@gogig-in/gogig-clients";
import ProfileCard from "./ProfileCard";
import SetUserAddressPopup from "../vendorDetail/SetUserAddressPopup";

const UserAddressData = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const { authHeader } = useAuthContext();
  const [cityName, setCityName] = useState<string>("");
  const [areaName, setAreaName] = useState<string>("");
  const [zipCode, setZipCode] = useState<number | string>();

  const snackbar = (message: string, severity: Severity) => {
    setOpenSnackbar(true);
    setSnackbarData({ message, severity });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const fetchUserDocument = useCallback(async () => {
    setLoading(true);
    try {
      if (authHeader) {
        const response: any = await getUserAddress(authHeader);
        const pinCode: string = response.data.pincode;
        const city: string = response.data.workLocation;
        const area: string = response.data.area;
        setZipCode(pinCode);
        setCityName(city);
        setAreaName(area);
      }
    } catch (error: any) {
      if (error.status === 401) {
        snackbar("Unauthorized access. Please log in again", "error");
      } else {
        console.error(
          "An error occurred while fetching user capabilities:",
          error
        );
      }
    } finally {
      setLoading(false);
    }
  }, [authHeader]);

  useEffect(() => {
    if (authHeader) {
      fetchUserDocument();
    }
  }, [authHeader, fetchUserDocument]);

  const handleDialogClose = async () => {
    setOpenDialog(false);
    await fetchUserDocument();
  };

  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        severity={snackbarData.severity}
        vertical={"top"}
        horizontal={"center"}
      />
      <ProfileCard
        title="Address"
        editTooltipText="Edit Address"
        onDialogOpen={handleDialogOpen}
        isLoading={isLoading}
      >
        <Grid container>
          {areaName ? (
            <Grid item xs={12}>
              <Typography textAlign={"start"}>
                <Typography component={"span"} fontWeight="bold">
                  My City :
                </Typography>{" "}
                {areaName}, {cityName}, {zipCode}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                No Address details found
              </Typography>
            </Grid>
          )}
        </Grid>
      </ProfileCard>
      <SetUserAddressPopup
        openDialog={openDialog}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default UserAddressData;
