import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  InputAdornment,
  IconButton,
  Card,
} from "@mui/material";
import { Alert } from "@mui/material";
import { savePin } from "@gogig-in/gogig-clients";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthContext } from "../../providers/AuthProvider";

interface WithdrawalPinContainerProps {
  onClose?: () => void;
}

const CreatePin: React.FC<WithdrawalPinContainerProps> = ({ onClose }) => {
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [showPin, setShowPin] = useState<boolean>(false);
  const [confirmShowPin, setConfirmShowPin] = useState<boolean>(false);
  const { authHeader } = useAuthContext();
  const [pinError, setPinError] = useState<string>("");
  const [confirmPinError, setConfirmPinError] = useState<string>("");

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 6);

    if (!/^\d{6}$/.test(value)) {
      setPinError("Please enter 6 digit PIN");
    } else {
      setPinError("");
    }

    setPin(value);
  };

  const handleConfirmPinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 6);

    if (!/^\d{6}$/.test(value)) {
      setConfirmPinError("Please enter 6 digit PIN");
    } else if (value !== pin) {
      setConfirmPinError("PINs do not match");
    } else {
      setConfirmPinError("");
    }

    setConfirmPin(value);
  };

  const handleSetPin = async () => {
    try {
      if (!confirmPinError && authHeader) {
        setLoading(true);
        const PIN = {
          pin: btoa(confirmPin),
        };
        await savePin(PIN, authHeader);
        setSnackbarMessage("Withdrawal PIN set successfully!");
        setSnackbarOpen(true);
        if (onClose) {
          onClose();
        }
      }
    } catch (error) {
      setSnackbarMessage("Error setting withdrawal PIN. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleToggleShowPin = () => {
    setShowPin(!showPin);
  };

  const handleToggleConfirmShowPin = () => {
    setConfirmShowPin(!confirmShowPin);
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Card
        sx={{
          maxWidth: 450,
          maxHeight: "600px",
          width: "100%",
          marginBottom: "20px",
          backgroundColor: "background.default",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography
          variant="h3"
          sx={{ marginBottom: "10px", marginTop: "25px" }}
        >
          Set Withdrawal PIN
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          This PIN will be used during the Withdrawal, so do not share the PIN
          with anyone.
        </Typography>
        <TextField
          label="Enter PIN(6-digits)"
          variant="outlined"
          margin="normal"
          type={showPin ? "text" : "password"}
          value={pin}
          onChange={handlePinChange}
          error={!!pinError}
          helperText={pinError}
          sx={{ padding: "5px" }}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleShowPin} edge="end">
                  {showPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirm PIN(6-digits)"
          variant="outlined"
          margin="normal"
          type={confirmShowPin ? "text" : "password"}
          value={confirmPin}
          onChange={handleConfirmPinChange}
          error={!!confirmPinError}
          helperText={confirmPinError}
          autoComplete="off"
          sx={{ padding: "5px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleConfirmShowPin} edge="end">
                  {confirmShowPin ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSetPin}
            disabled={loading || !confirmPin || !!pinError || !!confirmPinError}
            sx={{ m: 2 }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Set PIN"
            )}
          </Button>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="info">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Card>
    </Container>
  );
};

export default CreatePin;
