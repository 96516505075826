import React from "react";
import CustomButton from "./ButtonWrapper";
import { useNavigate } from "react-router-dom";

const SignUpButton = () => {
  const buttonStyle = {
    textTransform: "none",
  };
  const history = useNavigate();

  const handleSignUp = () => {
    history("/user-entry/signup");
  };

  return (
    <CustomButton
      tag="Signup"
      variant="outlined"
      sx={buttonStyle}
      onClick={handleSignUp}
    >
      Login / Sign up
    </CustomButton>
  );
};
export default SignUpButton;
