import React from "react";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Typography } from "@mui/material";

interface RadioGroupProps {
  question: string;
  questionId: string;
  answers: Record<string, string>;
  handleAnswerChange: (questionId: string, value: string) => void;
  options: string[];
}

const HandleSingleChoiceQuestions: React.FC<RadioGroupProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  options,
}) => {
  return (
    <FormControl component="fieldset">
              <Typography sx={{fontWeight:'bold'}}>
{question}</Typography>
      {" "}
      <RadioGroup
        name={`question_${questionId}`}
        value={answers[questionId] || ""}
        onChange={(e) => handleAnswerChange(questionId, e.target.value)}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default HandleSingleChoiceQuestions;
