import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dateFormat from "dateformat";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ProfilePictureComponent from "./ProfilePictureComponent";
import ProfileCard from "./ProfileCard";
import EditProfilePopup from "../ProfileEditComponents/EditProfilePopup";
import { useAuthContext } from "../../providers/AuthProvider";
import { userRoles } from "../../types/userRoles";

export default function UserProfileData() {
  const [openDialog, setOpenDialog] = useState(false);
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { userDetails } = useAuthContext();

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    // Check if userDetails exists and userDetails.dob exists
    // If userDetails or userDetails.dob is null or undefined, set dob to an empty string
    const dob =
      userDetails && userDetails.dob
        ? dateFormat(userDetails.dob, "dd-mm-yyyy")
        : "";
    // Set isLoading to false when userDetails is not null
    const isLoading = userDetails !== null ? false : true;
    setDob(dob);
    setIsLoading(isLoading);
  }, [userDetails?.dob, setIsLoading, userDetails]);
  return (
    <>
      <EditProfilePopup open={openDialog} onClose={handleDialogClose} />
      <ProfileCard
        title={userDetails?.role===userRoles.external?"Company Profile":"Profile"}
        editTooltipText="Edit Profile"
        onDialogOpen={handleDialogOpen}
        isLoading={isLoading}
      >
        <Box>
          <ProfilePictureComponent />
        </Box>
        <Typography variant="h3">
          {userDetails?.firstName + " " + userDetails?.lastName}
        </Typography>

        {/* PHONE NUMBER */}
        <Box
          sx={{
            display: "flex",
          }}
        >
          <PhoneOutlinedIcon fontSize="small" />
          <Typography ml={1}>{userDetails?.phoneNumber}</Typography>
          {userDetails?.phoneVerified === 1 ? (
            <CheckCircleIcon color="success" fontSize="small" />
          ) : (
            <CancelRoundedIcon color="error" fontSize="small" />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {/* EMAIL */}
          <EmailOutlinedIcon fontSize="small" />
          <Typography ml={1}>{userDetails?.emailId}</Typography>
        </Box>
        {/* GENDER & DOB */}
        <Box
          flexWrap={"wrap"}
          sx={{
            display: "flex",
            marginTop: "2px",
          }}
        >
          {userDetails?.gender === "male" ? (
            <MaleIcon fontSize="small" />
          ) : (
            <FemaleIcon fontSize="small" />
          )}
          <Typography ml={1}>
            {userDetails?.gender?.toLocaleUpperCase()}
          </Typography>
          <Typography component="span" mx={2}>
            |
          </Typography>
          <CakeOutlinedIcon fontSize="small" />
          <Typography ml={1}>{dob}</Typography>
        </Box>
      </ProfileCard>
    </>
  );
}
