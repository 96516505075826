import Strings from "../../Locale/strings.json";
import { NavigationItem } from "./types";

export const signedInUserNavigation: NavigationItem[] = [
  {
    label: Strings.Header.Home,
    path: "/home",
  },
  {
    label: "Find Work",
    path: "/explore-categories",
  },
  
  {
    label: "My Dashboard",
    path: "/task/gigHistory",
  },

  {
    label: "About Us",
    path: "/about",
  },
  {
    label: "FAQ",
    path: "/faq",
  },
];
