import { Box, Typography } from "@mui/material";
import { Capability } from "@gogig-in/gogig-clients";

interface CapabilitiesViewProps {
  label: string;
  capabilities: Capability[];
}

export default function CapabilitiesView({
  label,
  capabilities,
}: CapabilitiesViewProps) {
  return (
    <Box>
      <Typography variant="h5" fontWeight={700}>
        {label}
      </Typography>
      {capabilities.map((capability, index) => (
        <Typography key={index} sx={{ display: "flex" }}>
          {capability.capabilityName}
        </Typography>
      ))}
    </Box>
  );
}
