import { useState } from "react";

export const LocationMapper = () => {
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  if (!latitude || !longitude) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position: any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLatitude(latitude);
    setLongitude(longitude);
    localStorage.setItem("location_lat", latitude);
    localStorage.setItem("location_long", longitude);
  }
  function error() {
    console.error("Unable to retrieve your location");
  }
};
