import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ReactNode } from "react";
import SkeletonModal from "../skeleton/SkeletonModal";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

interface ProfileCardProps {
  children: ReactNode;
  title: string;
  editTooltipText: string;
  isLoading: boolean;
  onDialogOpen: () => void;
}

export default function ProfileCard({
  children,
  title,
  editTooltipText,
  isLoading,
  onDialogOpen,
}: ProfileCardProps) {
  return (
    <Grid>
      {isLoading ? (
        <>
          <Paper>
            <SkeletonModal skeletonType="dashboard" />
          </Paper>
        </>
      ) : (
        <>
          <Paper
            sx={{
              marginTop: "16px",
              border: "1px solid #ddd",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0px 4px 8px rgba(0,0,0,0.3)",
              backgroundColor: "background.default",
              textAlign: "left",
            }}
          >
            <Grid container item xs={12}>
              <Grid item xs={11} style={{ display: "flex" }}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    float: "right",
                    cursor: "pointer",
                  }}
                  variant="h4"
                  align="right"
                >
                  <Tooltip title={editTooltipText}>
                    <IconButton onClick={onDialogOpen}>
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Box mt={2}>{children}</Box>
          </Paper>
        </>
      )}
    </Grid>
  );
}
