import { fetchWrapper } from "@gogig-in/gogig-clients";
import { ApiBaseUrl } from "./baseUrls";

export default function getAuditFunction(authHeader: string) {
  return fetchWrapper(`${ApiBaseUrl}/getAuditRequest`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
  });
}
