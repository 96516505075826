import React, { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoading from "./Loading";

interface ModalProps {
  open: boolean;
  isLoading: boolean;
  title: string;
  children: ReactNode;
  primaryActionOnclick?: () => void;
  primaryActionText?: string;
  secondaryActionOnclick?: () => void;
  secondaryActionText?: string;
  onClose: () => void;
}

const CustomModal = ({
  open,
  isLoading,
  title,
  children,
  primaryActionText,
  secondaryActionText,
  primaryActionOnclick,
  secondaryActionOnclick,
  onClose,
}: ModalProps) => {
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>{isLoading ? <CustomLoading /> : children}</DialogContent>
      <DialogActions>
        {secondaryActionText && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={secondaryActionOnclick}
          >
            {secondaryActionText}
          </Button>
        )}
        {primaryActionText && (
          <Button
            color="primary"
            variant="contained"
            onClick={primaryActionOnclick}
            fullWidth
          >
            {primaryActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
