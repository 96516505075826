import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { UserDocument } from "./UserDocumentTypes";
import { useAuthContext } from "../../providers/AuthProvider";
import CustomLoading from "../custom/Loading";
import { HandleImageQuestion } from "../vendorDetail";
import {
  getWalletCredentials,
  saveWalletCredentials,
} from "@gogig-in/gogig-clients";

interface AadharPanImageFormProps {
  onCloseDialog: () => void;
}

const AadharPanImageForm: React.FC<AadharPanImageFormProps> = ({
  onCloseDialog,
}) => {
  const { authHeader } = useAuthContext();
  const [aadharCardNumber, setAadharCardNumber] = useState("");
  const [panCardNumber, setPanCardNumber] = useState("");
  const [aadharCardImage, setAadharCardImage] = useState<File | null>(null);
  const [panCardImage, setPanCardImage] = useState<File | null>(null);
  const [aadharCardNumberError, setAadharCardNumberError] =
    useState<string>("");
  const [panCardNumberError, setPanCardNumberError] = useState<string>("");
  const [panCardImageError, setPanCardImageError] = useState<string>("");
  const [aadharCardImageError, setAadharCardImageError] = useState<string>("");
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<UserDocument | null>(null);

  const handleAadharFileSelected = (file: File, question: string) => {
    setAadharCardImage(file);
    setAadharCardImageError("");
  };
  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string
  ) => {
    const { value } = e.target;

    switch (fieldName) {
      case "aadharCardNumber":
        setAadharCardNumber(value);
        setAadharCardNumberError(
          validateAadharNumber(value) ? "" : "Aadhar number should be 12 digits"
        );
        break;
      case "panCardNumber":
        setPanCardNumber(value.toUpperCase());
        setPanCardNumberError(
          validatePanCardNumber(value.toUpperCase())
            ? ""
            : "PAN card number should be in the format ABCDE1234F"
        );
        break;

      default:
        break;
    }
  };

  const handlePanCardFileSelected = (file: File, question: string) => {
    setPanCardImage(file);
    setPanCardImageError("");
  };

  useEffect(() => {
    const fetchDocumentsData = async () => {
      try {
        setLoading(true);
        if (authHeader) {
          const response: any = await getWalletCredentials(authHeader);
          const data = response.data[0]; // Specify the expected type here
          setFetchedData(data);
          setLoading(false);
        }
      } catch (e) {
        console.error("Error during getWalletCredentials", e);
      } finally {
        setLoading(false);
      }
    };
    if (authHeader) {
      fetchDocumentsData();
    }
  }, [authHeader]);
  const handleFileRemove = (question: string) => {
    if (question === "Upload Aadhar Card") {
      setAadharCardImage(null);
    } else if (question === "Upload PAN Card") {
      setPanCardImage(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (
        aadharCardNumberError ||
        panCardNumberError ||
        !aadharCardNumber ||
        !panCardNumber ||
        !aadharCardImage ||
        !panCardImage
      ) {
        if (!aadharCardNumber) {
          setAadharCardNumberError("Aadhar number is required");
        }
        if (!panCardNumber) {
          setPanCardNumberError("PAN card number is required");
        }
        if (!aadharCardImage) {
          setAadharCardImageError("Aadhar card image is required");
        }
        if (!panCardImage) {
          setPanCardImageError("PAN card image is required");
        }
        return;
      }

      const formData = new FormData();
      if (aadharCardImage) {
        formData.append("aadhaarCardImage", aadharCardImage);
      }
      if (panCardImage) {
        formData.append("panCardImage", panCardImage);
      }

      formData.append("aadhaarCardNumber", aadharCardNumber);
      formData.append("panCardNumber", panCardNumber);

      if (authHeader) {
        await saveWalletCredentials(formData, authHeader);
        setLoading(false);
      }
      onCloseDialog();
    } catch (error) {
      console.error("Error during form submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateAadharNumber = (aadharNumber: string) => {
    const isDigitsOnly = /^\d+$/.test(aadharNumber);
    const isLengthValid = aadharNumber.length === 12;

    if (!isDigitsOnly || !isLengthValid) {
      setAadharCardNumberError("Aadhar number must contain only 12 digits");
      return false;
    } else {
      setAadharCardNumberError("");
      return true;
    }
  };
  const validatePanCardNumber = (panCardNumber: string) => {
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
    const isFormatValid = panRegex.test(panCardNumber);

    if (!isFormatValid) {
      setPanCardNumberError(
        "PAN card number should be in the format ABCDE1234F"
      );
      return false;
    } else {
      setPanCardNumberError("");
      return true;
    }
  };
  return (
    <>
      {isLoading ? (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "200px",
            minWidth: "400px",
          }}
        >
          <CustomLoading />
        </Container>
      ) : (
        <Paper sx={{ display: "flex", justifyContent: "center" }} elevation={0}>
          {fetchedData ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  Aadhar Details
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    border: "1px solid #000000",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={fetchedData.aadhaarCardImage}
                    alt="Aadhaar Card"
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <span
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Aadhar Number:
                  </span>{" "}
                  {fetchedData.aadhaarCardNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  PAN Card Details
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    border: "1px solid #000000",
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={fetchedData.panCardImage}
                    alt="PAN Card"
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <span
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    PAN Number:
                  </span>{" "}
                  {fetchedData.panCardNumber}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                maxWidth: "600px",
                border: isMobileView ? "" : "2px solid #eee ",
                borderRadius: "10px",
                padding: "25px",
                marginTop: "25px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "#717171" }} variant="h6">
                    Aadhar Card Number:
                  </Typography>
                  <TextField
                    size="small"
                    value={aadharCardNumber}
                    onChange={(e) => handleFormChange(e, "aadharCardNumber")}
                    error={!!aadharCardNumberError}
                    autoComplete="off"
                  />
                  {aadharCardNumberError && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                      }}
                    >
                      <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                      <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                        {aadharCardNumberError}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <HandleImageQuestion
                    question="Upload Aadhar Card"
                    onFileSelected={handleAadharFileSelected}
                    handleFileRemove={handleFileRemove}
                    disabled={false}
                  />
                  {aadharCardImageError && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                      }}
                    >
                      <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                      <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                        {aadharCardImageError}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "#717171" }} variant="h6">
                    PAN Card Number:
                  </Typography>
                  <TextField
                    size="small"
                    type="text"
                    name="panCardNumber"
                    value={panCardNumber}
                    onChange={(e) => handleFormChange(e, "panCardNumber")}
                    error={!!panCardNumberError}
                    autoComplete="off"
                  />
                  {panCardNumberError && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                      }}
                    >
                      <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                      <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                        {panCardNumberError}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <HandleImageQuestion
                    question="Upload PAN Card"
                    onFileSelected={handlePanCardFileSelected}
                    handleFileRemove={handleFileRemove}
                    disabled={false}
                  />
                  {panCardImageError && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                      }}
                    >
                      <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                      <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                        {panCardImageError}
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
              {!isMobileView && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 6,
                  }}
                >
                  <Button variant="contained" onClick={handleSubmit} fullWidth>
                    Submit
                  </Button>
                </Box>
              )}

              {isMobileView && (
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 5px",
                    backgroundColor: "white",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                    sx={{
                      padding: "5px",
                      marginLeft: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Box>
          )}
        </Paper>
      )}
    </>
  );
};

export default AadharPanImageForm;
