import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { datasetUpload } from "@gogig-in/gogig-clients";
import ErrorIcon from "@mui/icons-material/Error";
import PageTitleComponent from "../PageTitleComponent";
import { useAuthContext } from "../../providers/AuthProvider";

const CsvFilePreview: React.FC = () => {
  const location = useLocation();
  const { datasetName } = location.state || {};
  const [fileContent, setFileContent] = useState<string[][]>([]);
  const [fileName, setFileName] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Store selected file
  const { authHeader } = useAuthContext();
  const { gigId } = useParams<{ gigId?: string }>();
  const history = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const redirectToDashboard = () => {
    history(`/manage-gigs/${gigId}`, {
      state: { datasetName: datasetName },
    });
  };
  const handleDialogClose = () => {
    setDialogOpen(false); // Close the dialog
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files) {
      const file = fileInput.files[0];
      setFileName(file.name);
      setSelectedFile(file); // Store the selected file
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target?.result) {
          const content: string = e.target.result.toString();
          const lines: string[] = content.split("\n");

          const previewData: string[][] = lines.map((line) => {
            const cells: string[] = [];
            let cell = "";
            let insideQuotes = false;

            for (let i = 0; i < line.length; i++) {
              const char = line[i];
              if (char === '"') {
                insideQuotes = !insideQuotes;
              } else if (char === "," && !insideQuotes) {
                cells.push(cell.trim());
                cell = "";
              } else {
                cell += char;
              }
            }

            cells.push(cell.trim()); // Add the last cell
            return cells;
          });

          setFileContent(previewData);
        }
      };

      reader.readAsText(file);
    }
  };

  const handleSubmit = async () => {
    if (gigId && fileName && selectedFile) {
      try {
        if (authHeader) {
          const formData = new FormData();
          formData.append("file", selectedFile);
          await datasetUpload(authHeader, formData, datasetName, gigId);
          setDialogOpen(true);
        }
      } catch (error) {
        console.error("Error during datasetUpload",error);
      }
    } else {
      console.error("No file selected");
    }
  };

  return (
    <Container>
      <PageTitleComponent title="Upload File" subTitle={datasetName} />
      <Box sx={{ display: "flex", alignItems: "baseline" }}>
        <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
        <Typography sx={{ color: "red", marginLeft: "5px" }}>
          You have the ability to add new rows of data. However, it's important
          to be aware that the removal of existing rows can only be performed by
          the support team, and you do not have the capability to delete any
          rows yourself.
        </Typography>
      </Box>

      <input type="file" accept=".csv" onChange={handleFileChange} />
      <Button onClick={handleSubmit}>Upload</Button>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "300px",
          maxWidth: "80vw",
        }}
      >
        <table
          style={{
            borderCollapse: "collapse",
            width: "auto",
            minWidth: "100%",
          }}
        >
          <tbody>
            {fileContent.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{ border: "1px solid #ccc", padding: "8px" }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogContent>
            <Typography>File is succeessfully uploaded</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={redirectToDashboard}>Go Back To Dashboard</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Container>
  );
};

export default CsvFilePreview;
