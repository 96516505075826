import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Container,
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import CustomSnackbar from "../operationRolePages/CustomSnackbar";
import { Wallet } from "@mui/icons-material";
import MDEditor from "@uiw/react-md-editor";
import CustomButton from "../ButtonWrapper";
import {
  CalendarTodayOutlined as CalendarIcon,
  TimerOutlined as TimerIcon,
  WorkOutlineOutlined as WorkIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import Strings from "./../../Locale/strings.json";
import {
  Capability,
  getAcceptedGigsByUser,
  getGigCapabilitiesByGigId,
  getGigDetailsByGigId,
} from "@gogig-in/gogig-clients";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Map from "@mui/icons-material/MapOutlined";
import FilterIcon from "@mui/icons-material/FilterNoneOutlined";
import GigInviteForm from "../GigForm/GigInviteForm";

const AcceptGigInvitationPage = () => {
  const { supervisorId } = useParams<{ supervisorId: string }>();
  const { gigId } = useParams<{ gigId: string }>();
  const { authHeader } = useAuthContext();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [gigDetails, setGigDetails] = useState<any>(null);
  const [gigCapabilities, setGigCapabilities] = useState<Capability[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const [isAcceptedGig, setIsAcceptedGig] = useState(false);
  const [visibilityId, setVisibilityId] = useState<string>();

  const titleStyle = {
    marginBottom: "0.5rem",
  };

  const handleAcceptInvitation = async () => {
    if (authHeader) {
      if (supervisorId && gigId) {
        setIsDialogOpen(true);
      }
    } else {
      navigate("/user-entry/signup", {
        state: {
          supervisorId: supervisorId,
          gigId: gigId,
        },
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchGigDetails = async () => {
      try {
        if (gigId) {
          const response: any = await getGigDetailsByGigId(gigId);
          setGigDetails(response.data[0]);
          const gigVisibilityId = gigDetails.visibilityId;
          setVisibilityId(gigVisibilityId);
          setIsDialogOpen(false);
        }
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };
    fetchGigDetails(); // eslint-disable-next-line
  }, [gigId]);

  useEffect(() => {
    const getAcceptedGigs = async () => {
      try {
        if (authHeader && gigId) {
          const acceptedGigs = await getAcceptedGigsByUser(authHeader);
          const isAccepted = acceptedGigs.some((gig) => gig.gigId === gigId);
          setIsAcceptedGig(isAccepted);
        }
      } catch (error) {
        console.error("Error fetching accepted gigs", error);
      }
    };
    getAcceptedGigs();
  }, [authHeader, gigId]);

  useEffect(() => {
    const fetchCapabilityData = async () => {
      try {
        if (gigId) {
          const res: any = await getGigCapabilitiesByGigId(gigId);
          setGigCapabilities(res.data);
        }
      } catch (e) {
        console.error("error during fetching capabilities", e);
      }
    };
    fetchCapabilityData();
  }, [gigId]);

  const handleCopyClick = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        handleSnackbar("success", "Link copied.");
      })
      .catch((error) => {
        console.error("Copy failed:", error);
      });
  };

  const handleWhatsAppShare = async () => {
    const urlToShare = window.location.href;
    try {
      await navigator.clipboard.writeText(urlToShare);
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        urlToShare
      )}`;
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const showGigSkills = gigCapabilities
    .filter((s) => s.capabilityType === "skill")
    .map((s) => s.capabilityName)
    .join(", ");
  const showGigAssets = gigCapabilities
    .filter((s) => s.capabilityType === "asset")
    .map((s) => s.capabilityName)
    .join(", ");

  const fontAsPerMd =
    "apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji";

  return (
    <Container>
      {gigDetails && (
        <>
          <Typography variant="h4" style={titleStyle}>
            Accept Gig Invitation
          </Typography>
          <Box sx={{ marginTop: "35px" }}>
            {gigDetails && (
              <Grid
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Grid item xs={12} sm={7.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-10px",
                    }}
                  >
                    {gigDetails?.companyLogo ? (
                      <img
                        src={gigDetails.companyLogo}
                        alt={gigDetails.companyName}
                        style={{
                          maxWidth: "132px",
                          height: "60px",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/logos/gmg-logo.png`}
                        alt="company Logo"
                        style={{
                          width: "132px",
                          marginRight: "10px",
                        }}
                      />
                    )}

                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          lineHeight: "1.2",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        {gigDetails.gigTitle}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "5px",
                          fontFamily: "Inter, sans-serif",
                          marginTop: "5px",
                          "@media (min-width: 600px)": {
                            flexDirection: "row",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: "5px",
                            marginRight: "15px",
                            textTransform: "capitalize",
                          }}
                        >
                          at {gigDetails.companyName}
                        </Typography>
                        <Chip label={gigDetails.jobType || "PART-TIME"} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {!isMobileView && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {isAcceptedGig ? (
                        <Button
                          variant="contained"
                          size="large"
                          endIcon={<ArrowForwardIcon />}
                          onClick={handleAcceptInvitation}
                        >
                          Accept Invitation
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={handleAcceptInvitation}
                          size="large"
                          endIcon={<ArrowForwardIcon />}
                        >
                          Apply & Accept
                        </Button>
                      )}
                      {isDialogOpen && supervisorId !== undefined && (
                        <GigInviteForm
                          supervisorId={supervisorId}
                          gigId={gigDetails.gigId}
                          gigTitle={gigDetails.gigTitle}
                          handleClose={() => setIsDialogOpen(false)}
                        />
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}

            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      flex: 1,
                      marginBottom: "20px",
                      padding: "20px 20px 0 20px",
                      backgroundColor: "background.default",
                      borderRadius: "8px",
                    }}
                  >
                    {gigDetails && (
                      <Box>
                        {showGigSkills && (
                          <>
                            <Typography
                              style={{
                                fontFamily: fontAsPerMd,
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "#18191C",
                              }}
                            >
                              Skills Required
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: fontAsPerMd,
                                fontWeight: "400",
                                fontSize: "16px",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {showGigSkills}
                            </Typography>
                          </>
                        )}
                        {showGigAssets && (
                          <>
                            <Typography
                              style={{
                                fontFamily: fontAsPerMd,
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "#18191C",
                              }}
                            >
                              Assets Required
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: fontAsPerMd,
                                fontWeight: "400",
                                fontSize: "16px",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {showGigAssets}
                            </Typography>
                          </>
                        )}
                        {gigDetails.communicationLanguages && (
                          <>
                            <Typography
                              style={{
                                fontFamily: fontAsPerMd,
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "#18191C",
                              }}
                            >
                              Languages to know
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: fontAsPerMd,
                                fontWeight: "400",
                                fontSize: "16px",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {gigDetails.communicationLanguages}
                            </Typography>
                          </>
                        )}
                        <Box
                          data-color-mode="light"
                          sx={{ textAlign: "justify" }}
                        >
                          <MDEditor.Markdown source={gigDetails.description} />
                        </Box>
                      </Box>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Paper
                    elevation={0}
                    sx={{
                      flex: 1,
                      marginBottom: "20px",
                      padding: "20px",
                      backgroundColor: "background.default",
                      borderRadius: "8px",
                      border: "2px solid #E7F0FA",
                    }}
                  >
                    {gigDetails && (
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              {Strings.GigDetails.Salary}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#0BA02C",
                                fontSize: "20px",
                                fontWeight: "500",
                              }}
                            >
                              {gigDetails.minWagePerDay !== null
                                ? Strings.INR + " " + gigDetails.minWagePerDay
                                : "hello world"}
                            </Typography>
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "16px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              {Strings.GigDetails.SalaryPeriod}
                              <br />
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              Earn
                            </Typography>
                            <Typography
                              sx={{
                                color: "#0BA02C",
                                fontSize: "20px",
                                fontWeight: "500",
                              }}
                            >
                              {gigDetails.pricePerClient !== null
                                ? Strings.INR + " " + gigDetails.pricePerClient
                                : "hello world"}
                            </Typography>
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "16px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              per valid entry
                              <br />
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              backgroundColor: "background.default",
                              color: "blue",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <Map color="primary" sx={{ marginRight: "10px" }} />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              <strong>
                                Job Location
                                <br />
                              </strong>
                            </Typography>
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "16px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              {gigDetails.location !== ""
                                ? gigDetails.location
                                : "India"}
                              <br />
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: "background.default",
                      borderRadius: "8px",
                      border: "2px solid #E7F0FA",
                    }}
                  >
                    <Box sx={{ margin: "20px" }}>
                      {gigDetails && (
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <CalendarIcon
                              sx={{ fontSize: "30px", color: "#0A65CC" }}
                            />
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "12px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              JOB STARTS FROM
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              {formatDate(gigDetails.gigStartDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <TimerIcon
                              sx={{ fontSize: "30px", color: "#0A65CC" }}
                            />
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "12px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              JOB EXPIRES IN
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              {formatDate(gigDetails.gigEndDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <FilterIcon
                              sx={{ fontSize: "30px", color: "#0A65CC" }}
                            />
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "12px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              JOB LEVEL
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              Entry Level
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Wallet
                              sx={{ fontSize: "30px", color: "#0A65CC" }}
                            />
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "12px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              EXPERIENCE
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              Not Applicable
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <WorkIcon
                              sx={{ fontSize: "30px", color: "#0A65CC" }}
                            />
                            <Typography
                              sx={{
                                color: "grey",
                                fontSize: "12px",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              EDUCATION
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                fontFamily: "inter, sans-serif",
                              }}
                            >
                              {gigDetails.academicQualificationRequired}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                    <Divider sx={{ marginY: "10px", width: "100%" }} />{" "}
                    {/* Full-width horizontal divider */}
                    <Box
                      sx={{
                        paddingLeft: "30px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        <strong>{Strings.GigDetails.ShareJob}</strong>
                      </Typography>
                      <CustomButton
                        tag="GigDetails/CopyLink"
                        startIcon={<LinkIcon />}
                        onClick={() => {
                          handleCopyClick();
                        }}
                      >
                        {Strings.ShareType.CopyLink}
                      </CustomButton>

                      <CustomButton onClick={handleWhatsAppShare}>
                        Share on whatsapp
                      </CustomButton>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
      {isMobileView && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 10px",
            backgroundColor: "white",
          }}
        >
          {isAcceptedGig ? (
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              sx={{ width: "73%" }}
            >
              Accept Invitation
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleAcceptInvitation}
              size="large"
              endIcon={<ArrowForwardIcon />}
              sx={{ width: "73%" }}
            >
              Accept & Apply
            </Button>
          )}
          {isDialogOpen && visibilityId !== undefined && (
            <GigInviteForm
              supervisorId={supervisorId}
              gigId={gigDetails.gigId}
              gigTitle={gigDetails.gigTitle}
              handleClose={() => setIsDialogOpen(false)} // Pass a function reference
            />
          )}
        </div>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default AcceptGigInvitationPage;
