import React, { useEffect} from "react";
import {
  List,
  Typography,
  Container,
  Box,
  useMediaQuery,
  Grid,
} from "@mui/material";
import NotificationCard from "./NotificationCard";
import { useAuthContext } from "../../providers/AuthProvider";
import { readNotifications } from "../../clients/readNotification";
import LoadingSpinner from "../LoadingAnimation";
import { useNotificationContext } from "../../providers/NotificationProvider";

export interface Notification {
  isRead: number;
  notificationBody: string;
  notificationId: number;
  notificationReceiverId: string;
  notificationSenderId: string;
  notificationTime: string;
  notificationMDText: string | undefined;
  clickAction: string | null;
  notificationTitle: string;
  image: string;
}
function Notifications() {
  const { authHeader } = useAuthContext();
  const {
    alertNotification,
    notifications,
    setNotifications,
    isLoading,
    setUnreadCount,
    unreadCount,
  } = useNotificationContext();
  const isMobileView = useMediaQuery("(max-width: 600px)");
  
  useEffect(() => {
    setNotifications(notifications)
    setUnreadCount(unreadCount)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader,alertNotification]);

  const updateReadStatus = async (notificationId: number) => {
    try {
      if (authHeader) {
        const messageReadStatus = await readNotifications(notificationId, authHeader);
        return messageReadStatus.data;
      }
    } catch (error) {
      console.error("Error in updateReadStatus:", error);
    }
  };
  const handleNotificationClick = async (notification: Notification) => {
    if (!notification.isRead) {
      try {
        await updateReadStatus(notification.notificationId);
        setNotifications((prevNotifications)=>{
          return prevNotifications.map((n) => n.notificationId === notification.notificationId
            ? {
              ...n,
              isRead:1,
            }
            : n);
        })
      } catch (error) {
        console.error("Error updating notification status:", error);
      }
    }

    if (notification.clickAction) {
      window.open(notification.clickAction, "_self");
    }
  };

  return (
    <Container sx={{ pl: 1, pr: 1, width: "100%" }}>
      {isLoading ? (
        <LoadingSpinner label="Please wait while we fetch notifications..." />
      ) : notifications.length === 0 ? (
        <Box sx={{ height: "400px" }}>
          <Typography variant="h5" textAlign="center" mb={6} mt={6}>
            No Notifications Received
          </Typography>
        </Box>
      ) : (
        <>
          <Typography
            variant="h3"
            mt={3}
            textAlign="left"
            sx={{  pl: isMobileView ? 1 : 2, pr: isMobileView ? 1 : 2, mb: 0 }}
          >
            Notifications
          </Typography>
          <Typography
            variant="h5"
            mt={3}
            textAlign="left"
            sx={{  pl: isMobileView ? 1 : 2, pr: isMobileView ? 1 : 2, mt: 0 }}
          >
            you have{" "}
            {
             unreadCount
            }{" "}
            {unreadCount !== 1
              ? "Unread Notifications"
              : "Unread Notification"}
          </Typography>
          {!isMobileView ? (
            <Box>
              <Grid>
                <List>
                  {notifications &&
                    notifications.map((notification) => (
                      <React.Fragment key={notification.notificationId}>
                        <NotificationCard
                          notification={notification}
                          handleNotificationClick={handleNotificationClick}
                        />
                      </React.Fragment>
                    ))}
                </List>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid>
                <List>
                  {notifications.map((notification) => (
                    <React.Fragment key={notification.notificationId}>
                      <NotificationCard
                        notification={notification}
                        handleNotificationClick={handleNotificationClick}
                      />
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Container>
  );
}

export default Notifications;
