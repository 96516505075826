import React, { useState, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Container,
  TextField,
  Snackbar,
  Box,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { reviewPin, raiseWithdrawalRequest } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";

const OTPPage: React.FC = () => {
  const location = useLocation();
  const withdrawalAmount: number | undefined = location.state?.withdrawalAmount;
  const paymentMethodId: string | undefined = location.state?.paymentMethodId;
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>("");
  const { authHeader } = useAuthContext();
  const [pinError, setPinError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "info"
  >("success");

  const handleResetPIN = () => {
    //reset pin functionality
  };

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setOtp(numericValue);
  };
  const handleBackToPreviousPage = () => {
    navigate(-2);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "info"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOtpSubmit = async () => {
    try {
      if (authHeader) {
        const encodedPin = btoa(otp);
        const reviewPinResponse: any = await reviewPin(
          { pin: encodedPin },
          authHeader
        );
        const status = reviewPinResponse.data;

        if (status.verification === "success") {
          const withdrawalData = {
            amount: withdrawalAmount || 0,
            paymentMethodId: paymentMethodId,
          };

          const withdrawalResponse = await raiseWithdrawalRequest(
            withdrawalData,
            authHeader
          );

          if (withdrawalResponse) {
            showSnackbar("Withdrawal request successful.", "success");
            navigate("/payment/withdraw-status");
          } else {
            showSnackbar(
              `Withdrawal request failed: ${withdrawalResponse}`,
              "error"
            );
          }
        } else {
          setPinError("Invalid PIN. Please try again.");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showSnackbar("An error occurred. Please try again later.", "error");
    }
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Card
        sx={{
          maxWidth: 450,
          maxHeight: "600px",
          width: "100%",
          marginBottom: "20px",
          backgroundColor: "background.default",
          marginTop: "50px",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: "30px" }}>
          {" "}
          Withdraw Confirmation
        </Typography>
        <Typography variant="h6">Enter your Withdrawal PIN</Typography>
        {withdrawalAmount !== undefined ? (
          <Typography variant="body1">
            To confirm{" "}
            <span style={{ color: "red" }}>
              {" "}
              withdrawal of INR
              <strong>{withdrawalAmount}</strong>
            </span>{" "}
            from your wallet
          </Typography>
        ) : (
          <Typography variant="body2" color="error">
            No withdrawal amount provided.
          </Typography>
        )}
        <TextField
          label="Enter PIN"
          variant="outlined"
          value={otp}
          onChange={handleOtpChange}
          fullWidth
          margin="dense"
          type="password"
          autoFocus
          sx={{ width: "350px", margin: "40px 40px 0px 40px" }}
          inputProps={{ maxLength: 6, style: { textAlign: "center" } }}
          autoComplete="off"
        />
        {pinError && <Typography sx={{ color: "red" }}>{pinError}</Typography>}
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Forgot your Withdrawal PIN?
          <Button
            color="primary"
            sx={{ marginBottom: "3px" }}
            onClick={handleResetPIN}
          >
            Reset Your PIN
          </Button>
        </Typography>
        <Box
          sx={{
            backgroundColor: "#FFEEC3",
            display: "flex",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #FFA500",
            margin: "30px",
            maxWidth: "370px",
            borderRadius: "10px",
          }}
        >
          <WarningAmberIcon sx={{ color: "#FCAA23" }} />
          <Typography sx={{ color: "#FF8C00", marginLeft: "8px" }}>
            Do not share the Withdrawal Pin with anyone.
          </Typography>
        </Box>
        <Button onClick={handleBackToPreviousPage} color="error">
          Cancel
        </Button>
        <Button onClick={handleOtpSubmit} sx={{ margin: "20px" }}>
          Submit PIN
        </Button>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default OTPPage;
