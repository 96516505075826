import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./VendorList.css";
import {
  getAssignedDetails,
  getGigDetailsByGigId,
} from "@gogig-in/gogig-clients";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LoadingSpinner from "../../components/LoadingAnimation";
import { useAuthContext } from "../../providers/AuthProvider";

interface Vendor {
  gigId: string;
  gigTitle: string;
  vendorId: string;
  name: string;
  number: string;
  language: string;
  completed: boolean;
  selectedOption: string | null;
  Gender: string;
  Country: string;
  callStatus: string;
}

const VendorList = () => {
  const [vendorDetails, setVendorDetails] = useState<Vendor[]>([]);
  const { authHeader } = useAuthContext();
  const { gigId } = useParams<{ gigId?: string }>();
  const history = useNavigate();
  const location = useLocation();
  const gigTitle = location.state ? location.state.gigTitle : null;
  const category = location.state ? location.state.category : null;

  const [isLoading, setIsLoading] = useState(true);
  const [gigDetails, setGigDetails] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gigId) {
          if (gigTitle === null) {
            const gigDetailsResponse: any = await getGigDetailsByGigId(gigId);
            setGigDetails(gigDetailsResponse.data[0]);
          }

          const response: any = await getAssignedDetails(gigId, authHeader);

          if (Array.isArray(response.data)) {
            setVendorDetails(response.data);
          } else {
            console.error("Data received is not an array:", response.data);
          }
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error during getAssignedDetails ",e);
      }
    })();
  }, [authHeader, gigId, gigTitle]);

  const handleVendorClick = (vendor: Vendor, gigTitle: string) => {
    const vendorDetailsURL = `/vendors/${gigId}/${vendor.vendorId}`;
    history(vendorDetailsURL, {
      state: { vendorData: vendor, gigTitle, category },
    });
  };

  const handleSupplementsClick = () => {
    history("/supplementsPage", { state: { category: category } });
  };

  return (
    <div className="vendor-list-container">
      {gigTitle === null ? (
        <h2 style={{ width: "" }}>{gigDetails?.gigTitle}</h2>
      ) : (
        <h2 style={{ width: "" }}>{gigTitle}</h2>
      )}{" "}
      {isLoading ? (
        <LoadingSpinner label="Please wait while we fetch your tasks..." />
      ) : (
        <>
          <Typography
            sx={{
              color: "primary.dark",
              fontSize: "12px",
              fontFamily: "inter, sans-serif",
              cursor: "pointer",
            }}
            onClick={() => handleSupplementsClick()}
          >
            Obtain additional details regarding this task by clicking here.
          </Typography>
          <TableContainer>
            <Table>
              <TableHead sx={{ borderBottom: "2px solid #000" }}>
                <TableRow>
                  <TableCell sx={{ padding: "10px 20px", fontSize: "18px" }}>
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px 20px",
                      textAlign: "center",
                      fontSize: "18px",
                    }}
                  >
                    Languages
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px 20px",
                      textAlign: "center",
                      fontSize: "18px",
                    }}
                  >
                    Phone Number
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px 20px",
                      textAlign: "center",
                      fontSize: "18px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorDetails.map((vendor: Vendor, index: number) => (
                  <React.Fragment key={vendor.vendorId}>
                    <TableRow className="vendor-row">
                      <TableCell sx={{ padding: "5px" }}>
                        <Typography variant="h6">
                          {vendor.name !== "null" ? vendor.name : "Unknown"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ padding: "5px", textAlign: "center" }}>
                        {vendor.language !== "null"
                          ? vendor.language
                          : "English, Kannada"}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "5px 20px", textAlign: "center" }}
                      >
                        {vendor.number}
                      </TableCell>
                      <TableCell sx={{ padding: "5px", textAlign: "center" }}>
                        {/* <a href={`tel:${vendor.number}`}>
                          <Button variant="text" size="small">
                            <PhoneIcon color="primary" />
                          </Button>
                        </a> */}
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => handleVendorClick(vendor, gigTitle)}
                          endIcon={<PersonAddIcon />}
                        >
                          Connect
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default VendorList;
