import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface HandlePersonQuesProps {
  question: string;
  questionId: string;
  answers: Record<string, string>;
  handleAnswerChange: (questionId: string, value: string) => void;
  disabled: boolean;
  onErrorChange: (error: string | null, field: string) => void;
  answerFields: string[];
}

const HandlePersonQues: React.FC<HandlePersonQuesProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  disabled,
  onErrorChange,
  answerFields,
}) => {
  const [personValues, setPersonValues] = useState<string[]>(() => {
    const savedValues =
      answers[questionId]?.split(",") || Array(answerFields.length).fill("");
    return savedValues.slice(0, answerFields.length);
  });
  const [personErrors, setPersonErrors] = useState<string[]>(
    Array(answerFields.length).fill("")
  );

  const validatePersonValues = (values: string[]): boolean => {
    const [name, email, phoneNumber] = values;

    const isNameValid = name.trim() !== "";
    const isEmailOrPhoneValid = email.trim() !== "" || phoneNumber.trim() !== "";

    if (!isNameValid || !isEmailOrPhoneValid) {
      setPersonErrors([
        "Along with the name, either a phone number or email is mandatory.",
      ]);
    } else {
      setPersonErrors(Array(answerFields.length).fill(""));
    }

    return isNameValid && isEmailOrPhoneValid;
  };

  const handlePersonValueChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = [...personValues];
      newValues[index] = e.target.value;
      setPersonValues(newValues);
      validatePersonValues(newValues);
      const personResponse: any = answerFields.reduce(
        (acc: Record<string, string>, field, i) => {
          acc[field] = newValues[i];
          return acc;
        },
        {}
      );
      handleAnswerChange(questionId, personResponse);
    };



  useEffect(() => {
    const notifyParentOnError = () => {
      const hasError = personErrors.some((error) => error !== "");
      onErrorChange(hasError ? "Please fill in all required fields." : null, question);
    };
    notifyParentOnError();
  }, [onErrorChange, personErrors, question]);
  
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography
          variant="h6"
          sx={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            textJustify: "inter-word",
          }}
        >
          {question}
        </Typography>
        {personErrors[0] && (
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
            }}
          >
            {personErrors[0]}
          </Typography>
        )}
      </FormLabel>
      {answerFields.map((field, index) => (
        <TextField
          key={index}
          label={field}
          variant="outlined"
          value={personValues[index]}
          onChange={handlePersonValueChange(index)}
          style={{ width: "250px" }}
          size="small"
          helperText={index === 0 ? " " : " "}
          error={false}
          disabled={disabled}
          autoComplete="off"
        />
      ))}
      <br />
    </FormControl>
  );
};

export default HandlePersonQues;
