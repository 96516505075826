import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";
import { Box } from "@mui/material";

const PrivateRoutes = () => {
  const headerSize = "61px";
  const { isAuthenticated } = useAuthContext();
  return isAuthenticated ? (
    <Box style={{ marginTop: headerSize }}>
      <Outlet />
    </Box>
  ) : (
    <Navigate to="/user-entry/signup" />
  );
};
export default PrivateRoutes;
