import React from "react";
import { Card, CardContent, Box, Skeleton, Grid } from "@mui/material";

interface SkeletonModalProps {
  numberOfCards?: number;
  skeletonType?: string;
}

const SkeletonModal: React.FC<SkeletonModalProps> = ({
  numberOfCards,
  skeletonType,
}) => {
  return (
    <div>
      {skeletonType === "dashboard" ? (
        <React.Fragment>
          {[...Array(numberOfCards)].map((_, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{
                backgroundColor: "background.default",
                margin: "20px 0",
                width: "100%",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={50}
                  height={50}
                  sx={{ marginLeft: "10px", marginTop: "10px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={40}
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    margin: "10px",
                  }}
                />
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={100} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Skeleton variant="text" width={200} />
                  <Skeleton variant="text" width={250} />
                </Box>
              </CardContent>
            </Card>
          ))}
        </React.Fragment>
      ) : skeletonType === "gigListing" ? (
        <React.Fragment>
          <Grid container spacing={2}>
            {[...Array(numberOfCards)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "background.default",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Skeleton variant="text" width={200} height={30} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "6px",
                        marginTop: "4px",
                        opacity: 0.7,
                      }}
                    >
                      <Skeleton variant="text" width={100} height={20} />
                      <Skeleton variant="text" width={100} height={20} />
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                    <Skeleton variant="text" width={300} height={30} />
                    <Skeleton variant="text" width={250} height={20} />
                    <Skeleton variant="text" width={200} height={20} />
                    <Skeleton variant="text" width={150} height={20} />
                    <Skeleton variant="text" width={200} height={20} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "25px",
                      }}
                    >
                      <Skeleton variant="circular" width={50} height={50} />
                      <Box sx={{ ml: 2 }}>
                        <Skeleton variant="text" width={100} height={20} />
                      </Box>
                    </Box>
                    <div dir="rtl" style={{ marginTop: "-24px" }}>
                      <Skeleton variant="rectangular" width={30} height={30} />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ) : skeletonType === "categoryListing" ? (
        <React.Fragment>
          <Grid container spacing={2}>
            {[...Array(numberOfCards)].map((_, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "background.default",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Skeleton variant="text" width={200} height={30} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginBottom: "6px",
                        marginTop: "4px",
                        opacity: 0.7,
                      }}
                    >
                      <Skeleton variant="text" width={100} height={20} />
                      <Skeleton variant="text" width={100} height={20} />
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                    <Skeleton variant="text" width={300} height={30} />
                    <Skeleton variant="text" width={250} height={20} />
                    <Skeleton variant="text" width={200} height={20} />
                    <Skeleton variant="text" width={150} height={20} />
                    <Skeleton variant="text" width={200} height={20} />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "25px",
                      }}
                    >
                      <Skeleton variant="circular" width={50} height={50} />
                      <Box sx={{ ml: 2 }}>
                        <Skeleton variant="text" width={100} height={20} />
                      </Box>
                    </Box>
                    <div dir="rtl" style={{ marginTop: "-24px" }}>
                      <Skeleton variant="rectangular" width={30} height={30} />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ): skeletonType === "default" ? (
        <React.Fragment>
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={100} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default SkeletonModal;
