import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Typography } from "@mui/material";

interface RadioGroupProps {
  question: string;
  questionId: string;
  answers: Record<string, string>;
  handleAnswerChange: (questionId: string, value: string) => void;
  options: string[];
  disabled: boolean;
}

const HandleSingleChoiceQuestions: React.FC<RadioGroupProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  options,
  disabled,
}) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h6">{question}</Typography>
      </FormLabel>{" "}
      <RadioGroup
        name={`question_${questionId}`}
        value={answers[questionId] || ""}
        onChange={(e) => handleAnswerChange(questionId, e.target.value)}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio />}
            label={option}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default HandleSingleChoiceQuestions;
