import React from "react";
import { Box, Grid } from "@mui/material";
import Container from "@mui/system/Container/Container";
import UserProfileData from "../components/profile/UserProfileData";
import AuditCredentials from "../components/profile/AuditCredentials";


const AuditUserData: React.FC = () => {
  return (
    <Grid>
      <Box marginTop={5}>
        <Container>
          <Box textAlign="center" mb={2} className="Audit-profile-page">
            <UserProfileData />
            <AuditCredentials/>
            
            
          </Box>
        </Container>
      </Box>
    </Grid>
  );
};

export default AuditUserData;
