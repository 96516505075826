export const validateAccountNumber = (
  accountNumber: string | undefined
): string => {
  if (
    !accountNumber ||
    accountNumber.length < 11 ||
    accountNumber.length > 16
  ) {
    return "Account number is required and must be between 11 and 16 characters";
  }
  return "";
};
