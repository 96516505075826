// CustomChip.tsx

import React from 'react';
import Chip from '@mui/material/Chip';

interface JobTypeChipProps {
  label: string;
  additionalStyles?: React.CSSProperties;
}

const CustomChip: React.FC<JobTypeChipProps> = ({ label, additionalStyles }) => (
  <Chip
    label={label || "Part-time"}
    size="small"
    sx={{
      marginRight: "5px",
      fontWeight: '600',
      borderRadius: "2px",
      ...additionalStyles,
    }}
  />
);

export default CustomChip;
