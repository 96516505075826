import { Outlet } from "react-router-dom";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Box } from "@mui/material";

function DefaultContainer() {
  const headerSize = "61px";
  return (
    <Box className={"layout-container"}>
      <Header />
      <Box style={{ marginTop: headerSize }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

export default DefaultContainer;
