import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

interface HandleTextQuesProps {
  question: string;
  questionId: string;
  answers: Record<string, string>; // Assuming answers is an object with string values
  handleAnswerChange: (questionId: string, value: string) => void;
  gridSpace: number;
}

const HandleTextQues: React.FC<HandleTextQuesProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
  gridSpace,
}) => {
  return (
    <FormControl component="fieldset">
        <Typography
          sx={{
            fontWeight:'bold',
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            textJustify: "inter-word",
          }}
        >
          {question}
        </Typography>
      {" "}
      <TextField
        variant="outlined"
        value={answers[questionId] || ""}
        onChange={(e) => handleAnswerChange(questionId, e.target.value)}
        style={{ width: "250px" }}
        size="small"
        autoComplete="off"
      />
      <br />
    </FormControl>
  );
};

export default HandleTextQues;
