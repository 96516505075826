import { createEvent } from "@gogig-in/gogig-clients";
import { TrackingEvent } from "./types";

export async function sendEvent(event: TrackingEvent, authHeader: string) {
  try {
    await createEvent(event, authHeader);
  } catch (error) {
    console.error(error,"error during sendEvent");
  }
}
