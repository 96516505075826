import { AuthApiBaseUrl } from "./baseUrls";
import { fetchWrapper } from "@gogig-in/gogig-clients";

export async function getSavedNotifications(authHeader: string) {
  return fetchWrapper(
    `${AuthApiBaseUrl}/getSavedNotifications`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader 
      },
    }
  );
}