import React from "react";
import { Box, Container, Typography } from "@mui/material";
import PageTitleComponent from "../components/PageTitleComponent";

const AboutPageComponent: React.FC = () => {
  return (
    <Container sx={{ marginBottom: "15px" }}>
      <Box>
        <PageTitleComponent title="About gOGig" />
        <Typography>
          Introducing gOGig, the premier platform designed to seamlessly connect
          freelancers and gig workers with businesses in need of on-demand
          services. Our mission is to streamline the process of securing
          flexible, contact-less work, catering to the evolving needs of both
          workers and businesses in today's dynamic market. For freelancers and
          gig workers, gOGig offers an intuitive and user-friendly interface to
          find and apply for various gigs that match their skills and
          preferences. Whether you are a seasoned professional or just starting,
          gOGig provides ample opportunities across diverse industries, ensuring
          that you can find work that suits your schedule and expertise.
          Businesses benefit from gOGig by gaining access to a vast pool of
          talented individuals ready to take on tasks as they arise. Our
          platform simplifies the hiring process, allowing businesses to quickly
          find and engage the right talent without the hassle of lengthy
          recruitment processes. This ensures that companies can maintain
          productivity and agility, adapting to workload fluctuations with ease.
          With gOGig, the future of work is here—flexible, efficient, and
          contact-less. Join us in transforming the way work gets done,
          providing a win-win solution for freelancers seeking independence and
          businesses striving for excellence.
        </Typography>

        <Typography mt={2} variant="h3">Our Story</Typography>
        <Typography>
          gOGig emerged with a mission to streamline the process of
          connecting individuals for work, enabling everyone to pursue their
          passions and skills autonomously. By leveraging innovative technology
          and a user-friendly platform, gOGig simplifies the way people find and
          offer services, fostering a community where freelancers and clients
          can connect seamlessly. The platform emphasizes flexibility,
          independence, and the empowerment of individuals to work on their own
          terms, supporting a diverse range of talents and professions. gOGig
          aims to revolutionize the gig economy, making it easier for people to
          find fulfilling work and achieve their professional goals.
        </Typography>

        <Typography  mt={2} variant="h3">Services We Offer</Typography>
        <Typography>
          On gOGig, freelancers can create detailed profiles showcasing their
          skills, experiences, and portfolio of work, making it easy for them to
          highlight what they are good at. The platform allows freelancers to
          browse and apply for jobs posted by businesses that require their
          expertise. Simultaneously, businesses can post job ads outlining their
          specific needs and requirements, and search through the profiles of
          potential candidates to find the perfect match for their projects.
          This dual-sided approach ensures that both freelancers and businesses
          can connect efficiently, facilitating productive collaborations and
          successful outcomes. gOGig streamlines the hiring process, making it
          simple and effective for both parties to find exactly what they are
          looking for.
        </Typography>

        <Typography  mt={2} variant="h3">Contact Us</Typography>
        <Typography mb={2}>
          If you need help, have ideas, or want to share your thoughts, you can
          reach out to us at <a href="mailto:support@gogig.in">contact us</a>.
          We appreciate your input and are here to help you.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutPageComponent;
