import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";
import Dashboard from "./Dashboard";
import { useAuthContext } from "../../providers/AuthProvider";

const VendorDashboard = () => {
  const { userDetails } = useAuthContext();
  return (
    <Container
      sx={{
        marginTop: 3,
      }}
    >
      <Box>
        {userDetails && (
          <Typography variant="h4" component="h6" textAlign={"center"}>
            Hello! {userDetails.firstName} {userDetails.lastName}
          </Typography>
        )}
        <Typography variant="h5" textAlign={"center"} gutterBottom>
          We are glad to have you here
        </Typography>
      </Box>

      <Divider
        sx={{
          margin: 2,
        }}
      />

      <Dashboard />
    </Container>
  );
};

export default VendorDashboard;
