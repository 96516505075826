import { AuthApiBaseUrl } from './baseUrls';
import { fetchWrapper } from '@gogig-in/gogig-clients';

export async function readNotifications(notificationId: number,authHeader:string) {
  return fetchWrapper(
    `${AuthApiBaseUrl}/readNotification?notificationId=${notificationId}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: authHeader,
      },
    },
  );
}