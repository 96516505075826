import React from 'react';
import { Avatar, Box } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MuiCategoryIcon from '@mui/icons-material/Category';

interface CategoryIconProps {
  categoryId: string;
  
}
export enum categoryNames{
  Telecalling ='cat-8055',
  Branding = "cat-9543",
  Survey = "cat-6405"
}

const CategoryIcon: React.FC<CategoryIconProps> = ({ categoryId }) => {
  const getIcon = () => {
    switch (categoryId) {
      case categoryNames.Branding:
        return <LocalOfferIcon sx={{ fontSize: 36 }} />;
      case categoryNames.Survey:
        return <AssessmentIcon sx={{ fontSize: 36 }} />;
      case categoryNames.Telecalling:
        return <HeadsetMicIcon sx={{ fontSize: 36 }} />;
      default:
        return <MuiCategoryIcon sx={{ fontSize: 36 }} />;
    }
  };
  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '72px',
        maxHeight: '60px',
        
      }}
    >
      <Avatar
        sx={{
          width: 64,
          height: 64,
          backgroundColor: 'primary.main',
        }}
      >
        {getIcon()}
      </Avatar>
    </Box>
  );
};

export default CategoryIcon;
