import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import CategoryListing from '../categoryListings/CategoryListing';

const featuredCategories = () => {
  return (
    <Box sx={{ backgroundColor: '#fff', position: 'relative', pt: 4, }}>
      <Container maxWidth="lg">
        <Typography component="h2"
                  sx={{
                    position: 'relative',
                    fontSize: 28,
                    fontWeight:"bold",
                    lineHeight: 1.1,
                  }}>Featured Categories</Typography>
                  
        <Typography variant="h5"  >
          We have some categories for you to choose gigs as per your preferences.
        </Typography>
        <CategoryListing />
      </Container>
    </Box>
  );
}

export default featuredCategories;
