import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  CardHeader,
  useMediaQuery,
} from "@mui/material";
import CustomButton from "../ButtonWrapper";
import Strings from "../../Locale/strings.json";
import WorkTwoToned from "@mui/icons-material/WorkTwoTone";
import { GigHistoryDetails } from "./GigHistoryValues";
import CustomChip from "../endUserLandingPage/CustomChip";
import { useNavigate } from "react-router-dom";

function calculateTimeRemaining(gigStartDate: any) {
  const gigStartDateTimestamp = new Date(gigStartDate).getTime();
  const currentDateTimestamp = new Date().getTime();
  const timeDifference = gigStartDateTimestamp - currentDateTimestamp;

  let daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  if (daysRemaining <= 0) {
    daysRemaining = 0;
  }

  return `${daysRemaining} days`;
}

interface CustomCardProps {
  gig: GigHistoryDetails;
  startsIn?: boolean;
  showEndingLine?: boolean;
  showEarning?: boolean;
}

const GigDashboardCard: React.FC<CustomCardProps> = ({
  gig,
  startsIn = false,
  showEndingLine = true,
  showEarning = true,
}) => {
  const timeLabel = startsIn ? "Starts in" : "Ending in";
  const history = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleGigClick = (gigDetails: GigHistoryDetails) => {
    const gigDetailsURL = `/task/gig-status/${gigDetails.gigId}`;
    history(gigDetailsURL);
  };
  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: "background.default",
        margin: "10px 0",
        width: "100%",
      }}
    >
      <CardHeader
        avatar={
          gig.companyLogo ? (
            <img
              src={gig.companyLogo}
              alt="Company Logo"
              style={{
                height: "40px",
              }}
            />
          ) : (
            <WorkTwoToned
              color="primary"
              sx={{ margin: "5px", height: "45px", width: "45px" }}
            />
          )
        }
        action={
          <Box sx={{ marginTop: "10px", width: "fit-content" }}>
            {!!gig.isVerified && (
              <>
                <CustomButton
                  tag="StartGig"
                  size="small"
                  onClick={() => handleGigClick(gig)}
                  style={{ textTransform: "none", marginRight: "10px" }}
                  variant="contained"
                >
                  Start Gig
                </CustomButton>
                {/* <CustomButton
                  tag={secondButtonTag}
                  size="small"
                  onClick={() => handleSecondButtonClick(gig)}
                  style={{ textTransform: "none" }}
                  variant="contained"
                >
                  {secondButtonLabel}
                </CustomButton> */}
              </>
            )}
          </Box>
        }
        title={
          gig.gigTitle.length > 100
            ? gig.gigTitle.slice(0, 100) + "..."
            : gig.gigTitle
        }
        subheader={new Date(gig.createdAt).toLocaleDateString()}
        sx={{ backgroundColor: "background.paper" }}
      />
      <CardContent>
        {!gig.isVerified && (
          <Typography sx={{ fontWeight: "600" }}>
            Your application is under review.
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              mt: "4px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              sx={{
                color: "text.secondary",
                fontSize: 14,
                pr: { xs: 0, md: 2 },
              }}
            >
              Total Tasks completed:{" "}
              <span style={{ fontWeight: "bold" }}>
                {gig.totalVendorsCompletedByUser}
              </span>
            </Typography>

            {showEarning &&
              (gig.earned !== "0" && gig.earned != null ? (
                <>
                  {!isSmallScreen && <Divider orientation="vertical" />}
                  <Typography
                    sx={{
                      fontSize: 14,
                      pr: { xs: 0, md: 2 },
                      pl: { xs: 0, md: 2 },
                      pt: { xs: "5px", md: 0 },
                    }}
                  >
                    You've earned{" " + Strings.INR}
                    <span style={{ color: "green", marginRight: "12px" }}>
                      {gig.earned}
                    </span>
                  </Typography>
                  {/* {showEndingLine && (
                    <>
                      <Divider orientation="vertical" />
                      <Typography sx={{ fontSize: 14, pr: 2, pl: 2 }}>
                        {timeLabel}{" "}
                        <span style={{ color: startsIn ? "green" : "red" }}>
                          {startsIn
                            ? calculateTimeRemaining(gig.gigStartDate)
                            : calculateTimeRemaining(gig.gigEndDate)}
                        </span>
                      </Typography>
                    </>
                  )} */}
                </>
              ) : (
                <Typography sx={{ fontSize: 14 }}>
                  {timeLabel}{" "}
                  <span style={{ color: startsIn ? "green" : "red" }}>
                    {startsIn
                      ? calculateTimeRemaining(gig.gigStartDate)
                      : calculateTimeRemaining(gig.gigEndDate)}
                  </span>
                </Typography>
              ))}
          </Box>
          <Box sx={{ ml: { xs: 0, md: 2 }, mt: { xs: "5px", md: 0 } }}>
            {gig.jobType && (
              <CustomChip
                label={gig.jobType}
                additionalStyles={{
                  color: "#0BA02C",
                  backgroundColor: "#E7F6EA",
                }}
              />
            )}
            {gig.category && (
              <CustomChip
                label={gig.category}
                additionalStyles={{
                  color: "#0d47a1",
                  backgroundColor: "#bbdefb",
                }}
              />
            )}
            {gig.site && (
              <CustomChip
                label={gig.site}
                additionalStyles={{
                  color: "#880e4f",
                  backgroundColor: "#f8bbd0",
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GigDashboardCard;
