import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import CapabilitiesSelector from "../profile/CapabilitiesSelector";
import {
  Capability,
  addUserCapability,
  getCapabilityList,
} from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import CustomModal from "../custom/CustomModal";

interface EditCapabilitiesPopUpProps {
  open: boolean;
  onClose: () => void;
  userSelectedCapabilities: Capability[];
}

export interface CapabilitySkillProps {
  capabilityId: number;
  proficiency?: string | null;
}

const EditCapabilitiesPopUp = ({
  open,
  onClose,
  userSelectedCapabilities,
}: EditCapabilitiesPopUpProps) => {
  const { authHeader } = useAuthContext();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const [isLoading, setLoading] = useState(false);

  const [capabilities, setCapabilities] = useState<Capability[]>([]);

  const [selectedUserSkills, setSelectedUserSkills] = useState<number[]>([]);
  const [selectedUserAssets, setSelectedUserAssets] = useState<number[]>([]);

  useEffect(() => {
    const initialSelectedSkills = userSelectedCapabilities
      .filter((capability) => capability.capabilityType === "skill")
      .map((s) => s.capabilityId);
    const initialSelectedAssets = userSelectedCapabilities
      .filter((capability) => capability.capabilityType === "asset")
      .map((a) => a.capabilityId);
    setSelectedUserAssets(initialSelectedAssets);
    setSelectedUserSkills(initialSelectedSkills);
  }, [userSelectedCapabilities]);

  const snackbar = (message: string, severity: Severity) => {
    setSnackbarData({ message, severity });
  };

  // Fetch the list of all capabilities
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCapabilityList();
        setCapabilities(data);
      } catch (e) {
        setOpenSnackbar(true);
        snackbar("Something went wrong", "error");
      }
    };

    fetchData();
  }, []);

  const userSkills = capabilities.filter(
    (capability) => capability.capabilityType === "skill"
  );
  const userAssets = capabilities.filter(
    (capability) => capability.capabilityType === "asset"
  );

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const capabilityList = [...selectedUserSkills, ...selectedUserAssets].map(
        (capabilityId) => ({
          capabilityId,
          proficiency: null, // replace with actual proficiency if available
        })
      );
      if (authHeader) {
        await addUserCapability(capabilityList, authHeader);
        setOpenSnackbar(true);
        snackbar("Your skills are added successfully", "success");
        setTimeout(() => {
          handleClose(); // close snackbar after 2 seconds
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setOpenSnackbar(true);
      snackbar(`Your skills are not added`, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSkillsChange = (capabilityIds: number[]) => {
    setSelectedUserSkills(capabilityIds);
  };

  const handleAssetsChange = (capabilityIds: number[]) => {
    setSelectedUserAssets(capabilityIds);
  };

  const handleSkillsDelete = (capabilityId: number) => {
    const filteredUserSkills = selectedUserSkills.filter(
      (id) => id !== capabilityId
    );
    setSelectedUserSkills(filteredUserSkills);
  };

  const handleAssetsDelete = (capabilityId: number) => {
    const filteredUserAssets = selectedUserAssets.filter(
      (id) => id !== capabilityId
    );
    setSelectedUserAssets(filteredUserAssets);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <GlobalSnackbar
        open={openSnackbar}
        message={snackbarData.message}
        onClose={handleCloseSnackbar}
        severity={snackbarData.severity}
        vertical="top"
        horizontal="center"
      />
      <CustomModal
        open={open}
        onClose={handleClose}
        title="Edit your details"
        isLoading={isLoading}
        primaryActionOnclick={handleSubmit}
        primaryActionText="Submit"
      >
        <Typography fontSize="18px" fontWeight={500}>
          Please select your skills & assets
        </Typography>
        <br />
        <CapabilitiesSelector
          label="Skills"
          capabilities={userSkills}
          selectedCapabilityIds={selectedUserSkills}
          onChange={handleSkillsChange}
          onDelete={handleSkillsDelete}
        />
        <CapabilitiesSelector
          label="Assets"
          capabilities={userAssets}
          selectedCapabilityIds={selectedUserAssets}
          onChange={handleAssetsChange}
          onDelete={handleAssetsDelete}
        />
      </CustomModal>
    </>
  );
};

export default EditCapabilitiesPopUp;
