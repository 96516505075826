import React, { useState } from "react";
import { Button, Typography, Container, Box } from "@mui/material";
import { addSubordinateToSupervisor } from "@gogig-in/gogig-clients";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import CustomSnackbar from "../operationRolePages/CustomSnackbar";

const AcceptInvitationPage = () => {
  const { supervisorId } = useParams<{ supervisorId: string }>();
  const { authHeader } = useAuthContext();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { isAuthenticated } = useAuthContext();

  const containerStyle = {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const titleStyle = {
    marginBottom: "0.5rem",
  };

  const buttonStyle = {
    marginTop: "0.5rem",
  };

  const handleAcceptInvitation = async () => {
    if (isAuthenticated && authHeader) {
      try {
        if (supervisorId) {
          await addSubordinateToSupervisor(supervisorId, authHeader);
          handleSnackbar("success", "Invitation accepted successfully.");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      } catch (error: any) {
        if (error.status === 409 && error.errorCause === "teamInviteAccepted") {
          setMessage("The invitation is already accepted by you.");
        } else {
          setMessage("Failed to accept invitation. Retry after sometime");
        }
      }
    } else {
      navigate("/user-entry/signup", {
        state: {
          supervisorId: supervisorId,
        },
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (severity: any, message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <Container maxWidth="sm" sx={containerStyle}>
      <Typography variant="h4" style={titleStyle}>
        Accept Invitation
      </Typography>
      <Typography variant="body1" paragraph>
        You have been invited to join his team!
      </Typography>
      {!message && (
        <>
          <Typography variant="body1" paragraph>
            Do you want accept the invitation?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptInvitation}
            style={buttonStyle}
          >
            Accept Invitation
          </Button>
        </>
      )}
      {message && (
        <>
          <Typography variant="h5" paragraph>
            {message}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              onClick={() => navigate("/")}
              sx={{ textTransform: "none" }}
            >
              Click Here
            </Button>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ display: "flex", margin: "auto" }}
            >
              to go back to home
            </Typography>
          </Box>
        </>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default AcceptInvitationPage;
