import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, Grid } from "@mui/material";
import { postQuery } from "@gogig-in/gogig-clients";
import PageTitleComponent from "../PageTitleComponent";

const EmployerRegistrationForm: FC = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>({
    query: "",
  });

  const handleSubmit = async () => {
    try {
      await postQuery(formData);
    } catch (error) {
      console.error("Error during postQuery",error);

    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Container>
      <PageTitleComponent
        title="Unlock On-Demand Talent: gOGig Connects You to Skilled Gig Workers!"
        subTitle="Join gOGig for access to a diverse pool of skilled gig workers, ensuring quick access to on-demand talent for your business needs. Are you seeking skilled professionals for your projects without the commitment of full-time hires? Look no further than gOGig. Our platform connects you with a vast pool of vetted gig workers, offering you the flexibility to outsource jobs on demand. With gOGig, you can:"
      />

      <Grid container sx={{ marginBottom: "15px" }}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ paddingRight: "5px", marginBottom: "15px" }}
        >
          <Typography variant="h3" sx={{ marginTop: "-3px" }}>
            Access Diverse Talent
          </Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              fontFamily: "inter, sans-serif",
              marginBottom: 2,
            }}
          >
            Explore a diverse range of skilled gig workers to find the perfect
            match for your project requirements.
          </Typography>
          <Typography variant="h3">Scale Operations Effortlessly</Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              fontFamily: "inter, sans-serif",
              marginBottom: 2,
            }}
          >
            {" "}
            Scale your operations seamlessly by leveraging our platform&apos;s
            user-friendly interface and streamlined outsourcing process.
          </Typography>
          <Typography variant="h3">Ensure Quality Result</Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              fontFamily: "inter, sans-serif",
              marginBottom: 2,
            }}
          >
            {" "}
            Rest assured with our rigorous screening process and quality
            assurance measures, ensuring that you receive top-notch services
            from reliable professionals.
          </Typography>
          <Typography variant="h3">Optimize Costs</Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              fontFamily: "inter, sans-serif",
              marginBottom: 2,
            }}
          >
            {" "}
            Experience significant cost savings by outsourcing through gOGig,
            eliminating the overhead expenses associated with full-time hires.
          </Typography>
          <Typography variant="h3">Enjoy Dedicated Support</Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              fontFamily: "inter, sans-serif",
            }}
          >
            {" "}
            Benefit from our dedicated customer support, guiding you through
            every step of the outsourcing journey and ensuring a seamless and
            satisfying experience.
          </Typography>
          <Typography
            sx={{
              fontWeight: "normal",
              fontFamily: "inter, sans-serif",
              marginBottom: 2,
            }}
          >
            {" "}
            Elevate your project outcomes with gOGig today and unlock the power
            of on-demand outsourcing to propel your business forward.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              margin: "auto",
              padding: 2,
              border: "1px solid #000",
              borderRadius: 0.5,
            }}
          >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              Let's Get Started
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Please provide your name, contact details and query below and our
              team will reach out to you within 24 hrs for your requirements
            </Typography>

            <Box>
              <TextField
                fullWidth
                label="Your query"
                type="query"
                name="query"
                variant="outlined"
                margin="normal"
                autoComplete="off"
                value={formData.query}
                onChange={handleInputChange}
              />
            </Box>

            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              sx={{ marginTop: 2 }}
            >
              Submit
            </Button>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Thank You</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Thanks for filling out the form. Our team will contact you.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmployerRegistrationForm;
