import React from "react";
import FormControl from "@mui/material/FormControl";
import Rating from "@mui/material/Rating";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";

interface HandleRatingQuestionProps {
  question: string;
  questionId: string;
  answers: Record<string, number | null>; // Updated type for rating values
  handleAnswerChange: (questionId: string, value: number | null) => void;
}

const HandleRatingQuestion: React.FC<HandleRatingQuestionProps> = ({
  question,
  questionId,
  answers,
  handleAnswerChange,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <FormControl component="fieldset" sx={{ width: "100%", mb: isSmallScreen ? 2 : 0 }}>
      <Typography sx={{fontWeight:'bold'}}>
      {question}
      </Typography>
      <Rating
        name={`question_${questionId}`}
        value={answers[questionId] || 0} // Default value is 0 for an unrated question
        onChange={(_, value) => handleAnswerChange(questionId, value)}
        readOnly={false} // Allow users to rate
      />
    </FormControl>
  );
};

export default HandleRatingQuestion;
