interface JobData {
    jobNumber: number;
    earnings: number;
  }
  
 export interface DailyEarning {
    date: string;
    totalEarned: number;
    data: JobData[];
  }
  
  export const groupDailyData = (daily: any[]): DailyEarning[] => {
    const groupedData: { [date: string]: DailyEarning } = {};
  
    daily.forEach((entry) => {
      const date = entry.createdAt.split("T")[0];
      if (!groupedData[date]) {
        groupedData[date] = { date, totalEarned: 0, data: [] };
      }
  
      groupedData[date].totalEarned += entry.todaysEarning;
      groupedData[date].data.push({
        jobNumber: entry.taskCount,
        earnings: entry.todaysEarning,
      });
    });
  
    return Object.values(groupedData);
  };  