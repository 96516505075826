import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Container,
} from "@mui/material";
import { getCategories } from "@gogig-in/gogig-clients";
import { CategoryListValues } from "../components/categoryListings/CategoryListValues";
import SkeletonModal from "../components/skeleton/SkeletonModal";
import { useMediaQuery } from "@mui/material";
import CategoryItem from "../components/categoryListings/CategoryItem";

export default function ExploreCategories() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<CategoryListValues[]>();
  const isMobileView = useMediaQuery("(max-width: 900px)");
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Fetch categories from API
        const response = await getCategories();
        setCategories(response.data as CategoryListValues[]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);
  return (
    <Grid>
      <Box marginTop={2}>
        <Container>         
          <Box mb={2} className="profile-page">
            {isLoading ? (
              <SkeletonModal numberOfCards={3} skeletonType="gigListing" />
            ) : (
              <Box>
                <Typography  variant="h3">
                  Job Categories
                </Typography>
                <Typography variant="body2">
                  Explore the jobs by categories
                </Typography>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  sx={{ marginBottom: "20px" }}
                  
                >
                  {categories &&
                    categories.map((category) => (
                      <Grid  item xs={12}  md={isMobileView?0:4} key={category.categoryId}>
                        <CategoryItem category={category} />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Grid>
  );
}
