import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const OurVision: FC = () => {
  return (
<Box
  id="hero"
  sx={{
    backgroundColor: 'background.default',
    position: 'relative',
    pt: { xs: 4, md: 8 }, // Adjusted padding for mobile and tablets
    pb: { xs: 4, md: 8 }, // Adjusted padding for mobile and tablets
  }}
>
  <Container maxWidth="lg">
    <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
      <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent:'center' }}>
          <img style={{ maxWidth: '100%' }} src={`${process.env.PUBLIC_URL}/images/home/rafiki.png`} alt="Our vision img" />
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box
          sx={{
            textAlign: { xs: 'center', md: 'left' },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography
              component="h2"
              sx={{
                position: 'relative',
                fontSize: { xs: 28, md: 36 }, // Adjusted font size for mobile and tablets
                letterSpacing: 1.5,
                fontWeight: 'bold',
                lineHeight: 1.1,
                fontFamily: 'inter, sans-serif'
              }}
            >
              One easy step to change <br />
              <Typography
                component="mark"
                sx={{
                  position: 'relative',
                  color: 'secondary.main',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  backgroundColor: 'unset',
                  lineHeight: 1.1,
                  fontFamily: 'inter, sans-serif'
                }}
              >
                your Future.
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ mb: 4, width: { xs: '100%', md: '80%' } }}> {/* Adjusted width for tablets */}
            <Typography sx={{ color: 'text.secondary', lineHeight: 1.6, fontFamily: 'inter, sans-serif' }}>
              {
                "Unlock the path to a brighter future with gig work. Discover flexible opportunities that empower you to shape a promising tomorrow. Your journey toward financial independence and success starts here!"
              }
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Container>
</Box>


  )
}

export default OurVision