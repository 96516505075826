import React, { useEffect, useState } from "react";
import { Box, Container, Button, Popover, Typography } from "@mui/material";
import Strings from "../../Locale/strings.json";
import { GigHistoryDetails } from "./GigHistoryValues";
import { getAcceptedGigsByUser } from "@gogig-in/gogig-clients";
import GigHistoryListing from "./GigHistoryListing";
import PageTitleComponent from "../PageTitleComponent";
import { useAuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../types/userRoles";

// const gigStatusList = ["onGoing", "yetToStart", "completed", "applied"]; TODO: use it for filtering
const GigHistoryList: React.FC = () => {
  const [gigData, setGigData] = useState<GigHistoryDetails[]>([]);
  const { authHeader } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userDetails } = useAuthContext();
  const userRole = userDetails?.role;
  const [supervisor, setSupervisor] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  document.title = Strings.PageTitles.GigHistoryPage;

  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          const acceptedGigs: any = await getAcceptedGigsByUser(authHeader);
          setGigData(acceptedGigs);
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error during getAcceptedGigsByUser",e);
      }
    })();
    if (userRole === userRoles.supervisor) {
      setSupervisor(true);
    }
  }, [authHeader, userRole]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!supervisor) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PageTitleComponent title="Gig Dashboard" />
        </Box>
        {userRole !== userRoles.seeker && (
          <div
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
          >
            <Button
              onClick={() => navigate("/manage-subordinates")}
              variant="outlined"
              color="primary"
              sx={{ display: "flex", margin: "15px", textTransform: "none" }}
              disabled={!supervisor}
            >
              Manage Team
            </Button>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1, backgroundColor: "white" }}>
                You need supervisor role
                <br /> to access this.
              </Typography>
            </Popover>
          </div>
        )}
      </Box>
      <GigHistoryListing gigs={gigData} isLoading={isLoading} />
    </Container>
  );
};

export default GigHistoryList;
