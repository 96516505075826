import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  getWalletDetails,
  getTransactions,
  getWalletCredentials,
} from "@gogig-in/gogig-clients";
import Strings from "../../Locale/strings.json";
import LoadingSpinner from "../../components/LoadingAnimation";
import "./WalletCSS.css";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useAuthContext } from "../../providers/AuthProvider";

const WalletPage: React.FC = () => {
  const { authHeader } = useAuthContext();
  const [transactionData, setTransactionData] = useState<any[]>();
  const [walletDetails, setWalletDetails] = useState<Record<string, any>>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString();

    const formattedDate = `${day} ${month} ${year}, ${formattedHours}:${minutes} ${ampm}`;

    return formattedDate;
  };
  const isMobileView = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authHeader) {
          const response: any = await getTransactions(authHeader);
          setTransactionData(response.data);
          const walletResponse: any = await getWalletDetails(authHeader);
          setWalletDetails(walletResponse.data[0]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching initial state:", error);
      }
    };

    fetchData();
  }, [authHeader]);

  const handleWithdrawClick = async () => {
    try {
      if (authHeader) {
        const response: any = await getWalletCredentials(authHeader);
        if (response.data.length === 0) {
          navigate("/payment/aadhar-pancard");
        } else {
          navigate("/payment/payment-options");
        }
      }
    } catch (error) {
      console.error("Error during withdrawal:", error);
    }
  };
  return (
    <Container sx={{ minHeight: "100px" }}>
      <Typography
        variant="h4"
        sx={{
          margin: "20px",
          justifyContent: "space-between",
          display: " flex",
        }}
      >
        WALLET{" "}
        {!isMobileView && (
          <Button variant="contained" onClick={handleWithdrawClick}>
            WITHDRAW
          </Button>
        )}
      </Typography>
      {isLoading ? (
        <LoadingSpinner label="Please wait while we fetch your balances..." />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "background.paper",
              padding: "25px",
              marginTop: "15px",
            }}
          >
            <Typography className="headerStyles">Total money earned</Typography>
            <Typography className="headerStyles" sx={{ color: "#4CAF4F" }}>
              {`${Strings.INR} ${walletDetails?.totalEarned || "0"}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "background.paper",
              padding: "25px",
            }}
          >
            <Typography className="subHeadStyles">Available Balance</Typography>
            <Typography className="headerStyles" sx={{ color: "#4CAF4F" }}>
              {`${Strings.INR} ${walletDetails?.availableBalance || "0"}`}
            </Typography>
          </Box>
          <Box sx={{ padding: "5px", marginTop: "10px" }}>
            <Typography
              sx={{
                fontSize: { xs: 20, md: 24 },
                fontWeight: "bold",
                padding: "0px 0px 20px 0px",
              }}
            >
              Transaction History
            </Typography>
            {transactionData && transactionData.length > 0 ? (
              transactionData.map((transaction: any, index: number) => (
                <React.Fragment key={transaction.transactionId}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "70%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "50%",
                          backgroundColor:
                            transaction.transactionType === "debit"
                              ? "#FFE8E9"
                              : "#E9F8EE",
                          display: "inline-block",
                          marginRight: "8px",
                          width: "35px",
                          height: "35px",
                        }}
                      >
                        {transaction.transactionType === "debit" ? (
                          <ArrowUpwardIcon
                            sx={{ color: "#EC7063", padding: "5px" }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{ color: "#61B994", padding: "5px" }}
                          />
                        )}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            maxWidth: "100%",
                            "@media (max-width: 600px)": { maxWidth: "100px" },
                          }}
                        >
                          {transaction.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color:
                              transaction.transactionStatus === "success"
                                ? "#5cb85c"
                                : transaction.transactionStatus === "failed"
                                ? "#F71411"
                                : "#F39C12",
                          }}
                        >
                          {transaction.transactionStatus === "success"
                            ? "Completed"
                            : transaction.transactionStatus === "failed"
                            ? "Failed"
                            : "Processing"}
                        </Typography>
                        <Typography>
                          {formatDate(transaction.createdAt)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "700",
                        }}
                      >
                        {`${Strings.INR} ${
                          transaction.amount !== null ? transaction.amount : "0"
                        }`}
                      </Typography>
                    </Box>
                  </Box>
                  {index < transactionData.length - 1 && (
                    <Divider sx={{ marginTop: "10px" }} />
                  )}{" "}
                </React.Fragment>
              ))
            ) : (
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "center", padding: "10px 0px 100px 0px" }}
              >
                No transactions have been made yet.
              </Typography>
            )}
          </Box>
          {isMobileView && (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 5px",
                backgroundColor: "white",
              }}
            >
              <Button
                variant="contained"
                onClick={handleWithdrawClick}
                fullWidth
                sx={{ padding: "5px", borderRadius: "5px" }}
              >
                WITHDRAW Money
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default WalletPage;
