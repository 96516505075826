import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

interface GlobalSnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
  severity : Severity;
  vertical : 'top' | 'bottom';
  horizontal : 'left' | 'center' | 'right';
}

export type Severity = "error" | "success" | "info" | "warning" | undefined;

const GlobalSnackbar: React.FC<GlobalSnackbarProps> = ({ open, message, onClose, severity, vertical, horizontal }) => {
  
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={3000} // Optional: Set the duration for auto closing
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
