import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import { publishProject, getGigDetailsByUserId } from "@gogig-in/gogig-clients";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomButton from "../ButtonWrapper";
import { useAuthContext } from "../../providers/AuthProvider";

interface GigDetails {
  gigId: string;
  gigTitle: string;
  category: string;
  budget: string;
  status: "draft" | "published" | "completed";
  creationDate: string;
  gigStartDate: string;
  skillsRequired: string;
}

const DraftGigList: React.FC = () => {
  const [gigData, setGigData] = useState<GigDetails[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGigId, setSelectedGigId] = useState<string | null>(null);
  const { authHeader } = useAuthContext();

  useEffect(() => {
    (async () => {
      try {
        if (authHeader) {
          const response: any = await getGigDetailsByUserId(authHeader);
          response.data.sort(
            (a: GigDetails, b: GigDetails) =>
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
          );
          setGigData(response.data);
        }
      } catch (e) {
        console.error("error during getGigDetailsByUserId",e);
      }
    })();
  }, [authHeader]);

  const openModal = (gigId: string) => {
    setSelectedGigId(gigId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePublish = async () => {
    if (selectedGigId && authHeader) {
      try {
        await publishProject(selectedGigId, authHeader);
        closeModal();
        window.location.reload();
      } catch (error) {
        console.error("error during publishing the project",error);
        closeModal();
      }
    }
  };

  return (
    <Container disableGutters>
      <Paper elevation={0} sx={{ margin: "20px auto", maxWidth: "1200px" }}>
        <div style={{ gap: "20px" }}>
          {gigData.map((card, index) => {
            if (card.status === "draft") {
              const creationDate = new Date(card.creationDate);
              const currentDate = new Date();
              const isSameDay =
                creationDate.getDate() === currentDate.getDate() &&
                creationDate.getMonth() === currentDate.getMonth() &&
                creationDate.getFullYear() === currentDate.getFullYear();

              let timeAgo = "";

              if (isSameDay) {
                const timeDifference =
                  currentDate.getTime() - creationDate.getTime();
                if (timeDifference < 60 * 1000) {
                  const seconds = Math.floor(timeDifference / 1000);
                  timeAgo = `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
                } else if (timeDifference < 60 * 60 * 1000) {
                  const minutes = Math.floor(timeDifference / (60 * 1000));
                  timeAgo = `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
                } else {
                  const hours = Math.floor(timeDifference / (60 * 60 * 1000));
                  const minutes = Math.floor(
                    (timeDifference % (60 * 60 * 1000)) / (60 * 1000)
                  );
                  timeAgo = `${hours} hour${
                    hours !== 1 ? "s" : ""
                  } ${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
                }
              } else {
                timeAgo = creationDate.toLocaleDateString("en-GB");
              }

              return (
                <div
                  key={index}
                  style={{
                    marginBottom: "0px",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bolder",
                    position: "relative",
                    transition: "background-color 0.3s",
                    cursor: "pointer",
                    borderBottom: "1px solid #ccc",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "white";
                    e.currentTarget.style.borderRadius = "10px";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#F1F2F4";
                    e.currentTarget.style.borderRadius = "0px";
                  }}
                >
                  <CustomButton
                    tag="DraftGig/PublishGig"
                    variant="contained"
                    onClick={() => openModal(card.gigId)}
                    style={{
                      position: "absolute",
                      top: 25,
                      right: 15,
                      color: "white",
                      backgroundColor: "secondary",
                      borderRadius: "22px",
                    }}
                  >
                    Publish Gig
                  </CustomButton>
                  <Link
                    to={`/gig-details/${card.gigId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      style={{
                        position: "absolute",
                        bottom: 15,
                        right: 7,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Know More
                        <NavigateNextIcon style={{ marginLeft: "5px" }} />
                      </div>
                    </Button>
                  </Link>
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    fontSize={"20px"}
                  >
                    {card.gigTitle}
                  </Typography>
                  <Typography>Category: Tele-Calling</Typography>
                  <Typography>Budget: {card.budget}</Typography>
                  <Typography>
                    Skills Required: {card.skillsRequired}
                  </Typography>
                  <Typography>
                    Gig Start Date:{" "}
                    {new Date(card.gigStartDate).toLocaleDateString("en-GB")}
                  </Typography>
                  <Typography>Created on: {timeAgo}</Typography>
                </div>
              );
            }
            return null;
          })}
        </div>
      </Paper>

      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirm Publish</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to publish this gig?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            tag="DraftGig/Cancel"
            onClick={closeModal}
            color="primary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            tag="DraftGig/Confirm"
            onClick={handlePublish}
            color="primary"
          >
            Confirm Publish
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DraftGigList;
