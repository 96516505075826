import React, { useState } from "react";
import initialFormValues, { FormValues } from "./CompanyRegistrationFormValues";
import initialEmpFormValues, {
  EmpFormValues,
} from "./EmployeeRegistrationFormValues";
import {
  AlertProps,
  TextField,
  Snackbar,
  Grid,
  Box,
  Container,
  Typography,
  Paper,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  saveCompanyDetails,
  saveEmployerDetails,
} from "@gogig-in/gogig-clients";
import CustomButton from "../ButtonWrapper";
import { useAuthContext } from "../../providers/AuthProvider";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const size = [
  {
    value: "Select",
    label: "Select",
  },
  {
    value: "Small",
    label: "Small",
  },
  {
    value: "Medium",
    label: "Medium",
  },

  {
    value: "Large",
    label: "Large",
  },
];
function CompanyRegistration() {
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { authHeader } = useAuthContext();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };
  const [empFormValues, setEmpFormValues] =
    useState<EmpFormValues>(initialEmpFormValues);

  const handleEmpInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEmpFormValues((prevEmpFormValues) => ({
      ...prevEmpFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (authHeader) {
        await saveCompanyDetails(formValues, authHeader);
        setFormValues(initialFormValues);

        // Handle employee form submission
        await saveEmployerDetails(empFormValues, authHeader);
        setEmpFormValues(initialEmpFormValues);

        setSnackbarMessage("Form submitted successfully!");
        setSnackbarSeverity("success"); // Set success severity
        setOpen(true);
      }
    } catch (error) {
      console.error("error during saveEmployerDetails",error);
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error"); // Set error severity
      setOpen(true);
    }
  };
  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={15}>
        <Box p={3}>
          <Typography variant="h4" align="center" gutterBottom>
            Company Registration
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Company Name"
                  name="companyName"
                  value={formValues.companyName}
                  onChange={handleInputChange}
                  autoComplete="organization"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Contact Number"
                  name="contactNumber"
                  value={formValues.contactNumber}
                  onChange={handleInputChange}
                  autoComplete="tel" 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Description"
                  name="description"
                  value={formValues.description}
                  onChange={handleInputChange}
                  multiline
                  rows={4}
                  autoComplete="off" 
                />
                <FormHelperText>
                  Enter your Company Description and other values....
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Industry"
                  name="industry"
                  value={formValues.industry}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Contact E-mail"
                  name="contactEmail"
                  value={formValues.contactEmail}
                  onChange={handleInputChange}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Website"
                  name="website"
                  value={formValues.website}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Social Media"
                  name="socialMedia"
                  value={formValues.socialMedia}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  required
                  label="Location"
                  name="location"
                  value={formValues.location}
                  onChange={handleInputChange}
                  autoComplete="street-address"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ display: "flex" }}
                  select
                  label="Size of Company"
                  onChange={handleInputChange}
                  name="size"
                  value={formValues.size}
                >
                  {size.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Employee Name"
                name="name"
                value={empFormValues.name}
                onChange={handleEmpInputChange}
                sx={{ mb: 3 }}
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Job Role"
                name="jobTitle"
                value={empFormValues.jobTitle}
                onChange={handleEmpInputChange}
                sx={{ mb: 3 }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Bio"
                name="bio"
                value={empFormValues.bio}
                onChange={handleEmpInputChange}
                multiline
                rows={4}
                autoComplete="off"
              />
              <FormHelperText sx={{ mb: 3 }}>
                Enter your Company Description and other values....
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Employee Phone"
                name="phoneNumber"
                value={empFormValues.phoneNumber}
                onChange={handleEmpInputChange}
                sx={{ mb: 3 }}
                autoComplete="tel"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Employee Email"
                name="emailId"
                value={empFormValues.emailId}
                onChange={handleEmpInputChange}
                sx={{ mb: 3 }}
                autoComplete="email"
              />
            </Grid>
            <Grid
              sx={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CustomButton
                tag="CompanyEmployerRegistration/Submit"
                type="submit"
                variant="contained"
                style={{ alignItems: "center", fontSize: "18px" }}
              >
                Submit
              </CustomButton>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity={snackbarSeverity as "success" | "error"}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Container>
  );
}

export default CompanyRegistration;
