import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { savePaymentMethod } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";
import GlobalSnackbar, { Severity } from "../snackbarComponent/Snackbar";
import CustomLoading from "../custom/Loading";
import theme from "../../theme";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LockIcon from "@mui/icons-material/Lock";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { validateAccountNumber } from "../validators/ValidateAccountNumber";
import { validateName } from "../validators/ValidateName";
import { validateConfirmAccountNumber } from "../validators/ValidateConfirmAccountNumber";
import { validateIFSCCode } from "../validators/ValidateIFSCCode";

interface BankDetailsFormProps {
  onCloseDialog: () => void;
  fetchUserBankData: () => void;
}

const BankDetailsForm: React.FC<BankDetailsFormProps> = ({
  onCloseDialog,
  fetchUserBankData,
}) => {
  const [accountHolderName, setAccountHolderName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState<string>("");
  const [ifscCode, setIFSCCode] = useState<string>("");
  const [accountHolderNameError, setAccountHolderNameError] =
    useState<string>("");
  const [accountNumberError, setAccountNumberError] = useState<string>("");
  const [confirmAccountNumberError, setConfirmAccountNumberError] =
    useState<string>("");
  const [ifscCodeError, setIFSCCodeError] = useState<string>("");
  const { authHeader } = useAuthContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    message: string;
    severity: Severity;
  }>({ message: "", severity: undefined });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPassword, setShowPassword] = useState<boolean>(true);

  const snackbar = (message: string, severity: Severity) => {
    setOpenSnackbar(true);
    setSnackbarData({ message, severity });
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        let hasError = false; // Flag to track if any validation error occurred

        if (validateName(accountHolderName)) {
          setAccountHolderNameError(
            "Account holder name is required and must be more than 3 characters"
          );
          hasError = true;
        } else {
          setAccountHolderNameError(""); // Clear the error message
        }

        if (validateAccountNumber(accountNumber)) {
          setAccountNumberError(
            "Account number is required and must be between 11 and 16 characters"
          );
          hasError = true;
        } else {
          setAccountNumberError(""); // Clear the error message
        }

        if (validateConfirmAccountNumber(confirmAccountNumber, accountNumber)) {
          setConfirmAccountNumberError("Account numbers do not match");
          hasError = true;
        } else {
          setConfirmAccountNumberError(""); // Clear the error message
        }

        if (validateIFSCCode(ifscCode)) {
          setIFSCCodeError(
            "IFSC code is required and must match the pattern ABCD0123456"
          );
          hasError = true;
        } else {
          setIFSCCodeError(""); // Clear the error message
        }

        if (hasError) {
          setLoading(false);
          return;
        }

        // Submit the form
        const formData = {
          name: accountHolderName,
          ifsc: ifscCode,
          accountNo: accountNumber,
          type: "bank",
        };

        try {
          if (authHeader) {
            const response: any = await savePaymentMethod(formData, authHeader);
            if (
              response.data.message === "payment method added successfully!"
            ) {
              snackbar("Bank Account added successfully", "success");
            }
            onCloseDialog();
            fetchUserBankData();
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        setLoading(false);
      }
    },
    [
      fetchUserBankData,
      accountHolderName,
      accountNumber,
      ifscCode,
      setLoading,
      onCloseDialog,
      authHeader,
      confirmAccountNumber,
    ]
  );

  const handleChangeAccountHolderName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setAccountHolderName(value);
    const error = validateName(value);
    setAccountHolderNameError(error);
  };

  const handleChangeAccountNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.replace(/\D/g, "");
    setAccountNumber(value);
    const error = validateAccountNumber(value);
    setAccountNumberError(error);
  };

  const handleConfirmAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.replace(/\D/g, "");
    setConfirmAccountNumber(value);
    const error = validateConfirmAccountNumber(value, accountNumber);
    setConfirmAccountNumberError(error);
  };

  const handleIFSCCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value.toUpperCase();
    setIFSCCode(value);
    const error = validateIFSCCode(value);
    setIFSCCodeError(error);
  };

  const handleBlur = () => {
    setShowPassword(false); // Close the password visibility toggle when focus is lost
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        {isLoading ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "200px",
              minWidth: "400px",
            }}
          >
            <CustomLoading />
          </Container>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <GlobalSnackbar
                open={openSnackbar}
                message={snackbarData.message}
                onClose={handleCloseSnackbar}
                severity={snackbarData.severity}
                vertical={"top"}
                horizontal={"center"}
              />
              <Grid item xs={12}>
                <TextField
                  label="Account Holder Name"
                  fullWidth
                  value={accountHolderName}
                  onChange={handleChangeAccountHolderName}
                  error={!!accountHolderNameError}
                  helperText={
                    accountHolderNameError
                      ? accountHolderNameError
                      : "Enter your name as per bank"
                  }
                  autoComplete="name"
                  InputProps={{
                    startAdornment: (
                      <AccountCircleIcon
                        sx={{ marginRight: 1, color: "gray" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Account Number"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  value={accountNumber}
                  onBlur={handleBlur}
                  onChange={handleChangeAccountNumber}
                  error={!!accountNumberError}
                  helperText={
                    accountNumberError
                      ? accountNumberError
                      : "Enter your bank account number"
                  }
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <AccountBalanceIcon
                        sx={{ marginRight: 1, color: "gray" }}
                      />
                    ),
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm Account Number"
                  fullWidth
                  type="text"
                  value={confirmAccountNumber}
                  onChange={handleConfirmAccountNumberChange}
                  error={!!confirmAccountNumberError}
                  helperText={
                    confirmAccountNumberError
                      ? confirmAccountNumberError
                      : "Confirm your bank account number"
                  }
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <AccountBalanceIcon
                        sx={{ marginRight: 1, color: "gray" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="IFSC Code"
                  fullWidth
                  value={ifscCode}
                  onChange={handleIFSCCodeChange}
                  error={!!ifscCodeError}
                  helperText={
                    ifscCodeError ? ifscCodeError : "Enter your bank IFSC code"
                  }
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <LockIcon sx={{ marginRight: 1, color: "gray" }} />
                    ),
                  }}
                />
              </Grid>
              {!isMobile && (
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              )}

              {isMobile && (
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 5px",
                    backgroundColor: "white",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                    sx={{
                      padding: "5px",
                      marginLeft: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Grid>
          </form>
        )}
      </Box>
    </Container>
  );
};

export default BankDetailsForm;
