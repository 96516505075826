import React from 'react';
import { CircularProgress } from '@mui/material';

interface LoadingProps {
  size?: number;
}

const CustomLoading: React.FC<LoadingProps> = ({ size = 40 }) => {
  const containerStyle: React.CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999, // Adjust the z-index as needed
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '8px', // You can adjust the border radius as needed
    padding: '16px', // You can adjust the padding as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div style={containerStyle}>
      <CircularProgress size={size} />
    </div>
  );
};

export default CustomLoading;
