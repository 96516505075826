import React, { useState, useRef } from "react";
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { compressImage } from "../../utils/compressImage";

interface HandleImageQuestionProps {
  question: string;
  onFileSelected: (file: File, question: string) => void;
  handleFileRemove: (question: string) => void;
  disabled: boolean;
}
const mediaQueryStyles = {
  "@media (maxWidth: 768px)": {
    width: "100%",
    maxWidth: "100%",
  },
};
const HandleImageQuestion: React.FC<HandleImageQuestionProps> = ({
  question,
  onFileSelected,
  handleFileRemove,
  disabled,
}) => {
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>();
  const [dragOver, setDragOver] = useState(false);
  const fileDropRef = useRef<HTMLDivElement>(null);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const handleUploadClick = async () => {
    if (file && !loading) {
      setLoading(true);
      let compressedFile: any = file;

      if (file.type.startsWith("image")) {
        // We compress the file by 50%
        compressedFile = await compressImage(file, {
          // 0: is maximum compression
          // 1: is no compression
          quality: 0.5,

          // We want a JPEG file
          type: "image/jpeg",
        });
      }

      onFileSelected(compressedFile, question);
      setConfirmationDialogOpen(false);
      setOpenDialog(false);
      setIsFileUploaded(true);

      setIsUploadSuccess(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(compressedFile);
      setLoading(false);
    }
  };

  const handleRemoveClick = () => {
    setFile(null);
    setCapturedImage(null);
    setIsUploadSuccess(undefined);
    setIsFileUploaded(false);
    handleFileRemove(question);
    setPreviewImage(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setConfirmationDialogOpen(true);
      setIsFileUploaded(true);
    }
  };
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const selectedFile = files[0];
      setFile(selectedFile);
      setConfirmationDialogOpen(true);
    }
  };

  const handleClickToBrowse = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  return (
    <Box className="box-design" sx={{ display: "flex" }}>
      {!capturedImage && (
        <>
          <Box>
            <Typography sx={{ color: "#717171" }} variant="h6">
              {question}
            </Typography>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileChange}
              style={{ display: "none" }}
              disabled={isUploadSuccess}
              ref={fileInputRef}
            />
            <Button
              disabled={isUploadSuccess}
              onClick={() => setOpenDialog(true)}
              variant="outlined"
            >
              Upload Image
            </Button>
            {isUploadSuccess && (
              <IconButton color="success">
                <CheckCircleOutlineIcon />
              </IconButton>
            )}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>Drag and Drop Image</DialogTitle>
              <DialogContent>
                <div
                  ref={fileDropRef}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  style={{
                    border: dragOver
                      ? "2px dashed #1976D2"
                      : "2px dashed #9E9E9E",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                >
                  <AddAPhotoIcon style={{ fontSize: 50, color: "#9E9E9E" }} />
                  <Typography>
                    Drag and drop an image file here or&nbsp;
                    <Button
                      sx={{
                        color: "#1976D2",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleClickToBrowse}
                    >
                      click to browse
                    </Button>
                  </Typography>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              </DialogActions>
            </Dialog>
            {isFileUploaded && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleRemoveClick}
                disabled={disabled}
              >
                Remove
              </Button>
            )}
            {isUploadSuccess === false && (
              <IconButton color="error">
                <CancelOutlinedIcon />
              </IconButton>
            )}{" "}
          </Box>
          <Box>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                style={{
                  marginLeft: "25px",
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                }}
              />
            )}
          </Box>
          <Dialog open={confirmationDialogOpen}>
            <DialogTitle>Preview and Confirm Image</DialogTitle>
            <DialogContent>
              {file && (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Selected"
                  style={{
                    width: "100%",
                    maxWidth: "500px",
                    height: "auto",
                    ...mediaQueryStyles,
                  }}
                />
              )}
              <Typography>Do you want to upload this image?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                sx={{ minWidth: "115px" }}
                onClick={handleUploadClick}
                color="primary"
                startIcon={
                  loading ? <CircularProgress size={20} /> : <AddAPhotoIcon />
                }
                disabled={loading || disabled}
              >
                {loading ? "Uploading..." : "Upload Image"}{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default HandleImageQuestion;
