import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { saveFCMToken } from "../../clients/saveFcmToken";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const getFirebaseToken = async (authHeader: string) => {
  // Request notification permission
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    // Retrieve FCM token
    const fcmToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
    });

    // Save FCM token to your server or locally
    try {
      if (authHeader && fcmToken) {
        await saveFCMToken(authHeader, fcmToken, "web");
        return fcmToken;
      }
    } catch (error) {
      console.error("Error saving FCM token:", error);
    }
  }
};
