import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Button,
  Modal,
  Backdrop,
  Fade,
  IconButton,
} from "@mui/material";
import dateFormat from "dateformat";
import CloseIcon from "@mui/icons-material/Close";
import { getUserTaskReport } from "@gogig-in/gogig-clients";
import { useAuthContext } from "../../providers/AuthProvider";

interface UserReportProps {
  gigId: string | undefined;
}

const MultipleCardsComponent: React.FC<UserReportProps> = ({ gigId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { authHeader } = useAuthContext();
  const [userResponse, setUserResponse] = useState<any[]>([]);

  const handleViewClick = (data: any) => {
    setSelectedData(data);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const isValidImageUrl = (url: any) => {
    return (
      typeof url === "string" && /\.(jpg|jpeg|png|gif)$/.test(url.toLowerCase())
    );
  };

  useEffect(() => {
    const fetchUserTaskReport = async () => {
      try {
        if (authHeader) {
          const response: any = await getUserTaskReport(authHeader, gigId);
          const userReport = response.data;
          setUserResponse(userReport);
        } else {
          console.error("Insufficient data.");
          return;
        }
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };
    fetchUserTaskReport();
  }, [authHeader, gigId]);

  return (
    <Box>
      <Typography variant="h5" sx={{ textDecoration: "underline" }}>
        Submitted Responses
      </Typography>
      <Box
        sx={{
          maxHeight: "150px",
          overflowY: "auto",
          backgroundColor: "background.paper",
          padding: "7px",
          border: "2px solid #d4d4d4",
          borderRadius: "8px",
        }}
      >
        {userResponse.map((data, index) => (
          <Card
            key={index}
            sx={{ backgroundColor: "white", marginBottom: "7px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {`Date: ${dateFormat(data.date, "mm-dd-yyyy, h:MM TT")}`}
              </Typography>
              <Button onClick={() => handleViewClick(data)}>View</Button>
            </div>
          </Card>
        ))}
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: "75%",
              maxWidth: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              padding: "10px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">Response</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            {selectedData && (
              <>
                {Object.entries(selectedData).map(([key, value]) => (
                  <div key={key}>
                    {key !== "date" && (
                      <Typography variant="h6">{key}</Typography>
                    )}
                    {value !== null && typeof value === "object" ? (
                      <div>
                        {Object.entries(value).map(([subKey, subValue]) => (
                          <div key={subKey}>
                            {typeof subValue === "string" &&
                            isValidImageUrl(subValue) ? (
                              <img
                                src={subValue}
                                alt={subKey}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  width: "300px",
                                  height: "300px",
                                }}
                              />
                            ) : (
                              <Typography variant="body1">{`${subKey}: ${subValue}`}</Typography>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : typeof value === "string" && isValidImageUrl(value) ? (
                      <img
                        src={value}
                        alt={key}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          width: "300px",
                          height: "300px",
                        }}
                      />
                    ) : typeof value === "string" && key === "date" ? (
                      <Typography
                        variant="h6"
                        color="green"
                      >{`Date: ${dateFormat(
                        value,
                        "mm-dd-yyyy, h:MM TT"
                      )}`}</Typography>
                    ) : (
                      <Typography variant="body1">{`${key}: ${value}`}</Typography>
                    )}
                  </div>
                ))}
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default MultipleCardsComponent;
