import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import { useAuthContext } from "../providers/AuthProvider";

interface CategoryLoginProps {
  categoryGigs: any[]; // Adjust type as per your data structure
  categoryDetails: { categoryName: string }; // Adjust type as per your data structure
}

const CategoryLoginPrompt: React.FC<CategoryLoginProps> = ({
  categoryGigs,
  categoryDetails,
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuthContext();
  const isMobileView = useMediaQuery("(max-width: 820px)");
  const isTabView = useMediaQuery("(max-width:1286px)");
  const isPcView = useMediaQuery("(min-width:1286px");
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < (isMobileView ? 1300 : 550)) {
        // Adjust the value based on your requirements
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileView]);
  return (
    <>
      {!isAuthenticated && categoryGigs.length > 0 && (
        <Box
          sx={{
            display: "flex",
            position: isFixed ? "fixed" : "relative",
            bottom: isFixed ? 0 : "auto",
            width: isMobileView
              ?isFixed? "89%":"96%"
              : isTabView
              ? isFixed
                ? "55%"
                : "97%"
              : isPcView
              ? isFixed
                ? "43.5%"
                : "96%"
              : "90%",
            backgroundColor: "orange",
            padding: "15px",
            alignItems: "center",
            zIndex: isFixed ? 1400 : 1, // High zIndex for fixed positioning
            mt: isFixed ? 0 : 1,
            mb: isFixed ? 0 : 1,
            ml: 0,
            
            transition: "position 0.3s ease",
          }}
        >
          <WorkIcon sx={{ marginRight: "10px" }} />
          <Typography variant="h6">
            Apply to {categoryDetails?.categoryName} category to be able to work
            on any of the available {categoryGigs.length} Jobs
          </Typography>
          <Box sx={{ flexGrow: 1 }} />{" "}
          {/* Spacer to push the button to the end */}
          <Button
            sx={{
              height: "40px",
              borderRadius: "8px",
            }}
            variant="contained"
            onClick={() => {
              loginWithRedirect({
                returnTo: window.location.pathname,
              });
            }}
          >
            Login
          </Button>
        </Box>
      )}
    </>
  );
};

export default CategoryLoginPrompt;
