import React from "react";
import {
  Card,
  CardContent,
  Typography,
  ListItem,
  useMediaQuery,
  CardActions,
  Box,
  Button,
} from "@mui/material";
import { Notification } from "./Notifications";
import MDEditor from "@uiw/react-md-editor";
import { relativeTime } from "../../utils/relativeTime";
interface NotificationCardProps {
  notification: Notification;
  handleNotificationClick: (notification: Notification) => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  handleNotificationClick,
}) => {
  const isMobileView = useMediaQuery("(max-width: 600px)");


  return (
    <ListItem
      sx={{ pl: isMobileView ? 1 : 2, pr: isMobileView ? 1 : 2, width: "100%" }}
    >
      <Button
        onClick={() => handleNotificationClick(notification)}
        sx={{ width: "100%", padding: 0, boxSizing: "border-box" }}
      >
        <Card
          elevation={1}
          sx={{
            width: "100%",
            padding: 0,
            textAlign: "left",
            backgroundColor: notification.isRead
              ? "background.default"
              : "#e4e7eb",
            textTransform: "none",
            boxShadow: notification.isRead
              ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
              : "none",
          }}
        >
          <CardContent sx={{ pb: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              {notification.notificationTitle}
            </Typography>
            <Typography variant="body2"  data-color-mode="light">
              <MDEditor.Markdown
                source={notification.notificationMDText}
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                }}
              />
            </Typography>
          </CardContent>
          <CardActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
              <Typography variant="caption" color="textSecondary">
                {relativeTime(notification.notificationTime)}
              </Typography>
            </Box>
          </CardActions>
        </Card>
      </Button>
    </ListItem>
  );
};

export default NotificationCard;
