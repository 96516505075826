import React, { useState } from "react";
import { Button, Typography, FormControl } from "@mui/material";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

interface HandleAddressQuesProps {
  question: string;
  questionId: string;
  handleAnswerChange: (questionId: string, value: Record<string, string>) => void;
  disabled: boolean;
  answers: Record<string, Record<string, string>>;
  onErrorChange: (error: string | null, field: string) => void;
}

const HandleAddressQues: React.FC<HandleAddressQuesProps> = ({
  question,
  questionId,
  handleAnswerChange,
  disabled,
  answers,
}) => {
  const [locationError, setLocationError] = useState<string | null>(null);

  const handleGeolocation = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (navigator.geolocation) {
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyCKtf2MVyTl3MIOac35LKedSTV00pLopVk`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch address.");
        }

        const data = await response.json();
        const formattedAddress = data.results[0]?.formatted_address ||  "Address not found";

        setLocationError(null);

        handleAnswerChange(questionId, {
          address: formattedAddress,
          coordinates: `${position.coords.latitude},${position.coords.longitude}`,
        });
      }catch (error: any) {
        console.error("Error getting address:", error);
        if (error.code === 1) {
          setLocationError("Please enable location permission from settings and try again! Go to options from top right corner -> go to settings -> select site settings -> click on location -> search gogig.in webite and unblock it")
        } else {
          setLocationError("Failed to get address. Please try again.");
        }
      }
    } else {
      setLocationError("Geolocation is not supported in this browser.");
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {question}
      </Typography>
      <FormControl fullWidth margin="normal">
        <Typography variant="body1">
          {answers[questionId]?.address || <span>Click on the <strong>Use current Location</strong> button to get the address</span>}
        </Typography>
      </FormControl>
      <Button
        type="button"
        onClick={handleGeolocation}
        color="primary"
        startIcon={<GpsFixedIcon />}
        disabled={disabled}
        variant="outlined"
      >
        Use current Location
      </Button>
      {locationError && (
        <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
          {locationError}
        </Typography>
      )}
    </div>
  );
};

export default HandleAddressQues;
