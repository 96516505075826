import React, { useEffect, useState } from "react";
import { getCategoryDetailsByCategoryId } from "@gogig-in/gogig-clients";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LoadingSpinner from "../components/LoadingAnimation";
import CustomChip from "../components/endUserLandingPage/CustomChip";
import { HtmlTooltip } from "../styles/htmlTooltip";
import { getGigDetailsByCategoryId } from "@gogig-in/gogig-clients";
import { CategoryGigListDetails } from "../components/categoryListings/CategoryGigListing";
import CategoryGigsList from "../components/categoryListings/CategoryGigsList";
import CategoryListing from "../components/categoryListings/CategoryListing";
import { useAuthContext } from "../providers/AuthProvider";
import MDEditor from "@uiw/react-md-editor";
import CategoryIcon from "./CategoryIcon";
import CategoryLoginPrompt from "./CategoryLoginPrompt";

const CategoryDetails = () => {
  const { categoryId } = useParams<{ categoryId?: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [categoryDetails, setCategoryDetails] = useState<any>(null);
  const [categoryGigs, setCategoryGigs] = useState<CategoryGigListDetails[]>(
    []
  );
  const { isAuthenticated } = useAuthContext();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const isMobileView = useMediaQuery("(max-width: 820px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        if (categoryId) {
          const response = await getCategoryDetailsByCategoryId(categoryId);
          setCategoryDetails(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching gig details", error);
      }
    };
    fetchCategoryDetails();
  }, [categoryId]);
  useEffect(() => {
    const fetchCategoryGigs = async () => {
      try {
        if (categoryId) {
          const response: any = await getGigDetailsByCategoryId(categoryId);
          setCategoryGigs(response.data);
        }
      } catch (error) {
        console.error("Error fetching categoryGigs:", error);
      }
    };
    fetchCategoryGigs();
  }, [categoryId]);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  return (
    <>
      {isLoading ? (
        <Box height="300px">
          <LoadingSpinner label="Please wait while we fetch gig Details..." />
        </Box>
      ) : (
          <Container
            sx={{
              pl:"10px",
              display: "flex",
              flexDirection: isMobileView ? "column" : "row",
              mt: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: isMobileView ? "100%" : "70%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  backgroundColor: "white",
                  justifyContent: "center",
                  ml: "2px",
                  mr: "2px",
                }}
              >
                <CategoryIcon
                  categoryId={categoryDetails.categoryId}
                  
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "90px",
                    marginTop: "-70px",
                  }}
                >
                  <Typography variant="h3">
                    {categoryDetails.categoryName}
                  </Typography>
                  <Box
                    sx={{
                      fontSize: "small",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "6px",
                      marginTop: "4px",
                      opacity: 0.7,
                    }}
                  >
                    <HtmlTooltip
                      title={<em>It's a survey to be collected on call.</em>}
                    >
                      <CustomChip
                        label={`${
                          categoryGigs.length ? categoryGigs.length : "0"
                        } jobs`}
                        additionalStyles={{
                          color: "blue",
                          backgroundColor: " lightBlue",
                          border: "1px solid blue",
                          borderRadius:"8px"
                        }}
                      />
                    </HtmlTooltip>
                    <HtmlTooltip title={<em>Work along your main job.</em>}>
                      <CustomChip
                        label="Part time"
                        additionalStyles={{
                          color: "green",
                          backgroundColor: "lightSkyBlue",
                          border: "1px solid green",
                          borderRadius:"8px"
                        }}
                      />
                    </HtmlTooltip>
                  </Box>
                </Box>
                <Box display="flex" flexDirection={"column"} marginTop={"10px"}>
                  {categoryDetails.averageSalary && (
                    <Typography
                      sx={{
                        color: "text.primary",
                        fontSize: 16,
                        userSelect: "none",
                        mb: 2,
                      }}
                    >
                      <b>Average Salary: </b>
                      {categoryDetails.averageSalary}
                    </Typography>
                  )}
                  {categoryDetails.basicRequirements && (
                    <Typography
                      sx={{
                        color: "text.primary",
                        fontSize: 16,
                        mb: 2,
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Basic Requirements: </b>
                      {categoryDetails.basicRequirements}
                    </Typography>
                  )}

                  <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
                    <Divider />
                  </Box>
                </Box>
                <h4 style={{marginTop:"0px"}}>Description: </h4>

                <Typography
                  sx={{
                    backgroundColor: "white",
                    marginBottom: "6px",
                   
                    opacity: 0.7,
                  }}
                >
                  <MDEditor.Markdown
                    source={
                      showFullDescription
                        ? categoryDetails.categoryDescription
                        : `${categoryDetails.categoryDescription.slice(
                            0,
                            500
                          )}${
                            categoryDetails.categoryDescription.length > 500
                              ? "..."
                              : ""
                          }`
                    }
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                  <Button size="small" onClick={toggleDescription}>
                    {showFullDescription ? "Read Less" : "Read More"}
                  </Button>
                </Typography>

                {categoryGigs.length > 0 && (
                  <>
                    <Typography variant="h4" sx={{ mt: "4px" }}>
                      Available Gigs
                    </Typography>
                    <CategoryGigsList categoryGigs={categoryGigs} />
                  </>
                )}
              </Box>
              {!isAuthenticated && (
                
                  <CategoryLoginPrompt
                    categoryGigs={categoryGigs}
                    categoryDetails={categoryDetails}
                  />
               
              )}
            </Box>
            <Box
              sx={{
                width: isMobileView ? "100%" : "40%",
                marginLeft: isMobileView ? "0px" : "60px",
          
              }}
            >
              <Typography
                sx={{
                  marginTop: "10px",
                  marginBottom: "25px",
                  marginLeft: "10px",
                }}
                variant="h4"
              >
                Explore Categories
              </Typography>
              <Box
                sx={{
                  marginTop: "-30px",
                  marginLeft: "5px",
                  marginRight:"0px",
                  padding:0
                }}
              >
                <CategoryListing categoryId={categoryId} />
              </Box>
            </Box>
          </Container>
        
      )}
    </>
  );
};

export default CategoryDetails;
